
export type ScoreEntryComponent = 'literacy' | 'numeracy' | 'theme';

export interface IScoreEntryCell {
    taqr_id?: number,
    component?: ScoreEntryComponent,
    question?: number,
    score?: string | number,
}

export interface IScoreEntryData {
    test_attempt_id: number,
    uid: number,
    cells: IScoreEntryCell[],
}

export interface IScoreEntryChange {
    uid: number,
    test_attempt_id: number,
    component: string,
    question: string,
    taqr_id: number,
    score: string,
}

export interface IScoreEntryUploadCell {
    component: ScoreEntryComponent,
    question: number,
    score: string | number,
}

export interface IScoreEntryUploadData {
    pen: string,
    school_code: string,
    first_name: string,
    last_name: string,
    grade: number,
    cells: IScoreEntryUploadCell[],

    uid?: number,
    school_group_id?: number,
    error?: IScoreEntryUploadError | null,
    test_attempt_id?: number,
}

export interface IScoreEntryUploadError {
    message: string,
}

export interface IScoreEntrySchoolAggregationByGrade {
    enrollment: number;
    cr_literacy: number;
    cr_numeracy: number;
    sr_literacy: number;
    sr_numeracy: number;
}

export interface IScoreEntrySchoolAggregationPercentageByGrade {
    enrollment: number;
    cr_literacy: string;
    cr_numeracy: string;
    sr_literacy: string;
    sr_numeracy: string;
}

export interface IScoreEntrySchoolAggregation {
    g4: IScoreEntrySchoolAggregationByGrade,
    g7: IScoreEntrySchoolAggregationByGrade,
}

export interface IScoreEntrySchoolAggregationPercentage {
    g4: IScoreEntrySchoolAggregationPercentageByGrade,
    g7: IScoreEntrySchoolAggregationPercentageByGrade,
}

export enum ScoreEntryStatus {
    NOT_YET_SCORED = 'sa_se_not_yet_started',
    IN_PROGRESS = 'sa_se_in_progress',
    IN_PROGRESS_NOT_SAVED = 'sa_se_in_progress_not_saved',
    SUBMITTED = 'sa_se_submitted',
}