<div class="score-entry-aggregation-table">
    <div class="district-total-label" *ngIf="totalSlug">
        <tra [slug]="totalSlug"></tra>
    </div>

    <table class="responses aggregate">
        <tr class="table-headers">
            <th>
                <tra slug="sa_grade"></tra>
            </th>
            <th class="response-expected">
                <div>
                    <tra slug="da_se_students_enrolled"></tra>
                </div>
            </th>
            <th><span class="small-headers">
                    <tra slug="da_se_students_who"></tra>
                </span><br>
                <tra slug="da_se_completedsr"></tra> <br>
                <tra slug="sa_literacy"></tra>
            </th>
            <th><span class="small-headers">
                    <tra slug="da_se_students_who"></tra>
                </span><br>
                <tra slug="da_se_completedsr"></tra> <br>
                <tra slug="da_numeracy"></tra>
            </th>
            <th><span class="small-headers">
                    <tra slug="da_se_students_with"></tra>
                </span><br>
                <tra slug="da_se_cr_scores_entered"></tra> <br>
                <tra slug="sa_literacy"></tra>
            </th>
            <th><span class="small-headers">
                    <tra slug="da_se_students_with"></tra>
                </span><br>
                <tra slug="da_se_cr_scores_entered"></tra><br>
                <tra slug="da_numeracy"></tra>
            </th>
        </tr>
        <tr>
            <td>
                <tra slug="fsa_g7"></tra>
            </td>
            <td>{{aggregation.g7.enrollment}}</td>
            <td>{{aggregation.g7.sr_literacy}} ({{aggregationPercentage.g7.sr_literacy}})</td>
            <td>{{aggregation.g7.sr_numeracy}} ({{aggregationPercentage.g7.sr_numeracy}})</td>
            <td>{{aggregation.g7.cr_literacy}} ({{aggregationPercentage.g7.cr_literacy}})</td>
            <td>{{aggregation.g7.cr_numeracy}} ({{aggregationPercentage.g7.cr_numeracy}})</td>
        </tr>
        <tr>
            <td>
                <tra slug="fsa_g4"></tra>
            </td>
            <td>{{aggregation.g4.enrollment}}</td>
            <td>{{aggregation.g4.sr_literacy}} ({{aggregationPercentage.g4.sr_literacy}})</td>
            <td>{{aggregation.g4.sr_numeracy}} ({{aggregationPercentage.g4.sr_numeracy}})</td>
            <td>{{aggregation.g4.cr_literacy}} ({{aggregationPercentage.g4.cr_literacy}})</td>
            <td>{{aggregation.g4.cr_numeracy}} ({{aggregationPercentage.g4.cr_numeracy}})</td>
        </tr>
    </table>
</div>