<div class="student-individual-report-bulk fit-print-to-page">
    <ng-container *ngIf="!isLoading">
        <div class="dont-print">
            <h1>Individual Student Results Report - All Students</h1>
            <!--NO PDFS AT ALL-->
            <ng-container *ngIf="!hasRecentReports(); else hasRecent">
                <p style="margin:1em 0; font-size:1.2em;">There have not been any PDFs generated for this group.</p>
                <button (click)="generatePDF()" class="button is-large">
                    Generate new PDF
                </button>
            </ng-container>

            <ng-template #hasRecent>
                    <!--RECENT IS COMPLETE-->
                <ng-container *ngIf="isValidRecentReport()"> 
                    <!-- "recentReports[0].is_waiting == 0 && recentReports[0].is_succeed == 1"> -->
                    <p style="margin:1em 0; font-size:1.2em;">The most recent PDF for this group was generated {{timeSince(recentReports[0].end)}} ago.</p>
                    <div class="options-row">
                        <button (click)="generatePDF()" class="button is-large">
                            Generate new PDF
                        </button>
                        <!-- <span style="font-weight:bold; margin:0 0.5em;">- or -</span>
                        <button  (click)="downloadPDF(recentReports[0].path)" class="button is-large">
                            Download PDF from {{timeSince(recentReports[0].end)}} ago
                        </button> -->
                    </div>
                    <a 
                        style="margin-top: 0.5em; display: block; margin-left: 0.1em;" 
                        (click)="downloadPDF(recentReports[0].path)"
                    >
                        Download PDF from {{timeSince(recentReports[0].end)}} ago
                    </a>
                </ng-container>
                <!--RECENT IS INCOMPLETE-->
                <ng-container *ngIf="recentReports[0].is_waiting == 1 && recentReports[0].is_succeed == 0">
                    <p style="margin:1em 0; font-size:1.2em;">A PDF for this group is currently being generated.</p>
                    <ng-container *ngIf="recentReports[0].mine">
                        <input 
                            type="checkbox" 
                            id="EmailMe" 
                            [(ngModel)]="emailMe" 
                            (ngModelChange)="changeEmailPreference()"
                        >
                        &nbsp;&nbsp;
                        <label for="EmailMe">Email me when the PDF is ready.</label>
                        <br>
                    </ng-container>

                    <div class="options-row"> 
                        <button disabled class="button is-large">
                            Please wait
                            <span style="width: 1.5em;
                            position: relative;
                            margin-left: 0.5em;">
                                <div class="ball-pulse">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </button>
                        <!-- <ng-container  *ngIf="recentReports[1] && recentReports[1].end">
                            <span style="font-weight:bold; margin:0 0.5em;">- or -</span>
                            <button (click)="downloadPDF(recentReports[1].path)" class="button is-large">
                                Download PDF from {{timeSince(recentReports[1].end)}} ago
                            </button>
                        </ng-container> -->
                    </div>

                    <ng-container  *ngIf="getRecentSucceedReport()">
                        <a 
                            style="margin-top: 0.5em; display: block; margin-left: 0.1em;" 
                            (click)="downloadPDF(getRecentSucceedReport().path)"
                        >
                            Download PDF from {{timeSince(getRecentSucceedReport().end)}} ago
                        </a>
                    </ng-container>
                </ng-container>
            </ng-template>
        
            <div class="select-sheet">
                <button 
                    *ngIf="isRawScoreAvailable()"
                    class="sheet-button"
                    [class.selected]="selectedScoreType == ScoreType.RAW_SCORE"
                    (click)="selectScoreType(ScoreType.RAW_SCORE)"
                >
                    Raw Score
                </button>
      
                <button
                    *ngIf="isScaledScoreAvailable()"
                    class="sheet-button"
                    [class.selected]="selectedScoreType == ScoreType.SCALED_SCORE"
                    (click)="selectScoreType(ScoreType.SCALED_SCORE)"
                >
                    Scaled Score
                </button>
            </div>
            <p style="margin:1em 0;"><i> Note: Student report PDFs can take up to several minutes to generate, depending on the number of students in the group. You can leave the page while the report generates.</i></p>
    
            <button class="button" [class]="currentlang == 'en' ? 'selected' : 'unselected'"
                (click)="handleLanguageToggle('en')">English</button>
            <button class="button" [class]="currentlang == 'fr' ? 'selected' : 'unselected'"
                (click)="handleLanguageToggle('fr')">French</button>
        </div>
    
        <div *ngIf="!languageRefresh" class="preview-container">
            PREVIEW
            <div class="one-report" *ngFor="let data of singleModeDataRows">
                <student-individual-report 
                    [singleModeData]="data" 
                    [isBulk]="true"
                    [districtCode]="districtDetail.foreignId" [schoolLang]="data.studentSchoolLang"
                    [isScaled]="isIrtReady"
                ></student-individual-report>
            </div>
        </div>
    </ng-container>

    <div *ngIf="isLoading || languageRefresh" class="loader-container dont-print">
        
        <div class="bcg-loader">
            <p style="text-align:center;">Loading report preview</p>&nbsp;&nbsp;&nbsp;
            <progress id="file" [value]="getLoadPercent()" max="100"> {{getLoadPercent()}}% </progress>
        </div>
        
    </div>
</div>
