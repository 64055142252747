import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcAccountsService, DistrictDetail, Filter, FilterCondition, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, IScoreProfile, IThemeProfile, ScoreEntryStudentRow, SpreadSheetScoreEntryRow, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntryCell, IScoreEntryChange, IScoreEntryData, IScoreEntryUploadCell, IScoreEntryUploadData, ScoreEntryComponent, ScoreEntryStatus } from 'src/app/bc-score-entry/types';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { LangService } from 'src/app/core/lang.service';
import { BcUploadWidgetComponent } from '../bc-upload-widget/bc-upload-widget.component';
import { ScoreEntryAggregationTableComponent } from '../score-entry-aggregation-table/score-entry-aggregation-table.component';
import * as Papa from 'papaparse'; // for reading CSVs
import { BcPaginatorComponent } from '../bc-paginator/bc-paginator.component';

enum ScoreEntryRowType {
  ALL = 'all',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}

@Component({
  selector: 'score-entry-students-table',
  templateUrl: './score-entry-students-table.component.html',
  styleUrls: ['./score-entry-students-table.component.scss']
})
export class ScoreEntryStudentsTableComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(BcUploadWidgetComponent) uploadWidget: BcUploadWidgetComponent;
  @ViewChild(BcPaginatorComponent) paginator: BcPaginatorComponent;

  @Input() school: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() district: DistrictDetail;
  @Input() themeProfile: IThemeProfile;
  @Input() scoreProfile: IScoreProfile;
  @Output() refresh = new EventEmitter();

  pagination: Pagination;
  scoreEntryRows: ScoreEntryStudentRow[] = [];
  visibleFilters: Set<string>;
  filters: Map<string, Filter>;
  filterThrottle: NodeJS.Timeout | null;
  paginatorShowCount: boolean = true;
  referesh: boolean = false;
  currentLang: string = 'en';

  isLoading: boolean = false;

  selectedGrade: 4 | 7 = 4;
  grades: (4 | 7)[] = [4, 7];

  selectedRowType: ScoreEntryRowType = ScoreEntryRowType.ALL;
  ScoreEntryRowType = ScoreEntryRowType;
  incompleteOnly: boolean = false;
  completeOnly: boolean = false;

  penToLookup: string;
  placeholderPEN_search: string;
  isInvalidPen: boolean = false;

  disableImport: boolean = true;
  showImportSpreadSheetModal: boolean = false;
  importSpreadSheetError: string = null;
  validScoreEntrySpreadSheetRows: SpreadSheetScoreEntryRow[] = null;
  isUploadLoading: boolean = false;
  sampleExcelLink: string = 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/504237/authoring/Sample Score Entry Spreadsheet/1630504408387/Sample Score Entry Spreadsheet.xlsx';
  uploadResultsTable: Partial<ScoreEntryStudentRow>[] = null;

  scoreChangeMap: Map<string, {
    uid: number,
    test_attempt_id: number,
    slug: string,
    taqr_id?: number,
    score: string,
  }>;
  themeChangeMap: Map<string, {
    uid: number,
    test_attempt_id: number,
    slug: string,
    taqr_id?: number,
    score: string,
  }>;

  autoSaveInterval: NodeJS.Timeout;
  isSaving: boolean = false;
  isEditing: boolean = false;

  headingToSortBys = [
    { heading: 'sa_pen', sortBy: 'pen', filterDisabled: true, },
    { heading: 'sa_llname', sortBy: 'last_name' },
    { heading: 'sa_lfname', sortBy: 'first_name' },
  ];

  fieldSlugs: string[] = ['LI_1', 'LI_2', 'LI_3', 'NU_1', 'NU_2', 'NU_3'];
  litFieldSlugs: ('LI_1' | 'LI_2' | 'LI_3')[] = ['LI_1', 'LI_2', 'LI_3'];

  previousTheme: any;
  noThemeValue: number = 0;

  isIrtReady: boolean = false;

  routeSub: Subscription;

  viewSldReportSlugProps: {
    SCHOOL_NAME: string,
  };

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private bcScoreEntry: BcScoreEntryService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
    private dataGuard: DataGuardService,
    private route: ActivatedRoute,
    private router: Router,
    private bcAssessments: BcAssessmentsService,
  ) {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.visibleFilters = new Set();
    this.filters = new Map();
    this.filterThrottle = null;

    this.placeholderPEN_search = this.lang.tra('sa_se_searchByPen');

    this.scoreChangeMap = new Map();
    this.themeChangeMap = new Map();
  }


  ngOnInit(): void {

    console.log('score-entry-students-table');
    this.bcScoreEntry.resetLastPulled();

    this.initializeFilters();

    this.routeSub = this.route.queryParams.subscribe(queryParams => {

      const { grade, rowType } = this.parseGradeAndCompletionQueryParams(queryParams);

      if (grade || rowType) {
        if (!queryParams.school || this.school && queryParams.school == this.school.groupId) {
          this.updateTable(false, true);
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.school && this.school) {
      this.viewSldReportSlugProps = {
        SCHOOL_NAME: this.school.name,
      };
    }
    if (changes.testWindow && this.testWindow) {
      this.bcAssessments.isIrtReadyForTestWindow(this.testWindow.id).then(ready => {
        this.isIrtReady = false; // deprecated variable
      })
    }
    if (changes.school || changes.testWindow || changes.district) {
      if (this.pagination) this.pagination.skip = 0;
      this.bcScoreEntry.resetLastPulled();

      this.penToLookup = null;
      this.isInvalidPen = false;
      this.filters.delete('pen');
      this.pagination.filters = [...this.filters.values()];


      const queryParams: Params = {};

      if (this.school) {
        queryParams.school = this.school.groupId;
      }

      if (changes.school && !changes.school.isFirstChange()) {
        this.selectedGrade = this.grades[0];
        queryParams.grade = this.selectedGrade;

        this.selectedRowType = ScoreEntryRowType.ALL;
        queryParams.row_type = this.selectedRowType;
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      }).then(() => {
        this.updateTable(true, true);
      })
    }
  }

  ngOnDestroy() {
    this.dataGuard.deactivate();
    if (this.routeSub) this.routeSub.unsubscribe();
    if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);
  }

  parseGradeAndCompletionQueryParams(queryParams: Params): { grade: boolean, rowType: boolean } {
    let gradeChanged = false;
    if (queryParams.grade && (this.grades as number[]).includes(+queryParams.grade)) {
      this.selectedGrade = +queryParams.grade as 4 | 7;
      gradeChanged = true;
    }

    let rowTypeChanged = false;
    if (queryParams.row_type) {
      let rowType: ScoreEntryRowType;
      switch (queryParams.row_type) {
        case ScoreEntryRowType.COMPLETE:
          rowType = ScoreEntryRowType.COMPLETE;
          this.incompleteOnly = false;
          this.completeOnly = true;
          break;
        case ScoreEntryRowType.INCOMPLETE:
          rowType = ScoreEntryRowType.INCOMPLETE;
          this.incompleteOnly = true;
          this.completeOnly = false;
          break;
        default:
          rowType = ScoreEntryRowType.ALL;
          this.incompleteOnly = false;
          this.completeOnly = false;
      }
      if (true) {
        this.selectedRowType = rowType;
        rowTypeChanged = true;
      }
    }

    return {
      rowType: rowTypeChanged,
      grade: gradeChanged,
    };
  }

  onSelectedGradeChange() {
    this.pagination.skip = 0;
    this.bcScoreEntry.resetLastPulled();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        grade: this.selectedGrade,
      },
      queryParamsHandling: 'merge',
    })
    this.handleLanguageToggle(this.currentLang);
  }

  getGradeDisplay(grade: 4 | 7): string {
    switch (grade) {
      case 7: return 'sa_se_grade7_se';
      default: return 'sa_se_grade4_se';
    }
  }

  getCountDisplay = (page: number, total: number): string => {
    let totalPages: number = 1;
    if (this.paginator) {
      totalPages = this.paginator.getTotalPagesToDisplay();
    }
    return `${page} ${this.lang.tra('sa_of')} ${totalPages} (${total} ${this.lang.tra('sa_sa_students')})`;
  }

  openEditIndividual(uid: number) {
    this.router.navigate([`/en/${this.getAdminPath()}/bc-fsa/score-entry/${uid}`], {
      queryParams: {
        school: this.school ? this.school.groupId : undefined,
      },
      queryParamsHandling: 'merge'
    });
  }

  handleLanguageToggle(lang: string){
    this.referesh = true;
    this.lang.setCurrentLanguage(lang);
    this.currentLang = lang;
    setTimeout(() => {
      this.referesh = false;
    }, 1000);
  }

  getAdminPath() {
    if (this.auth.isSchoolAdmin()) {
      return "school-admin";
    }
    if (this.auth.isMinistryAdmin()) {
      return "ministry-admin";
    }
    else {
      return "dist-admin";
    }
  }

  async updateTable(updateAggregatedTable: boolean, clearChangeMaps: boolean) {
    if (this.auth.isMinistryAdmin()) {
      if (!this.district || this.district.groupId < 0 || !this.selectedGrade) {
        return;
      }
    } else {
      if (!this.district || this.district.groupId < 0 || this.school === undefined || !this.selectedGrade) {
        return;
      }
    }

    this.isLoading = true;

    // score entry table
    if (this.auth.isMinistryAdmin() && this.district && this.district.groupId > 0
      || !this.auth.isMinistryAdmin() && this.school && this.school.groupId > 0) {

      if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);

      if (this.bcScoreEntry.getLastPulled()) {

        const makeKey = (uid: number, component: string, question: string) => {
          if (component == 'theme') {
            return `${uid}_theme`;
          } else {
            return `${uid}_${component.substring(0, 2).toUpperCase()}_${question || ''}`;
          }
        }

        const changes = await this.bcScoreEntry.findChangesSinceLastPulled(
          this.district.groupId,
          this.testWindow.id,
          this.selectedGrade,
          this.school ? this.school.groupId : undefined,
        );
        const changeMap = new Map<string, IScoreEntryChange>();
        changes.map((change) => {
          const {
            uid,
            component,
            question,
          } = change;

          const key = makeKey(uid, component, question);

          changeMap.set(key, change);
        });

        for (let row of this.scoreEntryRows) {
          for (let c of ['theme', 'literacy', 'numeracy']) {
            for (let q of ['1', '2', '3']) {
              const key = makeKey(row.uid, c, q);

              if (changeMap.has(key)) {
                const {
                  test_attempt_id,
                  taqr_id,
                  score,
                } = changeMap.get(key);

                row.test_attempt_id = test_attempt_id;

                if (c == 'theme') {
                  row.theme = score == null ? 0 : +score;
                  row.theme_taqr_id = taqr_id;
                } else {
                  row[`${c.substring(0, 2).toUpperCase()}_${q}`] = score;
                  row[`${c.substring(0, 2).toUpperCase()}_${q}_taqr_id`] = taqr_id;
                }
              }
            }
          }


        }
      } else {
        const { data, count } = await this.bcScoreEntry.findStudentsForScoreEntry(
          this.pagination,
          this.testWindow,
          this.district.groupId,
          this.incompleteOnly,
          this.completeOnly,
          this.selectedGrade,
          this.school ? this.school.groupId : undefined,
        );
        data.map(d => d._is_saved = true);
        this.convertNullThemeTo0(data);
        this.scoreEntryRows = data;
        this.pagination.count = count;
      }

      for (let row of this.scoreEntryRows) {
        row._is_saved = true;

        if (this.scoreChangeMap) {
          const uid = row.uid;
          for (let slug of this.fieldSlugs) {
            if (this.scoreChangeMap.has(`${uid}-${slug}`)) {
              row._is_saved = false;
              break
            }
          }
        }
        if (this.themeChangeMap) {
          const uid = row.uid;
          if (this.themeChangeMap.has(`${uid}`)) {
            row._is_saved = false;
          }
        }
        
      }

      if (clearChangeMaps) {
        this.scoreChangeMap = new Map();
        this.themeChangeMap = new Map();
      }

      this.isLoading = false;
      this.isSaving = false;

      this.resetAutoSave();
    }

    // school aggregated table
    if (updateAggregatedTable) {
      // if (this.aggregationTable) this.aggregationTable.update();
    }

  }

  private convertNullThemeTo0(rows: ScoreEntryStudentRow[]) {
    for (let row of rows) {
      if (row.theme == null) row.theme = 0;
    }
  }

  async onPaginationChange() {
    this.bcScoreEntry.resetLastPulled();
    await this.save(false);
    await this.updateTable(false, true);
  }

  async onIncompleteChanged(e) {
    this.pagination.skip = 0;
    this.bcScoreEntry.resetLastPulled();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        row_type: this.selectedRowType,
      },
      queryParamsHandling: 'merge',
    })
  }

  async searchPen() {
    this.updateFilter({
      target: {
        value: this.penToLookup,
      }
    }, 'pen');
  }

  async clearSearchPen() {
    this.penToLookup = null;
    this.isInvalidPen = false;
    this.updateFilter({
      target: {
        value: '',
      }
    }, 'pen');
  }

  areThemeAndScoreProfilesReady(): boolean {
    return this.bcScoreEntry.areProfilesReady();
  }

  onSelectedThemeInput(row: ScoreEntryStudentRow) {
    this.previousTheme = row.theme;
  }

  async onSelectedThemeChange(row: ScoreEntryStudentRow) {
    this.dataGuard.activate();

    const isNoTheme = this.bcScoreEntry.isNoTheme(row.theme);

    if (!isNoTheme) {
      this.themeChangeMap.set(`${row.uid}`, {
        uid: row.uid,
        test_attempt_id: row.test_attempt_id,
        taqr_id: row.theme_taqr_id,
        score: row.theme.toString(),
        slug: 'theme',
      });
    } else {
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('sa_se_no_theme_lit_data_loss'),
        confirm: () => {
          // remove literacy scores
          for (let slug of this.litFieldSlugs) {
            row[slug] = '';
            this.onScoreChange(row, slug as any);
          }

          // remove theme
          this.themeChangeMap.set(`${row.uid}`, {
            uid: row.uid,
            test_attempt_id: row.test_attempt_id,
            taqr_id: row.theme_taqr_id,
            score: null,
            slug: 'theme',
          });

          this.previousTheme = null;
        },
        close: () => {
          row.theme = this.previousTheme;
        }
      })

    }
  }

  onScoreInput(row: ScoreEntryStudentRow, slug: 'LI_1' | 'LI_2' | 'LI_3' | 'NU_1' | 'NU_2' | 'NU_3') {
    this.isEditing = true;
    row._is_saved = false;
  }

  onScoreChange(row: ScoreEntryStudentRow, slug: 'LI_1' | 'LI_2' | 'LI_3' | 'NU_1' | 'NU_2' | 'NU_3') {

    const alertNoTheme = () => {
      this.loginGuard.quickPopup(this.lang.tra('sa_se_only_nr_no_theme'));
    }

    const alertNotValidOption = () => {
      this.loginGuard.quickPopup(`"${row[slug]}" is not a valid option.`);
    }

    this.isEditing = false;
    this.dataGuard.activate();

    row[slug] = row[slug].toUpperCase()

    if ((this.litFieldSlugs as string[]).includes(slug) && !this.bcScoreEntry.validateLiteracyCell(row, row[slug])) {
      if (this.bcScoreEntry.isNoTheme(row.theme)) {
        alertNoTheme();
      } else {
        alertNotValidOption();
      }
      row[slug] = "";
      return;
    }

    else if (row[slug] !== '' && !this.bcScoreEntry.validateScore(this.scoreProfile, row[slug])) {

      alertNotValidOption();
      row[slug] = "";
      return;

    }

    else {
      if (row[slug] == "") {
        this.scoreChangeMap.set(`${row.uid}-${slug}`, {
          uid: row.uid,
          test_attempt_id: row.test_attempt_id,
          taqr_id: row[`${slug}_taqr_id`] as number,
          score: row[slug] == '' ? null : row[slug],
          slug,
        });
      }
      else {
        this.scoreChangeMap.set(`${row.uid}-${slug}`, {
          uid: row.uid,
          test_attempt_id: row.test_attempt_id,
          taqr_id: row[`${slug}_taqr_id`] as number,
          score: row[slug] == '' ? null : row[slug],
          slug,
        });
      }
      row._is_saved = false;
    }

  }

  async saveClicked() {
    await this.save(true);

    let rowsToAlert = this.scoreEntryRows.filter(row => {
      return this.bcScoreEntry.isScoreEntryRowPartiallyFilled(row);
    });

    if (rowsToAlert.length > 0) {
      let message: string = this.lang.tra('sa_se_alert_partial_filled') + '\n\n';

      rowsToAlert.map(row => {
        message += `\n\n<u>${row.last_name}, ${row.first_name} (${row.pen})</u>`;
      })

      this.loginGuard.quickPopup(message);
    }
  }

  async save(updateTable: boolean = true) {
    if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);

    // if no change, do nothing
    if (this.scoreChangeMap.size == 0 && this.themeChangeMap.size == 0) return;

    this.isSaving = true;

    const scoreChangeCurMap = new Map(this.scoreChangeMap);
    this.scoreChangeMap.clear();
    const themeChangeCurMap = new Map(this.themeChangeMap);
    this.themeChangeMap.clear();

    const dataMap = new Map<number, IScoreEntryData>();

    scoreChangeCurMap.forEach((change) => {

      const {
        uid,
        test_attempt_id,
        taqr_id,
        score,
        slug,
      } = change;

      let cell: IScoreEntryCell = {
        taqr_id,
        score,
        component: slug[0] == 'N' ? 'numeracy' : 'literacy',
        question: +slug[slug.length - 1],
      };

      if (dataMap.has(uid)) {
        let data = dataMap.get(uid);
        data.cells.push(cell);
      } else {
        dataMap.set(uid, {
          uid,
          test_attempt_id,
          cells: [cell],
        });
      }
    });

    themeChangeCurMap.forEach(change => {
      const {
        uid,
        test_attempt_id,
        taqr_id,
        score,
        slug,
      } = change;

      let cell: IScoreEntryCell = {
        taqr_id,
        score,
        component: 'theme',
        question: null,
      };

      if (dataMap.has(uid)) {
        let data = dataMap.get(uid);
        data.cells.push(cell);
      } else {
        dataMap.set(uid, {
          uid,
          test_attempt_id,
          cells: [cell],
        });
      }
    });

    const table: IScoreEntryData[] = [...dataMap.values()];


    const resultTable = await this.bcScoreEntry.createScore(table, this.testWindow.id, this.school.groupId);

    // apply created test attempt and taqr if any
    for (let row of resultTable) {
      const {
        test_attempt_id,
        uid,
        cells,
      } = row;

      let seRow = this.scoreEntryRows.find(row => row.uid === uid);
      if (seRow) {
        seRow.test_attempt_id = test_attempt_id;
        for (let cell of cells) {
          const {
            taqr_id,
            component,
            question,
          } = cell;

          if (component === 'theme') {
            seRow.theme_taqr_id = taqr_id;
          } else {
            const slug = `${component.substring(0, 2).toUpperCase()}_${question}_taqr_id`;
            seRow[slug] = taqr_id;
          }
        }
      }
    }

    // If we are saving under "complete rows only" or "incomplete rows only", there is a chance that a just-modified row should be hidden from the current view.
    // For simplicity, we will hard refresh the table
    if (this.incompleteOnly || this.completeOnly) {
      this.bcScoreEntry.resetLastPulled();
    }

    if (updateTable) {
      await this.updateTable(true, false);
    }

    this.isSaving = false;

    this.resetAutoSave();

    this.dataGuard.deactivate();

    console.log(this.scoreChangeMap);

  }

  async clear() {
    await this.updateTable(false, true);
  }

  importSpreadsheetClicked() {
    this.showImportSpreadSheetModal = true;
    this.validScoreEntrySpreadSheetRows = null;
  }

  resetAutoSave() {
    clearInterval(this.autoSaveInterval);
    this.autoSaveInterval = setInterval(() => {
      if (this.scoreChangeMap.size > 0 || this.themeChangeMap.size > 0) {
        this.save(true);
      }
    }, this.bcScoreEntry.getAutoSaveInterval());
  }

  isScoreEntryColumnFilled(column: string | number): boolean {
    return this.bcScoreEntry.isScoreEntryColumnFilled(column);
  }

  getStatus(row: ScoreEntryStudentRow): ScoreEntryStatus {
    return this.bcScoreEntry.getStatus(row);
  }

  markAsNotYetScoredClicked(row: ScoreEntryStudentRow) {
    if (row.is_marked_not_yet_scored) {
      this.markAsNotYetScored(row, false);
    } else {
      this.markAsNotYetScored(row, true);
    }
  }

  private async markAsNotYetScored(row: ScoreEntryStudentRow, not_yet_scored: boolean) {
    await this.bcScoreEntry.markAsNotYetScored(
      row,
      not_yet_scored,
      this.testWindow.id,
      this.school.groupId,
    );
    if (!not_yet_scored) {
      await this.save(true);
    }
    row.is_marked_not_yet_scored = not_yet_scored;
  }

  shouldDisableMarkAsNotYetScoredButton(row: ScoreEntryStudentRow): boolean {
    if (this.isSaving) return true;

    if (this.isIrtReady) return true;

    if (!this.areThemeAndScoreProfilesReady()) return true;

    if (row.is_marked_not_yet_scored) {
      if (!this.bcScoreEntry.isScoreEntryRowAllFilled(row)) return true;
    }

    return false;
  }

  async onSpreadSheetChange(files: FileList) {
    this.importSpreadSheetError = "";
    this.isUploadLoading = true;

    const postAction = () => {
      this.isUploadLoading = false;
    };

    // at least 1 file
    if (files.length < 1) {
      this.importSpreadSheetError = "Please provide a csv or Excel file.";
      postAction();
      return;
    }
    let file = files.item(0);

    // must be csv or excel
    let fileExtension = file.name.split('.').pop();
    if (!['csv', 'xlsx', 'xls'].includes(fileExtension.toLowerCase())) {
      this.importSpreadSheetError = "You can only upload a csv or Excel file.";
      postAction();
      return;
    }

    const onValidRows = (rows: any[]) => {
      this.validScoreEntrySpreadSheetRows = rows;
      postAction();
    }

    if (fileExtension.toLowerCase() == "csv") {
      Papa.parse(file, {
        header: true,
        delimiter: ",",
        complete: async (results) => {
          const validRows = await this.getValidRowsFromJson(results.data.slice(0));
          onValidRows(validRows);
        }
      });
    }
    else {
      let { json } = <any>await this.auth.excelToJson(files.item(0));
      const validRows = await this.getValidRowsFromJson(json);
      onValidRows(validRows);
    }
  }

  private async getValidRowsFromJson(rows: any[]): Promise<SpreadSheetScoreEntryRow[]> {

    const isNumber = (str: string): boolean => !isNaN(parseInt(str));

    const parseTheme = (theme: string | number): number => {
      if (!theme) return null;
      let themeStr = theme.toString();
      if (isNumber(themeStr)) return +themeStr;
      if (themeStr.toLowerCase().startsWith('theme ')) {
        let themeSubstr = themeStr.substr('theme '.length);
        if (isNumber(themeSubstr)) return +themeSubstr;
      }
      return null;
    }

    const validRows: SpreadSheetScoreEntryRow[] = [];
    const uniquePensInSpreadsheet = new Set<number>([]);

    for (let row of rows) {
      const cols = Object.keys(row);

      if (cols.length < 12) continue;

      let pen = row[cols[0]];
      let school_code = row[cols[1]];
      let theme = row[cols[2]] as string | number;
      let LI_1 = row[cols[3]];
      let LI_2 = row[cols[4]];
      let LI_3 = row[cols[5]];
      let NU_1 = row[cols[6]];
      let NU_2 = row[cols[7]];
      let NU_3 = row[cols[8]];
      let first_name = row[cols[9]];
      let last_name = row[cols[10]];
      let grade = row[cols[11]];

      const validRow: SpreadSheetScoreEntryRow = {
        pen,
        school_code,
        first_name,
        last_name,
        grade,
        LI_1,
        LI_2,
        LI_3,
        NU_1,
        NU_2,
        NU_3,
        theme: parseTheme(theme) as any,
      }

      validRows.push(validRow);
    }

    return validRows;
  }

  closeImportSpreadSheetModal() {
    this.uploadResultsTable = null;
    this.importSpreadSheetError = null;
    this.showImportSpreadSheetModal = false;
    this.validScoreEntrySpreadSheetRows = null;
  }

  async uploadAnother() {
    this.uploadResultsTable = null;

    if (this.uploadWidget) this.uploadWidget.resetFile();
  }

  async uploadSpreadSheet() {
    if (!this.validScoreEntrySpreadSheetRows || this.validScoreEntrySpreadSheetRows.length === 0) {
      return;
    }

    this.isUploadLoading = true;

    // create table
    const uploadTable: IScoreEntryUploadData[] = this.validScoreEntrySpreadSheetRows.map(row => {

      const data: IScoreEntryUploadData = {
        pen: row.pen,
        school_code: row.school_code ? row.school_code.toString() : undefined,
        first_name: row.first_name,
        last_name: row.last_name,
        grade: row.grade,
        cells: [],
      };

      for (const slug of [
        'theme',
        'LI_1',
        'LI_2',
        'LI_3',
        'NU_1',
        'NU_2',
        'NU_3',
      ]) {
        if (row[slug] != null) {
          let component: ScoreEntryComponent;
          if (slug == 'theme') component = 'theme';
          else if (slug[0] == 'L') component = 'literacy';
          else component = 'numeracy';

          let question: number | null;
          if (component == 'theme') question = null;
          else question = parseInt(slug[slug.length - 1]);

          const cell: IScoreEntryUploadCell = {
            component,
            question,
            score: row[slug] == '' ? null : row[slug],
          };

          data.cells.push(cell);
        }
      }

      return data;
    })

    const uploadResultsTable = await this.bcScoreEntry.uploadScoreEntry(this.testWindow.id, uploadTable);
    this.uploadResultsTable = uploadResultsTable.map(uploadRow => {
      const studentRow: Partial<ScoreEntryStudentRow> = {
        pen: uploadRow.pen,
        school_code: uploadRow.school_code,
        first_name: uploadRow.first_name,
        last_name: uploadRow.last_name,
        grade: uploadRow.grade,
      };

      uploadRow.cells.map(cell => {
        const {
          component,
          question,
          score,
        } = cell;
        if (score == null) return;

        if (component == 'theme') studentRow.theme = +score;
        else if (component == 'literacy') studentRow[`LI_${question}`] = score;
        else if (component == 'numeracy') studentRow[`NU_${question}`] = score;
      });

      // error
      let color: string;
      let message: string;
      let hrColor: string;

      if (uploadRow.error) {
        message = uploadRow.error.message;
        color = 'rgba(255, 0, 0, 0.3)';
        hrColor = 'rgba(255, 0, 0, 0.7)';
      } else {
        message = 'Scores have been updated for this student.';
        color = 'rgba(0, 255, 0, 0.3)';
        hrColor = 'green';
      }

      studentRow.color = color;
      studentRow.message = message;
      studentRow.hrColor = hrColor;

      return studentRow;
    });

    this.isUploadLoading = false;

    // this.closeImportSpreadSheetModal();
    await this.updateTable(true, true);
  }


  // score entry table pagination

  changeOrderBy(by: string) {
    if (this.pagination.orderBy === by) {
      this.pagination.orderDirection = this.pagination.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.pagination.orderBy = by;
      this.pagination.orderDirection = 'asc';
    }
    this.pagination.skip = 0;
    this.bcScoreEntry.resetLastPulled();
    this.updateTable(false, true);
  }

  isSortedBy(by: string, direction: 'asc' | 'desc'): boolean {
    return this.pagination.orderBy === by && this.pagination.orderDirection === direction;
  }

  toggleShowFilter(by: string) {
    if (this.visibleFilters.has(by)) {
      this.visibleFilters.delete(by);
    } else {
      this.visibleFilters.add(by);
    }
  }

  isFilterVisible(by: string): boolean {
    return this.visibleFilters.has(by);
  }

  updateFilter(event, by: string) {
    this.bcScoreEntry.resetLastPulled();
    if (event.target.value === '') {
      this.filters.delete(by);
    } else {

      switch (by) {
        case 'pen':
        case 'first_name':
        case 'last_name':
          this.filters.set(by, {
            field: by,
            condition: FilterCondition.LIKE,
            value: event.target.value,
          });
          break;
        case 'theme':
        case 'LI_1':
        case 'LI_2':
        case 'LI_3':
        case 'NU_1':
        case 'NU_2':
        case 'NU_3':
          this.filters.set(by, {
            field: by,
            condition: FilterCondition.MATCH,
            value: event.target.value,
          });
          break;
      }
    }

    if (this.filterThrottle !== null) {
      clearTimeout(this.filterThrottle);
    }
    this.filterThrottle = setTimeout(() => {
      this.pagination.filters = [...this.filters.values()];
      this.pagination.count = undefined;
      this.pagination.skip = 0;
      // this.setFilterURLParams();
      this.updateTable(false, true);
      this.filterThrottle = null;
    }, 500);
  }

  filterInitValue(field: string): string | number {
    const filter = this.filters.get(field);
    return filter ? filter.value : '';
  }

  private initializeFilters(): void {
    const params = this.route.snapshot.queryParams;
    const getFilterNum = (): number => {
      const keys: string[] = Object.keys(params)
      let index = 0;
      if (!!keys.length) {
        while (keys.indexOf(`field${index}`) !== -1) {
          ++index;
        }
      }
      return index;
    }
    let filterNum: number = getFilterNum();


    for (let i = 0; i < filterNum; i++) {
      const field = params[`field${i}`];
      const condition = params[`condition${i}`];
      const value = params[`value${i}`];
      this.filters.set(field, {
        field,
        condition,
        value
      });
      this.visibleFilters.add(field);
    }

    this.pagination.filters = [...this.filters.values()];
    this.pagination.count = undefined;
  }

  getTitleForLit(theme) {
    if (!this.isScoreEntryColumnFilled(theme)) {
      return this.lang.tra('sa_se_select_theme');
    } else {
      return ''
    }
  }

  getDisplayFromSlug(slug: 'LI_1' | 'LI_2' | 'LI_3' | 'NU_1' | 'NU_2' | 'NU_3'): string {
    return `${slug[0] == 'L' ? 'Literacy' : 'Numeracy'} Q${slug[slug.length - 1]}`;
  }

  customControl() {
    // this.bcScoreEntry.fillEmptyScoreWithNR(this.testWindow.id, this.district.groupId, this.school.groupId);
  }
}
