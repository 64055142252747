<div class="page-content">
    <bc-export-button [disabled]="isLoading" [large]="true" (export)="export()"></bc-export-button>

    <div class="dropdown-selectors">
        <!-- <div *ngIf="!isDistrictAdmin() && !isSchoolAdmin()" class="district-selector dropdown-selector">
            <bc-search-dropdown placeholder="District" [disabled]="isLoading" [inputValue]="selectedDistrict"
                [itemDisplayFn]="districtDisplayFn" [filterFn]="getFilterDistrictsFn()"
                (itemSelected)="districtSelected($event)"></bc-search-dropdown>
        </div> -->
        <div class="date-selector dropdown-selector">
            <label>
                <tra slug="da_student_login_from_date"></tra>:
            </label>
            <input class="date" type="date" required="required" [(ngModel)]="startDate" (change)="onDateChanged()" />
            <label>
                <tra slug="da_student_login_to_date"></tra>:
            </label>
            <input class="date" type="date" required="required" [(ngModel)]="endDate" (change)="onDateChanged()" />
        </div>
    </div>
    <div class="cover-with-loading">
        <div *ngIf="isLoading" class="reports-loader">
            <div></div>
        </div>

        <bc-paginated-table [columnHeadings]="columnHeadings" [pagination]="pagination"
            [columnWidths]="tableColumnWidths" [getRows]="getRows" [getDisplay]="getDisplay" [getTag]="getTag"
            [getAnyValue]="getAnyValue" [filterUpdater]="updateFilter" [defaultFilterCondition]="FilterCondition.LIKE">
        </bc-paginated-table>

    </div>
</div>