import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, Filter, FilterCondition, PaginatedRows, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { StudentLoginReportRow } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { BcPaginatedTableComponent, IColumnHeading, Tag, TextDisplay } from '../bc-paginated-table/bc-paginated-table.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'student-login-report',
  templateUrl: './student-login-report.component.html',
  styleUrls: ['./student-login-report.component.scss']
})
export class StudentLoginReportComponent implements OnInit, OnChanges {

  @ViewChild(BcPaginatedTableComponent) table: BcPaginatedTableComponent<StudentLoginReportRow>;
  @Input() testWindow: TestWindow;
  @Input() accountType: AccountType;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;

  pagination: Pagination;

  columnHeadings: IColumnHeading[] = [
    { heading: 'PEN', sortBy: 'pen' },
    { heading: 'Last Name', sortBy: 'last_name' },
    { heading: 'First Name', sortBy: 'first_name' },
    { heading: 'Grade', sortBy: 'grade' },
    { heading: 'School Name', sortBy: 'school_name' },
    { heading: 'School Code', sortBy: 'school_foreign_id' },
    { heading: 'Component', sortBy: 'test_session_slug', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Literacy', value: 1 }, { label: 'Numeracy', value: 0 }] },
    { heading: 'Total Selected Responses', sortBy: 'sr_total' },
    { heading: 'Total SR Items Completed', sortBy: 'sr_completed' },
    { heading: 'Submitted / Not Submitted', sortBy: 'is_submitted', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Submitted', value: 1 }, { label: 'Not Submitted', value: 0 }] },
    { heading: 'Confirmation Code', sortBy: 'confirmation_code', isSelect: true, options: [{ label: 'Any', value: '' }, { label: 'Has Unsubmissions', value: 1 }, { label: 'No Unsubmissions', value: 0 }] },
    { heading: 'Date & Time Started', sortBy: 'started_on', filterDisabled: true, },
    { heading: 'Date & Time Completed', sortBy: 'completed_on', filterDisabled: true, },

  ];
  tableColumnWidths: number[] = [200, 200, 200, 150, 250, 200, 200, 280, 280, 280, 300, 250, 280];

  isLoading: boolean = false;

  FilterCondition = FilterCondition;

  startDate = moment().utc().format('YYYY-MM-DD');
  endDate = moment().utc().format('YYYY-MM-DD');

  constructor(
    private bcReports: BcReportsService,
    private bcAccounts: BcAccountsService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.pagination = this.bcAccounts.getInitialPagination();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.districtDetail || changes.testWindow || changes.schoolDetail) {
      if (this.testWindow) {
        this.startDate = moment(this.testWindow.dateStart).format('YYYY-MM-DD');
        this.endDate = moment(this.testWindow.dateEnd).format('YYYY-MM-DD');
      }
      if (this.table) this.table.updateTable();
    }
  }

  export() {
    let pagination = cloneDeep(this.pagination) as Pagination;

    this.modifyFilter(pagination, {
      field: 'started_on',
      condition: FilterCondition.BETWEEN,
      value: JSON.stringify([this.startDate, this.endDate]),
    });

    let filename = `Student-Login-Report-From-${this.startDate}-To-${this.endDate}`;

    this.bcReports.exportStudentLoginReport({
      district_group_id: this.districtDetail.groupId,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      test_window_id: this.testWindow.id,
      pagination,
    }, filename);
  }

  getRows = (pagination: Pagination): Promise<PaginatedRows<StudentLoginReportRow>> => {
    if (this.districtDetail === undefined || !this.testWindow || this.schoolDetail === undefined) {
      return Promise.resolve({
        count: 0,
        data: [],
      });
    }

    this.modifyFilter(pagination, {
      field: 'started_on',
      condition: FilterCondition.BETWEEN,
      value: JSON.stringify([this.startDate, this.endDate]),
    });

    return this.bcReports.getStudentLoginReport({
      district_group_id: this.districtDetail ? this.districtDetail.groupId : undefined,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      test_window_id: this.testWindow.id,
    }, pagination);
  }

  getDisplay = (by: string, row: StudentLoginReportRow): string | TextDisplay => {
    if (by === 'school_foreign_id') {
      return this.formatSchoolCode(row.school_foreign_id)
    }

    if (by === 'test_session_slug') {
      if (row.test_session_slug.substr(4, 2) === 'LT') return 'Literacy';
      return 'Numeracy';
    }

    if (by === 'is_submitted') {
      return row.is_submitted == 1 ? 'Submitted' : 'Not Submitted';
    }

    if (by === 'confirmation_code') {
      return row.confirmation_code || 'N/A';
    }

    if (by === 'grade') {
      return row.grade;
    }

    if (['started_on', 'completed_on'].includes(by)) {
      return this.auth.formatDate(row[by]);
    }

    return row[by]
  }

  getTag = (by: string, row: StudentLoginReportRow): Tag | Tag[] => {
    if (by === 'confirmation_code') {
      if (row.has_unsubmissions == 1) {
        return {
          text: 'U',
          color: 'red',
        }
      }
    }
    return null;
  }

  getAnyValue = (by: string): string => {
    if (by === 'test_session_slug') {
      return 'Any';
    }

    if (by === 'is_submitted') {
      return 'Any';
    }
    return null;
  }

  updateFilter = (by: string, value: string | number, filters: Map<string, Filter>) => {
    if (by === 'has_unsubmissions') {
      filters.set(by, {
        field: by,
        condition: FilterCondition.MATCH,
        value: value == 'Has Unsubmissions' ? 1 : 0,
      });
    }

    else if (by === 'test_session_slug') {
      filters.set(by, {
        field: by,
        condition: FilterCondition.LIKE,
        value: this.getComponentCodePrefix(value.toString()) || value,
      })
    }

    else if (by === 'is_submitted') {
      filters.set(by, {
        field: by,
        condition: FilterCondition.MATCH,
        value: value == 'Submitted' ? 1 : 0,
      })
    }
  }

  private formatSchoolCode(schoolCode: number): string {
    return this.bcAccounts.formatSchoolCode(schoolCode);
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  onDateChanged() {
    if (this.table) this.table.updateTable();
  }

  private modifyFilter(pagination: Pagination, filter: Filter) {
    let existingFilter = (pagination.filters || []).find(f => f.field === filter.field);
    if (existingFilter) {
      existingFilter.value = filter.value;
      existingFilter.condition = filter.condition;
    } else {
      if (!pagination.filters) {
        pagination.filters = [];
      }
      pagination.filters.push(filter);
    }
  }

  private getFilter(pagination: Pagination, field: string): Filter | null {
    return (pagination.filters || []).find(f => f.field === field) || null;
  }

  private getComponentCodePrefix(value: string): string | null {
    let componentCodePrefix: string;
    let valueStr = value.toLowerCase();
    if ('literacy'.startsWith(valueStr)) {
      componentCodePrefix = 'LT';
    } else if ('numeracy'.startsWith(valueStr)) {
      componentCodePrefix = 'NM';
    } else {
      componentCodePrefix = null;
    }
    return componentCodePrefix;
  }
}
