<div class="custom-modal select-assessment-modal" *ngIf="showSelectAssessmentModal">
    <div class="modal-contents">
        <div>
            <tra slug="sa_sr_intro_p1"></tra><span>{{selectedAssessmentSession.name}}</span>
        </div>
        <div>
            <tra slug="sa_sr_intro_p2"></tra>
        </div>
        <table>
            <tbody>
                <tr *ngFor="let assessment of assessmentSessions">
                    <td>
                        <div class="assessment-option"><input type="radio"
                                [checked]="selectedAssessmentSession === assessment">
                            <div>{{assessment.name}}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="vertical-grow"></div>
        <div>
            <button (click)="selectAssessmentSession()">
                <tra slug="sa_view"></tra>
            </button>
            <button (click)="closeAssessmentSessionModal()">
                <tra slug="sa_aa_cancel"></tra>
            </button>
        </div>
    </div>
</div>
<div class="page-body">
    <div>
        <header [breadcrumbPath]="breadcrumb" [hasSidebar]="true"></header>
        <div class="page-content">
            <div *ngIf="!isInited && !noRole">
                <tra slug="sa_loading"></tra>
            </div>
            <div *ngIf="noRole">
                <tra-md
                    slug="You are not a ministry administrator. <br>Only a ministry administrator can access this page.">
                </tra-md>
            </div>
            <div *ngIf="isInited">
                <div *ngIf="false" class="notification is-danger">
                    <tra-md slug="The site is temporarily restricted"></tra-md>
                </div>
                <div *ngIf="isMinistryAdmin()" class="progress-report-header">
                    <tra slug="sa_session_reports_bc"></tra>
                </div>
                <div class="progress-reports-list">
                    <div class="reports-body" [ngClass]="{'wide': selectedView}">
                        <div class="view-container">
                            <div *ngIf="isMinistryAdmin()" class="select-assessment">
                                <div>
                                    <div>
                                        <tra slug="sa_aa_assessment_session"></tra>:
                                    </div>
                                    <div (click)="showAssessmentSessionModal()">{{selectedAssessmentSession.name}}</div>
                                </div>
                                <button *ngIf="selectedView" [ngClass]="{'selected': isSelectedAdministrationSession}"
                                    (click)="selectAdministrationSample('administration')">
                                    <tra slug="sa_sr_admin_session"></tra>
                                </button>
                                <button
                                    *ngIf="selectedView && selectedView !== BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT"
                                    [ngClass]="{'selected': !isSelectedAdministrationSession}"
                                    (click)="selectAdministrationSample('sample')">
                                    <tra slug="sa_sr_sample_assessment"></tra>
                                </button>
                            </div>
                            <bc-header-layout *ngIf="isSchoolAdmin() || isDistrictAdmin()"
                                [tra]="'sa_session_reports_bc'" [accountType]="accountType"
                                [imgSrc]="'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/session_progress_reports/1610134294563/session_progress_reports.svg'"
                                (school)="onSelectedSchoolChange($event)" (district)="onSelectedDistrictChange($event)"
                                (testWindow)="onSelectedTestWindowChange($event)">
                            </bc-header-layout>
                            <div class="list-header" *ngIf="selectedView">
                                <!-- <div *ngIf="selectedView" class="intro">
                                    <tra-md [slug]="selectedAssessmentSession.description"></tra-md>
                                </div> -->
                                <div class="report-title">
                                    {{getViewCaption(selectedView)}}
                                </div>
                                <a (click)="backToOptions()">
                                    <tra slug="sa_sa_back_to_options"></tra>
                                </a>
                            </div>
                            <div class="report-description">
                                {{getViewDescription(selectedView)}}
                            </div>
                            <div *ngIf="selectedTestWindow && selectedView" [ngSwitch]="selectedView">
                                <div *ngSwitchCase="BCFSAProgressReportsView.SCHOOL_COMPLETION">
                                    <school-completion-report [testWindow]="selectedTestWindow"
                                        [districtDetail]="districtDetail" [schoolDetail]="selectedSchool"
                                        [accountType]="accountType">
                                    </school-completion-report>
                                </div>
                                <!-- <div *ngSwitchCase="BCFSAProgressReportsView.DISTRICT_ROLL_UP">
                                    <ma-district-roll-up-report [testWindow]="selectedTestWindow">
                                    </ma-district-roll-up-report>
                                </div>
                                <div *ngSwitchCase="BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300C">
                                    <ma-enrollment-extract-report1300-c></ma-enrollment-extract-report1300-c>
                                </div>
                                <div *ngSwitchCase="BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300D">
                                    <ma-enrollment-extract-report1300-d></ma-enrollment-extract-report1300-d>
                                </div> -->
                                <div *ngSwitchCase="BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT">
                                    <school-level-data-report *ngIf="isSchoolAdmin() || isDistrictAdmin()"
                                        [testWindow]="selectedTestWindow" [districtDetail]="districtDetail"
                                        [schoolDetail]="selectedSchool" [accountType]="accountType">
                                    </school-level-data-report>
                                </div>
                                <!-- <div *ngSwitchCase="BCFSAProgressReportsView.STUDENT_RESPONSE_TIME_REPORT">
                                    <ma-student-response-time-report [testWindow]="selectedTestWindow">
                                    </ma-student-response-time-report>
                                </div>
                                <div *ngSwitchCase="BCFSAProgressReportsView.DAILY_SUBMISSION_SUMMARY">
                                    <ma-daily-summary-report></ma-daily-summary-report>
                                </div>
                                -->
                                <div *ngSwitchCase="BCFSAProgressReportsView.NOT_STARTED_SR_CR">
                                    <fsa-schools-not-started-report [districtDetail]="districtDetail"
                                        [schoolDetail]="selectedSchool" [testWindow]="selectedTestWindow">
                                    </fsa-schools-not-started-report>
                                </div>
                                <!--
                                <div *ngSwitchCase="BCFSAProgressReportsView.STUDENT_LOGIN_INFORMATION">
                                    <ma-login-report [testWindow]="selectedTestWindow"></ma-login-report>
                                </div>
                                <div *ngSwitchCase="BCFSAProgressReportsView.DAILY_DISTRICT_SUBMISSION">
                                    <ma-daily-district-submissions-report [testWindow]="selectedTestWindow">
                                    </ma-daily-district-submissions-report>
                                </div>
                                -->
                                <div *ngSwitchCase="BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT">
                                    <confirmation-code-report [districtDetail]="districtDetail"
                                        [schoolDetail]="selectedSchool" [testWindow]="selectedTestWindow"
                                        [accountType]="accountType">
                                    </confirmation-code-report>
                                </div>

                                <div *ngSwitchCase="BCFSAProgressReportsView.DISTRICT_ROLL_UP">
                                    <roll-up-report [testWindow]="selectedTestWindow" [districtDetail]="districtDetail"
                                        [schoolDetail]="selectedSchool" [accountType]="accountType"
                                        [titleSlug]="rollUpReportTitleSlug" [excludeIndependentSchools]="true"
                                        [descriptionSlug]="rollUpReportDescriptionSlug"
                                        [exportFilename]="rollUpReportFilename">
                                    </roll-up-report>
                                </div>

                                <div *ngSwitchCase="BCFSAProgressReportsView.STUDENT_LOGIN_INFORMATION">
                                    <student-login-report [testWindow]="selectedTestWindow"
                                        [districtDetail]="districtDetail" [schoolDetail]="selectedSchool"
                                        [accountType]="accountType">
                                    </student-login-report>
                                </div>

                            </div>
                        </div>
                        <table *ngIf="!selectedView" class="report-list">
                            <tr>
                                <th>
                                    <tra slug="sa_sr_report_name"></tra>
                                </th>
                                <th>
                                    <tra slug="sa_sr_desc"></tra>
                                </th>
                            </tr>
                            <tr *ngFor="let report of selectedAssessmentSession.reports">
                                <td>
                                    <a *ngIf="!report.disabled" (click)="selectView(report.id)" class="view-name">
                                        <tra [slug]="report.caption"></tra>
                                    </a>
                                    <a *ngIf="report.disabled" class="view-name" (click)="pageUnavailable()">
                                        <tra [slug]="report.caption"></tra>
                                    </a>
                                </td>
                                <td>
                                    <tra [slug]="report.description"></tra>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer [hasLinks]="true"></footer>
</div>