import { Component, Input, OnInit, Output, EventEmitter, SimpleChange } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { ALL_SCHOOLS, BcAccountsService, DistrictDetail, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { MySchoolService } from 'src/app/ui-schooladmin/my-school.service';

export interface ButtonConfig {
  title?: string,
  tra?: string,
  style?: { [key: string]: string },
  buttonId: string,
}

@Component({
  selector: 'bc-header-layout',
  templateUrl: './bc-header-layout.component.html',
  styleUrls: ['./bc-header-layout.component.scss']
})
export class BcHeaderLayoutComponent implements OnInit {


  @Input() title: string;
  @Input() tra: string;
  @Input() imgSrc: string;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;
  @Input() phantom: boolean = false;
  @Input() disableSchool: boolean = false;
  @Input() readQueryParams: boolean = true;
  @Input() initSchoolGroupId: number | string;

  @Input() buttons: ButtonConfig[] = [];
  @Input() showInactiveWindows: boolean = false;

  @Output() school = new EventEmitter();
  @Output() district = new EventEmitter();
  @Output() testWindow = new EventEmitter();
  @Output() button = new EventEmitter();

  schools: School[];
  selectedSchool: School;

  testWindows: TestWindow[];
  selectedTestWindow: TestWindow;

  isLoading: boolean = false;

  mySchoolSubscription: Subscription;
  myBoardSubscription: Subscription;

  disableAssessmentSessions = false; // deprecated
  disableSchoolSelections = false; // deprecated

  routeSubcription: Subscription;

  schoolDetail: SchoolDetail;
  districtDetail: DistrictDetail;

  isInputDebounced: boolean = true;

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private mySchool: MySchoolService,
    private myBoard: MyBoardService,
    private lang: LangService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.auth.user().subscribe(userInfo => { 
      if(userInfo){
        this.accountType = userInfo.accountType;
        this.loadDistrictAndSchools();
      }
    })
    
    this.bcAssessments.findAllTestWindows(AssessmentType.FSA, false, false).then(testWindows => {
      const activeTestWindow = testWindows.find(tw => tw.is_active);
      this.selectedTestWindow = activeTestWindow;

      this.testWindows = testWindows;

      if (this.showInactiveWindows) {
        if (!this.selectedTestWindow) {
          this.selectedTestWindow = testWindows[testWindows.length - 1];
        }
      }

      this.onSelectedTestWindowChange();
    })
    // }
  }

  isSchoolAdmin(): boolean {
    console.log("testing school -> ", this.auth.isSchoolAdmin(this.accountType))
    return this.auth.isSchoolAdmin(this.accountType);
  } 
  isDistrictAdmin(): boolean {
    console.log("testing district -> ", this.auth.isDistrictAdmin(this.accountType))
    return this.auth.isDistrictAdmin(this.accountType);
  }

  isGradSchoolAdmin(): boolean {
    return this.auth.isGradSchoolAdmin(this.accountType);
  }

  public getHideDropdownFlag(): boolean {
    return this.bcAccounts.getHideDropdownForGradSchoolAdminView();
  }

  ngOnChanges(change: SimpleChange) {
  }

  ngOnDestroy() {
    if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
    if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
    if (this.routeSubcription) this.routeSubcription.unsubscribe();
  }

  async loadDistrictAndSchools(): Promise<void> {
    // Todo: once api is configured, ask for this admin's accessible schools
    // temporarily assume this school admin has access to all schools in the district.
    const schools = await this.bcAccounts.findSchools();
    if (this.isSchoolAdmin()) {
      this.mySchool.subSchools().subscribe(schools => {
        if (schools.length > 0) {
          this.schools = schools;
          this.sortSchoolsByNameAsc();

          const { school } = this.route.snapshot.queryParams;
          let selectedSchool = this.schools.find(s => s.groupId === parseInt(school));

          if (!selectedSchool) {
            selectedSchool = this.schools[0];
          }

          this.selectedSchool = selectedSchool;
          this.onSelectedSchoolChange(this.selectedSchool);
        }
      })

      // if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
      // this.mySchoolSubscription = this.mySchool.sub().subscribe(groupList => {
      //   if (!groupList || groupList.length === 0) return;

      //   let schl_group_id = groupList[0].group_id; // default
      //   const schl_group_ids = groupList.map(g => +g.group_id);
      //   if (targetSchoolGroupId && schl_group_ids.indexOf(+targetSchoolGroupId) !== -1){
      //     schl_group_id = +targetSchoolGroupId
      //   }
      //   this.bcAccounts.getSchoolDetail(schl_group_id).then(schoolDetail => {
      //     this.schoolDetail = schoolDetail;
          
      //     const dist_group_id = schoolDetail.district.groupId;

      //     this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
      //       this.districtDetail = districtDetail;
      //       this.onDistrictDetailReady();
      //     });

      //     this.bcAccounts.findSchools(dist_group_id, assessment).then(schools => {
      //       this.schools = [];
      //       schools.forEach(school => {
      //         if (schl_group_ids.indexOf(+school.groupId) !== -1) {
      //           this.schools.push(school)
      //         }
      //       });
      //       this.sortSchoolsByNameAsc();

      //       this.coerceSchoolFromQueryParams();
      //     })
      //   })

      // })

    }

    else if (this.isDistrictAdmin()) {
      if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
      this.myBoardSubscription = this.myBoard.sub().subscribe(groupList => {
        if (!groupList || groupList.length === 0) return;

        const dist_group_id = groupList[0].group_id;

        this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
          this.districtDetail = districtDetail;
          this.onDistrictDetailReady();
        });

        this.bcAccounts.findSchools(dist_group_id, AssessmentType.FSA).then(schools => {
          this.schools = schools;
          this.sortSchoolsByNameAsc();

          this.coerceSchoolFromQueryParams();
        })
      })
    }
  }

  private coerceSchoolFromQueryParams() {
    if (this.readQueryParams) {
      this.routeSubcription = this.route.queryParams.subscribe(params => {
        let school = params.school;
        if (school) {
          if (!this.selectedSchool || +school != this.selectedSchool.groupId) {
            let match = this.schools.find(s => s.groupId === +school);
            if (match) {
              this.selectedSchool = match;
            } else if (this.auth.isGradSchoolAdmin()) {
              this.selectedSchool = null;
            } else {
              this.selectedSchool = this.schools[0];
            }
            this.school.emit(this.selectedSchool);
          }
        } else {
          if (this.auth.isGradSchoolAdmin()) {
            this.selectedSchool = null;
          } else {
            this.selectedSchool = this.schools[0];
          }
          
          this.school.emit(this.selectedSchool);
        }
      })
    } else {
      this.selectedSchool = this.schools[0];
      this.school.emit(this.selectedSchool);
    }

  }

  onDistrictDetailReady() {
    this.district.emit(this.districtDetail);
  }

  onSelectedSchoolChange(school: School) {
    this.updateQueryParams(school);

    // console.log(school);
    this.school.emit(school); 

    if (school.groupId > 0)
    {
      this.bcAccounts.getSchoolDetail(school.groupId).then((schoolDetail: SchoolDetail) => {
        this.schoolDetail = schoolDetail;
        console.log(schoolDetail);
        const dist_group_id = schoolDetail.district.groupId;
  
        this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
          this.districtDetail = districtDetail;
          this.onDistrictDetailReady();
        });
      })
    }

    else 
    {
      this.schoolDetail = null; 
    }
  }

  onSelectedTestWindowChange() {
    this.testWindow.emit(this.selectedTestWindow);
  }

  getSchoolDisplay = (school: School): string => {
    if (!school) return '';
    return this.bcAccounts.getSchoolDisplay(school);
  }

  getTestWindowDisplay(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  getTitle(): string {
    if (this.tra) {
      return this.lang.tra(this.tra);
    }
    return this.title;
  }

  extraButtonClicked(id: string) {
    this.button.emit(id);
  }

  getExtraButtonTitle(config: ButtonConfig): string {
    if (config.tra) {
      return this.lang.tra(config.tra);
    }
    return config.title || '';
  }

  forceSchoolSelection(school_group_id: number) {
    let s = this.schools.find(s => s.groupId === school_group_id);
    this.onSelectedSchoolChange(s);
  }

  updateQueryParams(school: School) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.getQueryParamsWithSchool(school),
        queryParamsHandling: 'merge',
        // skipLocationChange: true,
      });
  }

  getQueryParamsWithSchool(school: School): Params {
    let params: Params = {
      ...this.route.snapshot.queryParams,
    };
    if (school && school.groupId > 0) {
      params.school = school.groupId;
    } else {
      params.school = undefined;
    }
    return params;
  }

  getQueryParams(preserve: boolean = true): Params {
    let params: Params = {};
    if (preserve) {
      params = {
        ...this.route.snapshot.queryParams,
      };
    }
    if (this.selectedSchool && this.selectedSchool.groupId > 0) {
      params.school = this.selectedSchool.groupId;
    } else {
      params.school = undefined;
    }
    return params;
  }

  getVisibleSchools(): School[] {
    return this.schools;
  }

  private sortSchoolsByNameAsc() {
    const sortFunc = (a, b) => a.name < b.name ? -1 : 1;
    let allSchoolsIndex = this.schools.findIndex(s => s.groupId == -1);
    if (allSchoolsIndex >= 0) {
      const withoutAllSchools = this.schools.slice(0, allSchoolsIndex).concat(this.schools.slice(allSchoolsIndex + 1));
      withoutAllSchools.sort(sortFunc);
      withoutAllSchools.unshift(ALL_SCHOOLS);
      this.schools = withoutAllSchools;
    } else {
      this.schools.sort(sortFunc);
    }

  }

  public getFilterSchoolsFn() {
    const self = this;
    return (value: string | School): School[] => {
      let filtered: School[] = [];
      if (self.schools) {
        let filterValue: string = '';
        if (value) {
          if ((value as School).name) {
            filterValue = (value as School).name.toLowerCase()
          } else {
            filterValue = (value as string).toLowerCase();
          }
        }
        filtered = self.schools.filter(school => school.name.toLowerCase().includes(filterValue) || this.bcAccounts.formatSchoolCode(school.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  public schoolSelected = (event) => {
    const school: School = event.option.value;
    this.onSelectedSchoolChange(school);
  }
}
