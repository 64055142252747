import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { DistrictDetail, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntrySchoolAggregation, IScoreEntrySchoolAggregationPercentage } from 'src/app/bc-score-entry/types';
import { ngHasInputChanged } from 'src/app/ui-schooladmin/sa-accounts-students/sa-accounts-students-enrolled/util';

@Component({
  selector: 'score-entry-aggregation-table',
  templateUrl: './score-entry-aggregation-table.component.html',
  styleUrls: ['./score-entry-aggregation-table.component.scss']
})
export class ScoreEntryAggregationTableComponent implements OnInit, OnChanges {

  @Input() district: DistrictDetail;
  @Input() school: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() totalSlug: string;

  aggregation: IScoreEntrySchoolAggregation;
  aggregationPercentage: IScoreEntrySchoolAggregationPercentage;

  constructor(
    private auth: AuthService,
    private bcScoreEntry: BcScoreEntryService,
  ) { }

  ngOnInit(): void {
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (ngHasInputChanged(changes.district, 'groupId')
      || ngHasInputChanged(changes.school, 'groupId')
      || ngHasInputChanged(changes.testWindow, 'id')) {
      this.update();
    }
  }

  reset() {
    this.aggregation = this.bcScoreEntry.getEmptyAggregatedSchoolInfo();
    this.aggregationPercentage = this.calculateAggregationPercentage(this.aggregation);
  }

  private update() {
    if (this.district === undefined || this.testWindow === undefined) return;

    if (this.auth.isSchoolAdmin()) {
      if (!this.school || this.school.groupId <= 0) return;
    }
    else if (this.auth.isDistrictAdmin()) {
      if (this.school === undefined) return;
    }
    else if (this.auth.isMinistryAdmin()) {
      if (!this.district || this.district.groupId <= 0) return;
    }

    this.reset();

    if (this.auth.isSchoolAdmin()) {
      this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId, this.school.groupId).then(schoolAggregation => {
        this.aggregation = schoolAggregation;
        this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
      });
    }

    if (this.auth.isDistrictAdmin()) {
      if (this.school === null) {
        this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId).then(schoolAggregation => {
          this.aggregation = schoolAggregation;
          this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
        });
      }

      else {
        this.bcScoreEntry.generateAggregatedSchoolInfo(this.testWindow, this.district.groupId, this.school.groupId).then(schoolAggregation => {
          this.aggregation = schoolAggregation;
          this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
        });
      }
    }

    if (this.auth.isMinistryAdmin()) {
      this.bcScoreEntry.generateAggregatedSchoolInfo(
        this.testWindow,
        this.district.groupId,
        this.school && this.school.groupId > 0 ? this.school.groupId : undefined
      ).then(schoolAggregation => {
        this.aggregation = schoolAggregation;
        this.aggregationPercentage = this.calculateAggregationPercentage(schoolAggregation);
      });
    }
  }

  private calculateAggregationPercentage(schoolAggregation: IScoreEntrySchoolAggregation): IScoreEntrySchoolAggregationPercentage {
    const formatPercentage = (numerator: number, denominator: number): string => {
      if (numerator == 0 || denominator == 0 || numerator == null || denominator == null) return '0.00%';
      return `${(numerator / denominator * 100).toFixed(2)}%`;
    }
    return {
      g4: {
        enrollment: 0,
        cr_literacy: formatPercentage(schoolAggregation.g4.cr_literacy, schoolAggregation.g4.enrollment),
        cr_numeracy: formatPercentage(schoolAggregation.g4.cr_numeracy, schoolAggregation.g4.enrollment),
        sr_literacy: formatPercentage(schoolAggregation.g4.sr_literacy, schoolAggregation.g4.enrollment),
        sr_numeracy: formatPercentage(schoolAggregation.g4.sr_numeracy, schoolAggregation.g4.enrollment),
      },
      g7: {
        enrollment: 0,
        cr_literacy: formatPercentage(schoolAggregation.g7.cr_literacy, schoolAggregation.g7.enrollment),
        cr_numeracy: formatPercentage(schoolAggregation.g7.cr_numeracy, schoolAggregation.g7.enrollment),
        sr_literacy: formatPercentage(schoolAggregation.g7.sr_literacy, schoolAggregation.g7.enrollment),
        sr_numeracy: formatPercentage(schoolAggregation.g7.sr_numeracy, schoolAggregation.g7.enrollment),
      }
    }
  }


}
