<table style="width: 85%; font-size:0.85em;">
  <tr>
    <th>Voice</th>
    <td>
      <capture-voice 
        [isDisabled]="isReadOnly()"
        [element]="mcqOption[voiceoverProp]" 
        [scriptChanges]="getMcqChange()" 
        [urlChanges]="getMcqChange()" 
        title="Option Voice-over"
        [isSmall]="true"
        [isMagicEnabled]="false"
      ></capture-voice>
    </td>
  </tr>
  <tr>
    <th>Distractor</th>
    <td>
      <fieldset [disabled]="isReadOnly()">
        <input 
          class="input is-small is-fullwidth" 
          [formControl]="content" 
          type="text" 
          placeholder="Distractor Info"
        >
      </fieldset>
    </td>
  </tr>
</table>
