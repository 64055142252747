<div>

  <!-- <div class="control" *ngIf="isShowingAdvanced()">
    <div class="select is-fullwidth">
      <select [formControl]="paragraphStyle">
        <option value="option.id">(Default Text)</option>
        <option *ngFor="let option of paragraphStyles" [value]="option.id">{{option.caption}}</option>
      </select>
    </div>
  </div>

  <div class="simple-form-container" *ngIf="isShowingAdvanced()">
    <label title="Font Size">              S  <num-scrub [el]="element" prop="fontSize" [max]="6"></num-scrub></label>
    <label title="Line Height">            LH  <num-scrub [el]="element" prop="lineHeight" [max]="3"></num-scrub></label>
    <label title="Rotation">               R  <num-scrub [el]="element" prop="rotation" [min]="-180" [max]="180" [step]="0.1"></num-scrub></label>
    <label title="Adjust Text Width" *ngIf="isAdvancedInlineText()">      W  <num-scrub [el]="element" prop="advancedInlineWidth" [min]="10" [max]="100" ></num-scrub></label>
    <label> Invisible?  <input type="checkbox" [(ngModel)]="element.isInvisible" /></label>
  </div> -->
  <fieldset [disabled]="isReadOnly()">
    <label>
      <input type="checkbox" [(ngModel)]="element.isNoInvertOnHiContrast"/>
      &nbsp;<tra slug="auth_avoid_inversion_on_contrast"></tra>
    </label>
    <div>
      <label>
        <input type="checkbox" [(ngModel)]="element.isShowDynamicText"/>
        &nbsp;<tra slug="auth_show_dynamic_text"></tra>
      </label>
      <label *ngIf="element.isShowDynamicText">
        <input type="number" min="0"  [(ngModel)]="element.fromEntryId"/>
        &nbsp;<tra slug="auth_from_entry_id"></tra>
      </label>
    </div>

    <div class="control" *ngIf="isShowingAdvanced()">
      <div class="select is-fullwidth">
        <select [formControl]="paragraphStyle">
          <option value="option.id"><tra slug="auth_text_default"></tra></option>
          <option *ngFor="let option of paragraphStyles" [value]="option.id"><tra [slug]="option.caption"></tra></option>
        </select>
      </div>
    </div>
    
    <div class="simple-form-container" *ngIf="isShowingAdvanced()">
      <label title="Font Size">              S  <num-scrub [el]="element" prop="fontSize" [max]="6"></num-scrub></label>
      <label title="Line Height">            LH  <num-scrub [el]="element" prop="lineHeight" [max]="3"></num-scrub></label>
      <label title="Rotation">               R  <num-scrub [el]="element" prop="rotation" [min]="-180" [max]="180" [step]="0.1"></num-scrub></label>
      <label title="Adjust Text Width" *ngIf="isAdvancedInlineText()">      W  <num-scrub [el]="element" prop="advancedInlineWidth" [min]="10" [max]="100" ></num-scrub></label>
      <label> <tra slug="auth_invisible"></tra>  <input type="checkbox" [(ngModel)]="element.isInvisible" /></label>
    </div>
  
    <div *ngIf="isShowingAdvanced()">
      <button *ngFor="let align of alignments" [disabled]="isReadOnly()" class="button is-small"  (click)="setAlignment(align.id)">
        <i class="fa {{align.icon}}"></i>
      </button>
    </div>
    
    <div *ngIf="isSimpleText()">
      <div class="field">
        <!-- <label class="label">Caption</label> -->
        <p class="control">
          <textarea 
            [formControl]="caption" 
            cdkTextareaAutosize 
            [cdkTextareaAutosize]="true" 
            [cdkAutosizeMinRows]="2"
            class="textarea"
          ></textarea>
          <!-- -->
        </p>
      </div>      
    </div>
  </fieldset>
  <div *ngIf="isLink()">
    <div class="field">
      <p class="control">
        <element-config-text-link *ngIf="element.link" [element]="element.link"></element-config-text-link>
      </p>
    </div>
  </div>

  <!-- <div class="select is-fullwidth" [style.margin-top.em]="0.5" *ngIf="isShowingAdvanced()">
    <select [(ngModel)]="this.element.font">
      <option value="">Default Text</option>
      <option *ngFor="let font of fontStyles; let index = index" [value]="font.id">
        {{font.caption}}
      </option>
    </select>
  </div>

  <input *ngIf="isShowingAdvanced()" type="color" [(ngModel)]="element.colour" style="margin-top: 0.5em"> -->
  <fieldset [disabled]="isReadOnly()">
    <div class="select is-fullwidth" [class.is-disabled]="isReadOnly()" [style.margin-top.em]="0.5" *ngIf="isShowingAdvanced()">
      <select [(ngModel)]="this.element.font">
        <option value=""><tra slug="auth_default_text"></tra></option>
        <option *ngFor="let font of fontStyles; let index = index" [value]="font.id">
          {{font.caption}}
        </option>
      </select>
    </div>
  
    <input *ngIf="isShowingAdvanced()" type="color" [(ngModel)]="element.colour" style="margin-top: 0.5em">
  </fieldset>

  <div *ngIf="isAnnotation()">
    <element-config-annotation [element]="element"></element-config-annotation>
  </div>

  <div *ngIf="isAdvancedInlineText() || isListText()">
    <div>
      <div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(element.advancedList, $event);">
        <div cdkDrag *ngFor="let contentElement of element.advancedList" class="nested-element">
          <div [class.is-disabled]="isReadOnly()" class="nested-element-header" cdkDragHandle>
            <i class="fa" [ngClass]="getIconByElementTypeId(contentElement.elementType)" aria-hidden="true"></i>
            <a [class.is-disabled]="isReadOnly()" class="button">
              <i class="fas fa-trash" (click)="removeElement(element.advancedList, contentElement);" aria-hidden="true"></i>
            </a>                  
          </div>
          <div class="nested-element-content">
            <element-config [contentElement]="contentElement"></element-config>
          </div>
        </div>
      </div>
<!-- 
      <select-element
        [elementTypes]="elementTypes"
        (insert)="insertAdvancedListEntry($event)"
        style="margin-top:1em;" 
      >
      </select-element>
    </div>
  </div>

  <div *ngIf="isParagraphs()">

    <hr *ngIf="isShowingAdvanced()" />

    <h5>Paragraph Settings</h5>

    <div class="simple-form-container">
      <label title="Width"> W  <num-scrub [el]="element" prop="paragraphWidth" [step]="0.5" [max]="40"></num-scrub></label>
      <label title="Space after Paragraph"> SAP  <num-scrub [el]="element" prop="paragraphSpaceAfter" [max]="8"></num-scrub></label>
      <label title="Font Size">
        Num
        <select [(ngModel)]="element.paragraphNumberingPos" style="width: 4em;">
          <option value="NONE">No Paragraph Numbering</option>
          <option value="LEFT">Left</option>
          <option value="RIGHT">Right</option>
        </select>
      </label>
      <label title="Number Margin"> NM  <num-scrub [el]="element" prop="paragraphNumberingMargin" [max]="15" ></num-scrub></label>
      <label title="Number Margin"> NS  <num-scrub [el]="element" prop="paragraphNumberingScale" [max]="1.5" ></num-scrub></label>
      <label title="Number Margin"> SI  <num-scrub [el]="element" prop="paragraphNumberingStart" [step]="1" [min]="0" [max]="30" ></num-scrub></label>
    </div>

    <div>
      <div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(getParagraphList(), $event);">
        <div cdkDrag *ngFor="let paragraph of getParagraphList()" class="nested-element is-horizontal">
          <div>
            <button cdkDragHandle>
              <i class="fas fa-arrows-alt"></i>
            </button>
            <div>
              <num-scrub [el]="paragraph" prop="paddingLeft" [max]="5" ></num-scrub>
            </div>
            <div style="margin-top:2em;" title="Space after Paragraph">
              SAP
              <num-scrub [el]="paragraph" prop="paragraphSpaceAfter" [max]="5" ></num-scrub>
            </div>
          </div>
          <div style="flex-grow:1;">
            <textarea 
              [(ngModel)]="paragraph.caption" 
              cdkTextareaAutosize 
              [cdkTextareaAutosize]="true" 
              [cdkAutosizeMinRows]="2"
              class="textarea"
            ></textarea>
          </div>
          <div>
            <div>
              <num-scrub [el]="paragraph" prop="paddingRight" [max]="5" ></num-scrub>
            </div>
            <div style="margin-top:2em;" title="Numbering override">
              NO
              <input type="text" style="width:4em;" [(ngModel)]="paragraph.numberingOverride" />
            </div>
            <a>
              <i class="fas fa-trash" (click)="removeElement(getParagraphList(), paragraph);" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <button class="button is-primary is-small" (click)="getParagraphList().push({})">
      Add Paragraph
    </button>

    <hr/> -->

      <select-element
        [elementTypes]="elementTypes"
        (insert)="insertAdvancedListEntry($event)"
        style="margin-top:1em;" 
      >
      </select-element>
    </div>
  </div>

  <fieldset>
    <div *ngIf="isParagraphs()">
  
      <hr *ngIf="isShowingAdvanced()" />
  
      <h5>Paragraph Settings</h5>
  
      <div class="simple-form-container">
        <label title="Width"> W  <num-scrub [el]="element" prop="paragraphWidth" [step]="0.5" [max]="40"></num-scrub></label>
        <label title="Space after Paragraph"> SAP  <num-scrub [el]="element" prop="paragraphSpaceAfter" [max]="8"></num-scrub></label>
        <label title="Font Size">
          Num
          <select [(ngModel)]="element.paragraphNumberingPos" style="width: 4em;">
            <option value="NONE">No Paragraph Numbering</option>
            <option value="LEFT">Left</option>
            <option value="RIGHT">Right</option>
          </select>
        </label>
        <label title="Number Margin"> NM  <num-scrub [el]="element" prop="paragraphNumberingMargin" [max]="15" ></num-scrub></label>
        <label title="Number Margin"> NS  <num-scrub [el]="element" prop="paragraphNumberingScale" [max]="1.5" ></num-scrub></label>
        <label title="Number Margin"> SI  <num-scrub [el]="element" prop="paragraphNumberingStart" [step]="1" [min]="0" [max]="30" ></num-scrub></label>
      </div>
  
      <div>
        <div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(getParagraphList(), $event);">
          <div cdkDrag *ngFor="let paragraph of getParagraphList()" class="nested-element is-horizontal">
            <div>
              <button [class.is-disabled]="isReadOnly()" cdkDragHandle>
                <i class="fas fa-arrows-alt"></i>
              </button>
              <div>
                <num-scrub [el]="paragraph" prop="paddingLeft" [max]="5" ></num-scrub>
              </div>
              <div style="margin-top:2em;" title="Space after Paragraph">
                SAP
                <num-scrub [el]="paragraph" prop="paragraphSpaceAfter" [max]="5" ></num-scrub>
              </div>
            </div>
            <div style="flex-grow:1;">
              <textarea 
                [(ngModel)]="paragraph.caption" 
                cdkTextareaAutosize 
                [cdkTextareaAutosize]="true" 
                [cdkAutosizeMinRows]="2"
                class="textarea"
              ></textarea>
            </div>
            <div>
              <div>
                <num-scrub [el]="paragraph" prop="paddingRight" [max]="5" ></num-scrub>
              </div>
              <div style="margin-top:2em;" title="Numbering override">
                NO
                <input type="text" style="width:4em;" [(ngModel)]="paragraph.numberingOverride" />
              </div>
              <a>
                <i class="fas fa-trash" (click)="removeElement(getParagraphList(), paragraph);" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
  
      <button class="button is-primary is-small" (click)="getParagraphList().push({})">
        Add Paragraph
      </button>
  
      <hr/>
  
    </div>
  </fieldset>

  <hr  *ngIf="isShowAdvancedOptions.value"/>

  <div>
    <label *ngIf="!element.isAdvancedOptionsDisabled" class="checkbox">
      <input type="checkbox" [formControl]="isShowAdvancedOptions">
      <tra slug="auth_show_advanced_options"></tra>
    </label>
  </div>

</div>