<div class="student-individual-report">
    <br>
    <div class="single-student" [class.is-single]="!isBulk">
        <div class="single-student-header-name isr-header-rows-container">
            <div class="isr-header-rows">
                <tra-md slug="sa_fr_ministry_of_education"></tra-md>
                <img class="report-logo" [src]="imageSource">
            </div>
            <div class="space-between">
                <div>
                    <div> <strong>
                            <tra slug="sa_fr_student_name"></tra>
                        </strong> {{singleModeData.singleModeName?.last_name}},
                        {{singleModeData.singleModeName?.first_name}} </div>
                    <div> <strong>
                            <tra slug="sa_isrr_school_name"></tra>
                        </strong>
                        {{singleModeData.studentSchool}}
                    </div>
                </div>
                <div>
                    <div> <strong>
                            <tra slug="sa_fr_personal_edunum"></tra>
                        </strong> {{singleModeData.pen}} </div>
                    <div> <strong>
                            <tra slug="sa_isrr_grade"></tra>
                        </strong> {{singleModeData.grade}} </div>
                </div>
            </div>
        </div>
        <div class="report-section">
            <div class="report-intro">
                <p>
                    <tra-md slug="sa_fr_issr_report_intro"></tra-md>
                    <strong>
                        {{translateLevel(getProficiencyLevel('literacy', singleModeData.literacyScore))}}
                    </strong>
                </p>
            </div>
            <table>
                <thead class="literacy_level_thead">
                    <tr>
                        <th class="emergingThLiteracy" *ngFor="let header of TableHeaders; index as i"
                            [class.active]="getProficiencyLevel('literacy', singleModeData.literacyScore) == Levels[i]">
                            <tra [slug]="header"></tra>
                        </th>
                    </tr>
                </thead>
                <tr>
                    <td class="emergingTdLiteracy" *ngFor="let content of LiteracyTableContent; index as i"
                        [class.active]="getProficiencyLevel('literacy', singleModeData.literacyScore) == Levels[i]">
                        <tra-md [slug]="content"></tra-md>
                    </td>
                </tr>
            </table>
            <br>
            <div><strong>
                    <tra-md class="one-line-slug" [isCondensed]="true" slug="sa_fr_issr_LitNumSection"></tra-md>
                    {{translateLevel(getProficiencyLevel('numeracy', singleModeData.numeracyScore))}}
                </strong>
            </div>
            <br>
            <table>
                <thead class="literacy_level_thead">
                    <tr>
                        <th class="emergingTdNumeracy" *ngFor="let header of TableHeaders; index as i"
                            [class.active]="getProficiencyLevel('numeracy', singleModeData.numeracyScore) == Levels[i]">
                            <tra [slug]="header"></tra>
                        </th>
                    </tr>
                </thead>
                <tr>
                    <td class="emergingTdNumeracy" *ngFor="let content of NumeracyTableContent; index as i"
                        [class.active]="getProficiencyLevel('numeracy', singleModeData.numeracyScore) == Levels[i]">
                        <tra-md [slug]="content"></tra-md>
                    </td>
                </tr>
            </table>
            <br>
            <div class="report-footer isr-footer-rows">
                <tra-md slug="sa_fr_issr_footer"></tra-md>
            </div>
        </div>
    </div>

</div>