import { Component, OnInit, Input } from '@angular/core';
import { ElementType } from '../../ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'element-config',
  templateUrl: './element-config.component.html',
  styleUrls: ['./element-config.component.scss']
})
export class ElementConfigComponent implements OnInit {

  @Input() contentElement;

  constructor(public editingDisabled: EditingDisabledService) { }

  ElementType = ElementType;

  ngOnInit() {
  }

}
