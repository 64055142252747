import { CustomInteractionType, getElementWeight, ElementType, IEntryStateCartesianPlane, QuestionState, IContentElementCartesianPlane, IContentElementScaleRadius } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "src/app/ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from 'pixi.js-legacy';
import * as _ from 'lodash';
import { Direction, ScoringTypes } from "../../models";
import { MatOptionSelectionChange } from "@angular/material/core";
import { stringify } from "querystring";
import { SpriteLoader } from "./sprite-loader";

export interface Option {
    image?: PIXI.Sprite,
    label?: PIXI.Text,
    fontSize?: number,
    origX: number,
    origY: number,
    id?: string,
    xCoordinate?: number,
    yCoordinate?: number,
    width?: number,
    height?: number,
    gap?: number
}

export interface AxisInfo {
    text?: PIXI.Text,
    displayCoord: number,
    axisCoord: number
}

export class RenderGridDND extends CustomInteractionCtrl {
    element: IContentElementCartesianPlane;
    grid: PIXI.Graphics;
    graph: PIXI.Container;
    options: Option[];
    spriteLoader: SpriteLoader;

    xAxisDisplayLength;
    yAxisDisplayLength;
    xAxisLabelInc;
    yAxisLabelInc;
    xAxisUnitsLabel;
    yAxisUnitsLabel;
    axisLabelFontSize;
    axisThickness;
    gridThickness;
    removeGraphic;
    textRes;    
    isResponded: any;

    constructor(element: IContentElementCartesianPlane, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, removeGraphic, textToSpeech) {
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);

        this.spriteLoader = new SpriteLoader();        
        this.element = element;
        // this.spriteLoader.loadSprites().then()
        this.removeGraphic = removeGraphic
        this.xAxisDisplayLength = element.xLength
        this.yAxisDisplayLength = element.yLength
        this.xAxisUnitsLabel = element.xAxisLabel
        this.yAxisUnitsLabel = element.yAxisLabel
        this.axisLabelFontSize = element.axisLabelFontSize
        this.axisThickness = element.thicknessOfAxis
        this.gridThickness = element.thicknessOfLines
        this.xAxisLabelInc = element.incDistanceBetweenLabelsX
        this.yAxisLabelInc = element.incDistanceBetweenLabelsY
        this.textRes = 2
        if (!this.xAxisDisplayLength) {
            this.xAxisDisplayLength=1
        }
        if (!this.yAxisDisplayLength) {
            this.yAxisDisplayLength=1
        }
        if (!this.axisLabelFontSize) {
            this.axisLabelFontSize = 5
        }
        this.graph = new PIXI.Container();
        this.graph.interactive = true;
        this.graph.zIndex = 2
        this.grid = new PIXI.Graphics()
        this.graph.addChild(this.grid);
        this.addGraphic(this.graph);
        this.grid.interactive = true;
        this.grid.zIndex = 2
        this.canvasGR = new PIXI.Graphics()
        this.addGraphic(this.canvasGR)
        this.canvasGR.interactive = true;
        this.canvasGR.zIndex = 1;
        //this.canvasGR.on('mouseup', ($event) => {this.deactivateMouseDown($event)});
        if (!this.options) this.options = [];
        
        this.drawCanvas()
        this.loadAssets().then(this.init)
        this.render()
    }

    init = ({resources}) => {
        // console.log(resources ,this.spriteLoader.loader.resources)
        if (this.element.enableDrags) {
            if (this.element.draggableSide == Direction.UP) {
                this.drawItems(this.element.options);
                this.graph.y = this.itemSectionHeight
                this.drawSquares()
            } else if (this.element.draggableSide == Direction.LEFT) {
                this.drawItems(this.element.options)
                this.graph.x = this.itemSectionWidth
                this.drawSquares()
            } else {
                this.drawSquares()
                this.drawItems(this.element.options)
            }
        } else {
            this.drawItems(this.element.options)
            this.drawSquares()
        }
        this.graph.y += 10
        this.calculateGraphDims()
        if (this.element.canvasWidth<this.graph.width) {
            this.element.canvasWidth= this.graph.width
        }
        if (this.element.canvasHeight<this.graph.height) {
            this.element.canvasHeight = this.graph.height
        }
        if ((this.element.canvasWidth < this.graph.width+this.itemSectionWidth) && (this.element.draggableSide==Direction.RIGHT || this.element.draggableSide==Direction.LEFT)) {
            this.element.canvasWidth = this.graph.width+this.itemSectionWidth
        } else if ((this.element.canvasHeight < this.graph.height+this.itemSectionHeight) && (this.element.draggableSide==Direction.DOWN || this.element.draggableSide==Direction.UP)) {
            this.element.canvasHeight = this.graph.height+this.itemSectionHeight
        }
        // this.drawCanvas()
        this.render()
    }

    loadAssets = () => {
        let assets = []
        let arrow = this.getImage()
        assets.push({name: 'arrowhead', path: arrow});
        this.element.options.forEach((option,id) => {
            if(option.img && option.img.url){
                let name = id.toString()
                let path = this.getImage(option)
                assets.push({name, path})
            }
        })
        this.spriteLoader.addSpritestoLoader(assets)
        return this.spriteLoader.loadSprites();        
    }


    calculateGraphDims() {
        this.graph.width = this.leftLabelWidth + this.xAxisDisplayLength;
        this.graph.height = this.bottomLabelHeight + this.yAxisDisplayLength;
    }

    getNumRows() {
        return this.xAxisDisplayLength/this.calculateXInc()
    }

    getNumColumns() {
        return this.yAxisDisplayLength/this.calculateYInc()
    }

    handleNewState() {
        this.drawSquares()
        const state = this.questionState[this.element.entryId]
        this.isResponded = state ? state.isResponded : undefined;
        if (state.optionStates) {
            this.options.forEach((option)=>{
                state.optionStates.forEach((storedOption)=>{
                    if (option.id==storedOption.id) {
                        if (option.image) {
                            option.xCoordinate = storedOption.currX
                            option.yCoordinate = storedOption.currY
                            this.xAxisInfos.forEach((info)=>{
                                if (info.axisCoord == option.xCoordinate) {
                                    option.image.x = info.displayCoord - option.image.width/2
                                }
                            })
                            this.yAxisInfos.forEach((info)=>{
                                if (info.axisCoord == option.yCoordinate) {
                                    option.image.y = info.displayCoord - option.image.height/2
                                }
                            })
                            if (option.xCoordinate != undefined && option.yCoordinate != undefined) {
                                this.removeGraphic(option.image)
                                this.grid.addChild(option.image)
                            } 
                        }
                    }
                })
            })
        }
        
        this.render();
    }

    getXStart() {
        return this.graph.x+this.grid.x;
    }

    getYStart() {
        return this.graph.y+this.grid.y;
    }

    registerMouseEvents(obj:Option) {
        obj.image.on('mousedown', ($event) => {this.activateMouseDown(obj)});
        obj.image.on('touchstart', ($event) => {this.activateMouseDown(obj)})
        obj.image.on('mouseup', ($event) => {this.deactivateMouseDown($event)});
        obj.image.on('touchend', ($event) => {this.deactivateMouseDown($event)});
        const mouseMove = ($event) => {
            if (this.currentObj == obj) {
                this.isResponded = true;
                this.changeLocation($event, obj.image)
            }
        }
        obj.image.on('mousemove', mouseMove);
        obj.image.on('touchmove', mouseMove);
    }

    getSnappedAxis(num: number, gap:number, sideLength:number, origin:number, end:number, lineCoords:AxisInfo[]) {
        if (num-sideLength>end || num+sideLength<origin) {
            return undefined
        }

        if (!num) {
            return undefined
        }
        
        let closestLine = undefined;
        let minDiff = -1;
        let coord = undefined;
        lineCoords.forEach((coordinate, i)=>{
            const overAllCoord = coordinate.displayCoord
            let diff = num - overAllCoord
            if (diff < 0) {
                diff *= -1
            }
            if (minDiff == -1 || diff < minDiff) {
                minDiff = diff
                closestLine = overAllCoord
                coord = coordinate.axisCoord
            }
        })
        if (!closestLine) {
            closestLine = 0
            coord = 0
        }

        return {coordinate: coord, loc: closestLine}
    }

    currentObj:Option

    activateMouseDown(obj:Option) {
        this.currentObj = obj
        this.grid.removeChild(obj.image)
        this.addGraphic(obj.image)
    }
    
    deactivateMouseDown($event) {
        if (!this.currentObj) {
            return
        }
        const xStart = this.getXStart()
        const yStart = this.getYStart()
        const p = $event.data.global
        const x = (p.x - xStart)/this.zoom;
        const y = (p.y - yStart)/this.zoom;
        const snapXObj = this.getSnappedAxis(x, this.element.xGap, this.currentObj.width, xStart, xStart+this.xAxisDisplayLength, this.xAxisInfos)
        const snapYObj = this.getSnappedAxis(y, this.element.yGap, this.currentObj.height, yStart, yStart+this.yAxisDisplayLength, this.yAxisInfos)
        if (snapYObj != undefined && snapXObj != undefined) {
            const snapX = snapXObj.loc
            const snapY = snapYObj.loc
            this.removeGraphic(this.currentObj.image)
            this.grid.addChild(this.currentObj.image)
            this.currentObj.image.x = snapXObj.loc-this.currentObj.width/2
            this.currentObj.image.y = snapYObj.loc-this.currentObj.height/2
            this.currentObj.xCoordinate = snapXObj.coordinate
            this.currentObj.yCoordinate = snapYObj.coordinate

        } else {
           this.currentObj.image.x = this.currentObj.origX
           this.currentObj.image.y = this.currentObj.origY

           this.currentObj.xCoordinate = undefined
           this.currentObj.yCoordinate = undefined
        }
        this.throttledUpdate()
        this.currentObj = undefined
    }

    changeLocation($event, obj) {
        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom

        obj.x = x-obj.width/2;
        obj.y = y-obj.height/2;

        this.throttledUpdate()
    }

    throttledUpdate = _.throttle(()=>{
        this.updateState();
        this.render()
    }, 20)

    getUpdatedState() {
        const weight = getElementWeight(this.element)
        let score = 0;
        const saveOptionInfo = []
        let isStarted = false
        let isFilled = true
        this.options.forEach((option)=>{
            const currX = option.xCoordinate
            const currY = option.yCoordinate
            if (currX != undefined && currY != undefined) {
                isStarted = true;
            } else {
                isFilled = false;
            }
            saveOptionInfo.push({currX, currY, id: option.id})
            this.element.options.forEach(element => {
                if (option.id==element.id && option.id != undefined) {
                    if (currY == element.correctY && currX == element.correctX) {
                        score++;
                    }
                }
            });
        })
        if (this.element.options.length>score) {
            score = 0;
        } else {
            score = weight
        }
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.GRID_DND,
            optionStates: saveOptionInfo,
            isStarted: isStarted,
            isFilled: isFilled,
            isResponded:this.isResponded,
            isCorrect: score==weight,
            score: score,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        }
    }

    canvasGR: PIXI.Graphics

    drawCanvas() {
        const canvas = new PIXI.Rectangle(0, 0, this.element.canvasWidth, this.element.canvasHeight);
        // this.canvasGR.beginFill(0xFFFFFF);
        this.canvasGR.drawShape(canvas)
        this.canvasGR.endFill()
    }

    calculateXInc() {
        return (this.element.maxX-this.element.minX)/(this.xAxisDisplayLength/this.element.xGap)
    }

    calculateYInc() {
        return (this.element.maxY-this.element.minY)/(this.yAxisDisplayLength/this.element.yGap)
    }

    leftLabelWidth = 0;
    bottomLabelHeight = 0;

    yAxisInfos: AxisInfo[]
    xAxisInfos: AxisInfo[]

    drawGridLine(min:number, max:number, gap:number, length:number, otherAxisLength:number, axisThickness:number, lineThickness:number, horizontal: boolean) {
        if (gap==0 || !gap) {
            gap = 1
        }
        if (min==undefined) {
            min = 0;
        }
        if (max==undefined) {
            max = 0;
        }
        if (min>max) {
            min = max;
        }
        let axisCoordinate = 0;
        
        if (max<=0) {
            axisCoordinate = length;
        } else if (min>=0) {
            axisCoordinate = 0;
        } else {
            const ratio = (-1*min)/(max-min)
            axisCoordinate = length*ratio;
        }
        console.log(this.xAxisLabelInc, this.yAxisLabelInc)
        if (!horizontal) {
            const x = axisCoordinate+this.leftLabelWidth
            const inc = gap
            const visualGap = (gap/(max-min))*length
            this.grid.lineStyle(axisThickness, this.getColor()).moveTo(x, 0).lineTo(x, otherAxisLength);
            // const arrow = PIXI.Sprite.from(this.getImage())
            const arrow = PIXI.Sprite.from(this.spriteLoader.loader.resources['arrowhead'].texture)
            this.grid.addChild(arrow);
            arrow.width = 4*axisThickness
            arrow.height = 4*axisThickness;
            arrow.angle = 270;
            arrow.x = x-arrow.width/2;
            arrow.y = 0;
            this.xAxisInfos.push({displayCoord: x, axisCoord: 0})
            for (let i = 1;axisCoordinate+i*visualGap<=length;i++) {
                const coord = axisCoordinate+visualGap*i+this.leftLabelWidth;
                const curr = i*inc
                this.grid.lineStyle(lineThickness, this.getColor()).moveTo(coord, 0).lineTo(coord, otherAxisLength);
                let unitLabel:PIXI.Text = undefined;
                if (!this.xAxisLabelInc || i%this.xAxisLabelInc==0) {
                    //unitLabel = new PIXI.Text((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize))
                    const unitLabel = this.getText((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize), this.textRes, undefined, otherAxisLength)
                    this.grid.addChild(unitLabel);
                    unitLabel.x = coord-unitLabel.width/2;
                    if (this.bottomLabelHeight<unitLabel.height) {
                        this.bottomLabelHeight = unitLabel.height
                    }
                    unitLabel.zIndex = 8;
                }
                this.xAxisInfos.push({text: unitLabel, displayCoord: coord, axisCoord: curr})
            }
            for (let i = 1;axisCoordinate-visualGap*i>=0;i++) {
                const coord = axisCoordinate-visualGap*i+this.leftLabelWidth;
                const curr = -1*i*inc
                this.grid.lineStyle(lineThickness, this.getColor()).moveTo(coord, 0).lineTo(coord, otherAxisLength);
                let unitLabel:PIXI.Text = undefined;
                if (!this.xAxisLabelInc || i%this.xAxisLabelInc==0) {
                    //unitLabel = new PIXI.Text((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize))
                    unitLabel = this.getText((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize), this.textRes, undefined, otherAxisLength)
                    this.grid.addChild(unitLabel);
                    unitLabel.x = coord-unitLabel.width/2;
                    if (this.bottomLabelHeight<unitLabel.height) {
                        this.bottomLabelHeight = unitLabel.height
                    }
                    unitLabel.zIndex = 8;
                }
                this.xAxisInfos.push({text: unitLabel, displayCoord: coord, axisCoord: curr})
            }
        } else {
            const leftLabelWidthStart = this.leftLabelWidth
            const inc = gap
            const visualGap = (gap/(max-min))*length
            for (let i = 1;(this.yAxisDisplayLength - axisCoordinate)+visualGap*i<=length;i++) {
                const coord = (this.yAxisDisplayLength - axisCoordinate)+visualGap*i
                const curr = -1*i*inc
                let unitLabel:PIXI.Text = undefined
                if (!this.yAxisLabelInc || i%this.yAxisLabelInc==0) {
                    //unitLabel = new PIXI.Text((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize))
                    unitLabel = this.getText((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize), this.textRes, leftLabelWidthStart, undefined)
                    this.grid.addChild(unitLabel);
                    unitLabel.y = coord-unitLabel.height/2;
                    if (this.leftLabelWidth<unitLabel.width+unitLabel.x) {
                        this.leftLabelWidth = unitLabel.width+unitLabel.x
                    }
                    unitLabel.zIndex = 8;
                }
                this.yAxisInfos.push({text: unitLabel, displayCoord: coord, axisCoord: curr})
            }
            for (let i = 1;this.yAxisDisplayLength -axisCoordinate-visualGap*i>=0;i++) {
                const coord = (this.yAxisDisplayLength -axisCoordinate)-visualGap*i;
                const curr = i*inc
                let unitLabel:PIXI.Text = undefined
                if (!this.yAxisLabelInc || i%this.yAxisLabelInc==0) {
                    //unitLabel = new PIXI.Text((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize))
                    unitLabel = this.getText((curr).toString(), this.getTextStyle(this.element.axisLabelFontSize), this.textRes, leftLabelWidthStart, undefined)
                    this.grid.addChild(unitLabel);
                    unitLabel.y = coord-unitLabel.height/2;;
                    if (this.leftLabelWidth<unitLabel.width+unitLabel.x) {
                        this.leftLabelWidth = unitLabel.width+unitLabel.x
                    }
                    unitLabel.zIndex = 8;
                }
                this.yAxisInfos.push({text: unitLabel, displayCoord: coord, axisCoord: curr})
            }

            for (let i = 1;(this.yAxisDisplayLength - axisCoordinate)+visualGap*i<=length;i++) {
                const coord = (this.yAxisDisplayLength - axisCoordinate)+visualGap*i
                this.grid.lineStyle(lineThickness, this.getColor()).moveTo(this.leftLabelWidth, coord).lineTo(this.leftLabelWidth+otherAxisLength, coord);
            }
            for (let i = 1;this.yAxisDisplayLength -axisCoordinate-visualGap*i>=0;i++) {
                const coord = (this.yAxisDisplayLength -axisCoordinate)-visualGap*i;
                this.grid.lineStyle(lineThickness, this.getColor()).moveTo(this.leftLabelWidth, coord).lineTo(otherAxisLength+this.leftLabelWidth, coord);
            }

            const x = this.leftLabelWidth
            const y = this.yAxisDisplayLength -axisCoordinate
            this.grid.lineStyle(axisThickness, this.getColor()).moveTo(x, y).lineTo(otherAxisLength+x, y);
            this.yAxisInfos.push({displayCoord: y, axisCoord: 0})
            // const arrow = PIXI.Sprite.from(this.getImage())
            const arrow = PIXI.Sprite.from(this.spriteLoader.loader.resources['arrowhead'].texture)
            this.grid.addChild(arrow);
            arrow.width = 4*axisThickness
            arrow.height = 4*axisThickness
            arrow.x = otherAxisLength+x;
            arrow.y = y-arrow.height/2
        }
    }

    drawSquares() {
        this.graph.removeChildren()
        this.graph.addChild(this.grid)
        this.grid.lineStyle(2, this.getColor())
        this.leftLabelWidth = 0;
        this.bottomLabelHeight = 0;
        if (this.element.yAxisLabel) {
            //const unitLabel:PIXI.Text = new PIXI.Text(this.element.yAxisLabel, this.getTextStyle(this.element.axisLabelFontSize))
            const unitLabel:PIXI.Text = this.getText(this.element.yAxisLabel, this.getTextStyle(this.element.axisLabelFontSize), this.textRes, 0, undefined)
            this.grid.addChild(unitLabel);
            unitLabel.y = this.yAxisDisplayLength/2+unitLabel.width/2
            unitLabel.angle=270
            unitLabel.zIndex = 13
            this.leftLabelWidth += unitLabel.height
        }
        this.xAxisInfos = []
        this.yAxisInfos = []
        this.drawGridLine(this.element.minY, this.element.maxY, this.element.yGap, this.yAxisDisplayLength, this.xAxisDisplayLength, this.axisThickness, this.gridThickness, true)
        this.drawGridLine(this.element.minX, this.element.maxX, this.element.xGap, this.xAxisDisplayLength, this.yAxisDisplayLength, this.axisThickness, this.gridThickness, false)
        if (this.element.xAxisLabel) {
            //const unitLabel:PIXI.Text = new PIXI.Text(this.element.xAxisLabel, this.getTextStyle(this.element.axisLabelFontSize))
            const unitLabel: PIXI.Text = this.getText(this.element.xAxisLabel, this.getTextStyle(this.element.axisLabelFontSize), this.textRes, undefined, this.yAxisDisplayLength+this.bottomLabelHeight)
            unitLabel.resolution = this.textRes
            this.grid.addChild(unitLabel);
            unitLabel.x = this.xAxisDisplayLength/2-unitLabel.width/2+this.leftLabelWidth;
            this.graph.height = unitLabel.y+unitLabel.height
        }
        
        this.rightAlignAxisLabels(this.yAxisInfos)

        this.grid.height = this.yAxisDisplayLength
        this.grid.width = this.xAxisDisplayLength
        this.grid.interactive = true;
        //this.grid.on('pointerup', ($event) => {this.deactivateMouseDown($event)});
        this.render();
    }

    rightAlignAxisLabels(axisInfos:AxisInfo[]) {
        let maxWidth = 0;
        axisInfos.forEach((info)=>{
            if (info.text && info.text.width>maxWidth) {
                maxWidth = info.text.width
            }
        })
        if (maxWidth>0) {
            axisInfos.forEach((info)=>{
                if (info.text) {
                    const offSet = maxWidth - info.text.width
                    info.text.x += offSet-3
                }
            })
        }
    }

    itemSectionWidth = 0;
    itemSectionHeight = 0;

    drawItems(allOptions) {
        let xPosition = 0;
        let yPosition = 0;
        if (this.element.draggableSide==Direction.RIGHT) {
            xPosition += this.grid.width+50
        } else if (this.element.draggableSide==Direction.DOWN) {
            yPosition += this.grid.height+50
        }
        for (let i = 0;i<allOptions.length;i++) {
            const option = allOptions[i];
            const anOption:Option = {origX: xPosition, origY: yPosition, width: option.width ? option.width : 10, height: option.height ? option.height : 10, gap: option.gap ? option.gap : 0}
            if (option.img && option.img.url) {
                // let url = this.getImage(option)
                // anOption.image = PIXI.Sprite.from(url)
                anOption.image = PIXI.Sprite.from(this.spriteLoader.loader.resources[i.toString()].texture)
                this.addGraphic(anOption.image)
                
                anOption.image.height = anOption.height
                anOption.image.width = anOption.width;
                anOption.image.x = xPosition
                anOption.image.y = yPosition
                anOption.image.zIndex = 20
                anOption.image.interactive = true;
                this.registerMouseEvents(anOption)
            }
            if (option.label && option.label) {
                //anOption.label = new PIXI.Text(option.label, this.getTextStyle(option.fontSize));
                anOption.label = this.getText(option.label, this.getTextStyle(option.fontSize), this.textRes, anOption.image.x, anOption.image.y+anOption.image.height)
                this.addGraphic(anOption.label)
                anOption.label.zIndex = 16
            }
            if (this.element.draggableSide == Direction.DOWN || this.element.draggableSide == Direction.UP) {
                xPosition += anOption.image.width+1+anOption.gap;
                if (xPosition > this.itemSectionWidth) {
                    this.itemSectionWidth = xPosition
                }
                if (anOption.image.y+anOption.image.height > this.itemSectionHeight) {
                    this.itemSectionHeight = anOption.image.y+anOption.image.height
                }
            } else if (this.element.draggableSide == Direction.RIGHT || this.element.draggableSide == Direction.LEFT) {
                yPosition += anOption.image.height+1+anOption.gap;;
                if (yPosition > this.itemSectionHeight) {
                    this.itemSectionHeight = yPosition
                }
                if (anOption.image.x+anOption.image.width > this.itemSectionWidth) {
                    this.itemSectionWidth = anOption.image.x+anOption.image.width
                }
            }
            
            anOption.id = option.id
            this.options.push(anOption)
        }
    }

    getTextStyle(fontSize?){
        return new PIXI.TextStyle({
            fontSize: fontSize || 5,
            fill: [this.getColor()] 
        })
    }

    isHCmode(){ return this.textToSpeech.isHiContrast }

    getColor(){ return this.isHCmode() ? 0xFFFFFF : 0x000000 }

    getImage(option?){
        if(option){
            let normalUrl = option.img.url.toString()
            let hiContrastUrl = option.img.isHiContrastSensitive && option.img.hiContrastImg.url ? option.img.hiContrastImg.url.toString() : normalUrl  // Fallback to normal image
            return this.isHCmode() ? hiContrastUrl : normalUrl
        }
        let blackArrowhead = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/arrow_2/1620862487602/arrow_2.png"
        let whiteArrowhead = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/UI_Elements/gridComponent/arrow_white.png"
        return this.isHCmode() ? whiteArrowhead : blackArrowhead
    }
}