import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { ALL_DISTRICT, BcAccountsService, District, DistrictCompletionReport, DistrictDetail, Filter, FilterCondition, PaginatedRows, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { CompletionReportRow, SchoolCompletionReportRow } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { EFSAReportDataMode, FSA_REPORT_DATA_MODES, IFSAReportDataMode } from 'src/app/ui-ministryadmin/ma-progress-reports/types';
import { BcPaginatedTableComponent, IColumnHeading, Tag, TagConfig, TagLayout, TextDisplay, ZebraConfig } from '../bc-paginated-table/bc-paginated-table.component';

interface IGradeOption {
  grade: string;
  caption: string;
}
const GRADE_OPTIONS: IGradeOption[] = [
  { grade: '4', caption: 'sa_sr_grade4' },
  { grade: '7', caption: 'sa_sr_grade7' },
  { grade: '', caption: 'sa_sr_grade4n7' },
]
enum EComponentFilter {
  ALL,
  NOT_STARTED,
  ONGOING,
  SUBMITTED
}
interface IComponentFilterOption {
  id: EComponentFilter;
  caption: string;
  filters: Partial<Filter>[]
}
const COMPONENT_FILTER_OPTIONS: IComponentFilterOption[] = [
  { id: EComponentFilter.ALL, caption: 'All', filters: [] },
  {
    id: EComponentFilter.NOT_STARTED, caption: 'Not Started', filters: [{
      condition: FilterCondition.MATCH,
      value: 0,
    }]
  },
  {
    id: EComponentFilter.ONGOING, caption: 'Ongoing', filters: [{
      condition: FilterCondition.DONT_MATCH,
      value: 0,
    }, {
      condition: FilterCondition.DONT_MATCH_FIELD,
      value: 'total',
    }]
  },
  {
    id: EComponentFilter.SUBMITTED, caption: 'Submitted', filters: [{
      condition: FilterCondition.MATCH_FIELD,
      value: 'total',
    }]
  }
];

@Component({
  selector: 'roll-up-report',
  templateUrl: './roll-up-report.component.html',
  styleUrls: ['./roll-up-report.component.scss']
})
export class RollUpReportComponent implements OnInit, OnChanges {

  @ViewChildren(BcPaginatedTableComponent) tables: BcPaginatedTableComponent<CompletionReportRow>[];


  @Input() testWindow: TestWindow;
  @Input() accountType: AccountType;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;

  @Input() titleSlug: string = 'sc_scr_title';
  @Input() descriptionSlug: string = 'sa_sr_desc_full';
  @Input() excludeIndependentSchools: boolean = false;
  @Input() exportFilename: string = 'School-Completion-Report';

  selectAll: boolean;
  districts: District[];
  selectedDistrict: District;
  gradeOptions: IGradeOption[] = GRADE_OPTIONS;
  selectedGradeOption: IGradeOption;
  districtOverview: CompletionReportRow[];
  districtCompletion: DistrictCompletionReport | null;
  dataModes: IFSAReportDataMode[] = FSA_REPORT_DATA_MODES;
  selectedDataMode: IFSAReportDataMode;

  schoolTablePagination: Pagination;
  schoolTableIdentifier: string = 'school-table';
  columnHeadings: IColumnHeading[] = [];
  tableColumnWidths: number[] = [250, 180, 180, 100, 180, 250, 250, 250, 250];
  componentSortBys: string[] = ['sr_literacy', 'sr_numeracy', 'cr_literacy', 'cr_numeracy']
  zebraConfig: ZebraConfig = {
    field: 'school_group_id',
  }
  tagConfig: TagConfig = {
    layout: TagLayout.HORIZONTAL,
  }
  FilterCondition = FilterCondition;


  districtTableIdentifier: string = 'district-table';
  districtColumnHeadings: IColumnHeading[] = [
    { heading: 'Grade', sortBy: 'grade', sortDisabled: true, filterDisabled: true },
    { heading: 'Enrollment', sortBy: 'enrollment', sortDisabled: true, filterDisabled: true },
    { heading: this.lang.tra('sa_sr_literacy_lvl2'), subheading: this.lang.tra('sa_sr_literacy_lvl2_sub'), sortBy: 'sr_literacy', sortDisabled: true, filterDisabled: true },
    { heading: this.lang.tra('sa_sr_numeracy_lvl2'), subheading: this.lang.tra('sa_sr_numeracy_lvl2_sub'), sortBy: 'sr_numeracy', sortDisabled: true, filterDisabled: true },
    { heading: this.lang.tra('sa_cr_literacy_lvl2'), subheading: this.lang.tra('sa_cr_literacy_lvl2_sub'), sortBy: 'cr_literacy', sortDisabled: true, filterDisabled: true },
    { heading: this.lang.tra('sa_cr_numeracy_lvl2'), subheading: this.lang.tra('sa_cr_numeracy_lvl2_sub'), sortBy: 'cr_numeracy', sortDisabled: true, filterDisabled: true },
  ];
  districtTableColumnWidths: number[] = [150, 150, 250, 250, 250, 250];

  isLoading: boolean = false;

  g4_not_started: Set<number>;
  g7_not_started: Set<number>;

  constructor(
    private bcAccounts: BcAccountsService,
    private lang: LangService,
    private bcReports: BcReportsService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    this.selectAll = false;
    this.districts = [ALL_DISTRICT];
    this.selectedDistrict = ALL_DISTRICT;

    this.districtCompletion = null;
    this.selectedDataMode = FSA_REPORT_DATA_MODES.filter(mode => mode.id === EFSAReportDataMode.ADMIN_SESSION)[0];
  }

  ngOnInit(): void {
    let sortable = this.isDistrictAdmin();
    this.columnHeadings = [
      { heading: 'School Name', sortBy: 'school_name', isInfo: true, sortDisabled: !sortable, filterDisabled: !sortable },
      { heading: 'School Code', sortBy: 'school_foreign_id', isInfo: true, sortDisabled: !sortable, filterDisabled: !sortable },
      { heading: 'School Type', sortBy: 'school_type', isInfo: true, sortDisabled: !sortable, filterDisabled: !sortable },
      { heading: 'Grade', sortBy: 'grade', sortDisabled: true, filterDisabled: true },
      { heading: 'Enrollment', sortBy: 'enrollment', sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_sr_literacy_lvl2'), subheading: this.lang.tra('sa_sr_literacy_lvl2_sub'), sortBy: 'sr_literacy', sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_sr_numeracy_lvl2'), subheading: this.lang.tra('sa_sr_numeracy_lvl2_sub'), sortBy: 'sr_numeracy', sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_cr_literacy_lvl2'), subheading: this.lang.tra('sa_cr_literacy_lvl2_sub'), sortBy: 'cr_literacy', sortDisabled: true, filterDisabled: true },
      { heading: this.lang.tra('sa_cr_numeracy_lvl2'), subheading: this.lang.tra('sa_cr_numeracy_lvl2_sub'), sortBy: 'cr_numeracy', sortDisabled: true, filterDisabled: true },
    ]

    this.schoolTablePagination = this.bcAccounts.getInitialPagination();

    const params = this.route.snapshot.queryParams;
    const districtId = params.district;

    const postAction = () => {
      this.selectedGradeOption = GRADE_OPTIONS.filter(option => option.grade === '')[0]
    }

    postAction();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schoolDetail || changes.testWindow || changes.districtDetail) {
      if (!this.tables) return;

      this.tables.forEach(table => {
        if (table) table.resetPage();
      });

      this.updateTables();
    }
  }

  private updateTables() {
    if (!this.tables) return;
    this.tables.forEach(table => {
      if (table && table.identifier === this.schoolTableIdentifier) table.updateTable();
    });
  }

  export() {
    let grades = this.getGradeArray();
    this.bcReports.exportRollUpReport(this.exportFilename, {
      district_group_id: this.districtDetail.groupId,
      district_name: this.districtDetail.name,
      test_window_id: this.testWindow.id,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      no_independent: this.excludeIndependentSchools ? true : undefined,
      grades: JSON.stringify(grades),
      pagination: this.schoolTablePagination,
    })
  }

  onSelectedDistrictChange(e) {
    if (!this.tables) return;

    this.tables.forEach(table => {
      if (table) table.resetPage();
    });

    this.updateTables();
  }

  onSelectedGradeChange(e) {
    if (!this.tables) return;

    this.tables.forEach(table => {
      if (table) table.resetPage();
    });
    
    this.updateTables();
  }

  getDisplayDistrict(district: District): string {
    return this.bcAccounts.getDistrictDisplay(district);
  }

  getBackRoute() {
    if (this.isSchoolAdmin()) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/session_reports`;
    } else if (this.isDistrictAdmin()) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/bc-fsa/session_reports`;
    } else {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-fsa/session_reports`;
    }
  }

  formatSchoolCode(code: number): string {
    return this.bcAccounts.formatSchoolCode(code);
  }

  public districtDisplayFn = (district: District) => {
    let display = '';
    if (district) {
      display = this.getDisplayDistrict(district)
    }
    return display;
  }

  public getFilterDistrictsFn() {
    const self = this;
    return (value: string | District): District[] => {
      let filtered: District[] = [];
      if (self.districts) {
        let filterValue: string;
        if ((value as District).name) {
          filterValue = (value as District).name.toLowerCase()
        } else {
          filterValue = (value as string).toLowerCase();
        }
        filtered = self.districts.filter(district => district.name.toLowerCase().includes(filterValue) || self.formatDistrict(district.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  formatDistrict(district: number): string {
    return this.bcAccounts.formatDistrictCode(district);
  }

  public districtSelected = (event) => {
    const district: District = event.option.value;
    this.selectedDistrict = district;
    this.onSelectedDistrictChange(event);
  }

  getDivisionPercentageDisplay(numerator: number, denominator: number): string {
    if (!denominator || denominator === 0) return '0';
    const quotient = numerator * 100 / denominator;
    return quotient.toFixed(2);
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  notStartedAtAll(row: SchoolCompletionReportRow): boolean {
    return !row.sr_literacy && !row.sr_numeracy && !row.cr_literacy && !row.cr_numeracy;
  }

  isRowFrench(row: SchoolCompletionReportRow): boolean {
    return row.lang == 'FR';
  }

  getRows = (pagination: Pagination): Promise<PaginatedRows<SchoolCompletionReportRow>> => {
    if (!this.selectedGradeOption || !this.districtDetail || !this.testWindow || this.schoolDetail === undefined) return Promise.resolve({
      count: 0,
      data: [],
    });

    let grades = this.getGradeArray();

    return this.bcReports.getRollUpReport({
      district_group_id: this.districtDetail.groupId,
      test_window_id: this.testWindow.id,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      grades: grades,
      no_independent: this.excludeIndependentSchools ? true : undefined,
    }, pagination).then(({ schools, district }) => {

      this.districtOverview = [
        district['4'], district['7']
      ];

      this.g4_not_started = new Set();
      this.g7_not_started = new Set();
      for (let s of schools.data) {
        if (this.notStartedAtAll(s)) {
          if (s.grade == 4) {
            this.g4_not_started.add(s.school_foreign_id);
          } else {
            this.g7_not_started.add(s.school_foreign_id);
          }
        }
      }

      return schools;
    })


  }

  getDisplay = (by: string, row: SchoolCompletionReportRow): string | TextDisplay => {
    if (by === 'school_foreign_id') {
      return this.formatSchoolCode(row.school_foreign_id);
    }

    if (by === 'grade') {
      return `${this.lang.tra('sa_grade')} ${row.grade}`;
    }

    if (this.componentSortBys.includes(by)) {
      return `${row[by]} (${this.getDivisionPercentageDisplay(row[by], row.enrollment)}%)`
    }

    return row[by];
  }

  getTag = (by: string, row: SchoolCompletionReportRow): Tag | Tag[] => {
    if (by === 'school_name') {
      let tags: Tag[] = [];

      if (row._isInfo && this.isRowFrench(row)) {
        tags.push({
          text: 'FR',
          color: '#ffa726',
        });
      }

      if (row._isInfo && this.g4_not_started.has(row.school_foreign_id) && this.g7_not_started.has(row.school_foreign_id)) {
        tags.push({
          text: '*',
          color: 'rgba(255, 255, 255, 0)',
          textColor: '#ff0000',
          style: {
            'font-size': '24px',
            'transform': 'scale(1.5) translate(-8px, 7px)'
          }
        });
      }

      if (tags.length == 0) {
        return null;
      } else {
        return tags;
      }
    }
    return null;
  }

  schoolsUpdated = (e) => {
    if (this.tables) {
      this.tables.forEach(table => {
        // update district table
        if (table.identifier === this.districtTableIdentifier) {
          table.updateTable();
        }
      })
    }
  }

  districtGetRows = (pagination: Pagination): Promise<PaginatedRows<CompletionReportRow>> => {
    if (!this.selectedGradeOption || !this.districtDetail || !this.testWindow || this.schoolDetail === undefined || !this.districtOverview) return Promise.resolve({
      count: 0,
      data: [],
    });

    if (+this.selectedGradeOption.grade == 4) {
      return Promise.resolve({
        count: 1,
        data: [this.districtOverview[0]],
      });
    }

    else if (+this.selectedGradeOption.grade == 7) {
      return Promise.resolve({
        count: 1,
        data: [this.districtOverview[1]],
      });
    }

    return Promise.resolve({
      count: 2,
      data: this.districtOverview,
    });

  }

  districtGetDisplay = (by: string, row: CompletionReportRow): string | TextDisplay => {
    if (by === 'grade') {
      return `${this.lang.tra('sa_grade')} ${row.grade}`;
    }

    if (this.componentSortBys.includes(by)) {
      return `${row[by]} (${this.getDivisionPercentageDisplay(row[by], row.enrollment)}%)`
    }

    return row[by];
  }

  getGradeArray() {
    const grades: (4 | 7)[] = [];
    switch (this.selectedGradeOption.grade) {
      case '4':
        grades.push(4);
        break;
      case '7':
        grades.push(7);
        break;
      default:
        grades.push(4);
        grades.push(7);
    }
    return grades;
  }
}
