export enum MaFinalResultsViews {
    PROFICIENCY_STUDENT_SCORE = 'proficiency',
    INDIVIDUAL_STUDENT_INFO = 'student',
    ITEM_ANALYSIS_DISTRACTOR = 'item_analysis',
    ASSESSMENT_KEY = 'assessment_key',
    RESULTS_REPORT = 'results_report',
}

export const MA_FINAL_RESULTS_VIEWS = [
    {
        id: MaFinalResultsViews.PROFICIENCY_STUDENT_SCORE,
        caption: 'Proficiency Distributions & Student Score Information',
        disabled: false,
    }, {
        id: MaFinalResultsViews.INDIVIDUAL_STUDENT_INFO,
        caption: 'Individual Student Reports',
        disabled: false,
    }, {
        id: MaFinalResultsViews.ITEM_ANALYSIS_DISTRACTOR,
        caption: 'Item Analysis & Distractor Reports',
        disabled: true,
    }, {
        id: MaFinalResultsViews.ASSESSMENT_KEY,
        caption: 'Assessment Keys',
        disabled: false,
    },
]