import { AfterViewInit, Component, Input, OnChanges, OnInit, Renderer2, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { CustomInteractionType, ElementType, IContentElementPixiDragDrop, IContentElementCustomInteraction, IContentElementScaleRadius, IContentElementSlider, IContentElementRotateSprite, IContentElementCartesianPlane, IContentElementPieChart, IContentElementGridFill, IContentElementGridScatterPlot, IContentElementGridPaint } from "../models";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import * as PIXI from "pixi.js-legacy";
import { LangService } from "../../core/lang.service";
import { ZoomService } from "../zoom.service";
import { updateChangeCounter } from "../../ui-item-maker/services/data-bind";
import { TextToSpeechService } from "../text-to-speech.service";

// Controllers
import { CustomInteractionCtrl } from "./controllers/custom-interaction";
import { RotateSpriteCtrl } from "./controllers/rotate-sprite";
import { SliderCtrl } from "./controllers/slider";
import { ScaleRadiusCtrl } from "./controllers/scale-radius";
import { RenderGridDND } from "./controllers/grid-dnd";
import { GridFill } from "./controllers/grid-fill";
import { GridScatterPlot } from "./controllers/grid-scatter-plot";
import { PieChartCtrl } from "./controllers/pie-chart";
import { DragDropCtrl } from "./controllers/drag-drop";
import { GridPaint } from "./controllers/grid-paint";

@Component({
  selector: "element-render-custom-interaction",
  templateUrl: "./element-render-custom-interaction.component.html",
  styleUrls: ["./element-render-custom-interaction.component.scss"]
})
export class ElementRenderCustomInteractionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() element: IContentElementCustomInteraction;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter: number;
  hcPrevState: boolean;

  @ViewChild("pixiDiv") pixiDiv;

  app: PIXI.Application;
  // controller: CustomInteractionCtrl

  CustomInteractionType = CustomInteractionType;
  constructor(private renderer2: Renderer2, private lang: LangService, private zoom: ZoomService, private textToSpeech: TextToSpeechService) {
    this.zoom.zoomSub.subscribe(val => {
      if (this.element) {
        this.refresh();
      }
    });

    this.zoom.screenShrinkSub.subscribe(val => {
      if(this.element) {
        this.refresh();
      } 
    })

    this.hcPrevState = this.textToSpeech.isHiContrast
    this.textToSpeech.isHighContrastSub.subscribe(val => {
      if(this.hcPrevState !== val){
        this.refresh();
        this.hcPrevState = val
      }
    })
  }

  renderer;
  stage: PIXI.Container;

  ctrl: CustomInteractionCtrl;

  ngOnInit(): void {
    this.app = new PIXI.Application({ autoStart: false});
    // #Below code is experimental : deal with the sluggish performance after firing so many events
    // It has something to do with Ticker and System ticker in PIXI
    // this.app.ticker.autoStart =false
    // this.app.ticker.stop()
    this.app.renderer.plugins.interaction.useSystemTicker = false
  }

  intervals = []
  ngOnDestroy() {
    //Removes all event listeners defined in the viewchild
    if(this.ctrl) this.ctrl.removeAccessibilityKeyListeners();
    this.intervals.forEach((interval)=>{
      clearInterval(interval)
    })
  }

  handleNewState() {
    if (this.ctrl){
      this.ctrl.loadAssets().then(() => this.ctrl.handleNewState());
    } 
  }

  ensureState() {
    if (!this.questionState[this.element.entryId]) {
      this.updateState();
    }
  }

  updateState() {
    if (this.ctrl) this.ctrl.updateState();
  }

  ngAfterViewInit() {
    this.renderer2.appendChild(this.pixiDiv.nativeElement, this.renderer.view);
  }

  refresh() {
    // console.log("refreshing")
    if (this.element.canvasWidth == null) {
      this.element.canvasWidth = 100;
    }

    if (this.element.canvasHeight == null) {
      this.element.canvasHeight = 100;
    }
    const zoom = this.zoom.getScreenShrinkZoom() == null ? 1 : this.zoom.getScreenShrinkZoom();
    const canvasWidth = zoom * this.element.canvasWidth;
    const canvasHeight = zoom * this.element.canvasHeight;
    // console.log(this.element.canvasWidth, this.element.canvasHeight)
    if (!this.renderer) {
      this.renderer = PIXI.autoDetectRenderer({ width: canvasWidth, height: canvasHeight, transparent: true, antialias: true, clearBeforeRender: true });
    } else {
      this.renderer.resize(canvasWidth, canvasHeight);
    }

    this.stage = new PIXI.Container();
    this.stage.scale.set(zoom);

    const render = () => {
      this.stage.sortableChildren = true;
      this.stage.sortChildren();
      this.renderer.render(this.stage);
    };

    const addGraphic = (gr: PIXI.DisplayObject) => {
      this.stage.addChild(gr);
    };
    const removeGraphic = (gr: PIXI.DisplayObject)=>{
      this.stage.removeChild(gr)
    }

    switch (this.element.interactionType) {
      case CustomInteractionType.SCALE_RADIUS:
        this.ctrl = new ScaleRadiusCtrl(<IContentElementScaleRadius>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, this.textToSpeech);
        break;
      case CustomInteractionType.SLIDER:
        this.ctrl = new SliderCtrl(<IContentElementSlider>this.element, this.questionState, this.questionPubSub, addGraphic, render, this.lang, zoom, this.isLocked, this.textToSpeech);
        break;
      case CustomInteractionType.ROTATE_SPRITE:
        this.ctrl = new RotateSpriteCtrl(<IContentElementRotateSprite>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, this.textToSpeech);
        break;
      case CustomInteractionType.GRID_DND:
        // console.log(this.element)
        this.ctrl = new RenderGridDND(<IContentElementCartesianPlane>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, removeGraphic, this.textToSpeech);
        break;
      case CustomInteractionType.PIE_CHART:
        this.ctrl = new PieChartCtrl(<IContentElementPieChart>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, this.textToSpeech)
        break;
      case CustomInteractionType.GRID_FILL:
        this.ctrl = new GridFill(<IContentElementGridFill>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, removeGraphic, this.textToSpeech);
        break;
      case CustomInteractionType.GRID_SCATTER_PLOT:
        this.ctrl = new GridScatterPlot(<IContentElementGridScatterPlot>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, removeGraphic, this.textToSpeech);
        break;   
      // case CustomInteractionType.DRAG_DROP:
      //   this.ctrl = new DragDropCtrl(<IContentElementPixiDragDrop>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, removeGraphic, this.textToSpeech);
      //   break; 
      case CustomInteractionType.GRID_PAINT:
        this.ctrl = new GridPaint(<IContentElementGridPaint>this.element, this.questionState, this.questionPubSub, addGraphic, render, zoom, this.isLocked, removeGraphic, this.textToSpeech);
        break;   
    }

    this.ensureState();
    this.handleNewState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.changeCounter && changes.changeCounter.currentValue !== undefined && !changes.changeCounter.firstChange) {
      this.questionState[this.element.entryId] = undefined; //(when we are not essentially performing an ngOnInit)
    }

    this.refresh();
  }
}
