import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../api/auth.service';
import { AccountType } from '../../constants/account-types';
import { IContentElementSolution, QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { UrlLoaderService } from '../url-loader.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { SiteContexts } from 'src/app/api/constants/site-contexts';


@Component({
  selector: 'element-render-solution',
  templateUrl: './element-render-solution.component.html',
  styleUrls: ['./element-render-solution.component.scss']
})
export class ElementRenderSolutionComponent implements OnInit {

  @Input() element:IContentElementSolution;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(private auth: AuthService,
        private urlLoader: UrlLoaderService,
        private loginGuard: LoginGuardService,
        private text2Speech: TextToSpeechService
    ) { }

  ngOnInit() {
    
  }

  isVisible() {
    if (!this.isLocked){ return false; }
    if (this.element.hideFromMarkers){
      try {
        if(this.loginGuard.checkSiteContext(SiteContexts.MARKING)){
          return false;
        }
      }
      catch (e){
        // continue
      }
    }
    if (this.element.hideFromTestTakers){
      //TODO: make invisible to test-takers (including preview as test-taker). for purposes of demo/review leave as is, but we should also hide when previewing as test-taker
      try {
        if (this.auth.user().value.accountType === AccountType.TEST_TAKER){
          return false
        } 
      }
      catch (e){
        return false;
      }
    }
    return this.isLocked;
  }

  getTextStyle(){
    let style = {}
    if (this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    return style;
  }

  sanitizeUrl(url:string){
    return this.urlLoader.sanitize(url);
  }

  invert() {
    if (this.text2Speech.isHiContrast && this.element.isRubric) {
      return true;
    }
    return false;
  }
}
