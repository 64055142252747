import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ElementType, IContentElementResultsPrint, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'element-render-results-print',
  templateUrl: './element-render-results-print.component.html',
  styleUrls: ['./element-render-results-print.component.scss']
})


export class ElementRenderResultsPrintComponent implements OnInit, OnChanges {

  @Input() element:IContentElementResultsPrint;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter:number;

  imageUrls = new Map();

  // response: string;
  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
  isVisible() {
    return this.questionPubSub && this.questionPubSub.isPrintMode;
  }

  isResponseUpload(){
    const responseEntry = this.getResponseEntry();
    if(responseEntry) {
      return responseEntry.type === ElementType.UPLOAD;
    }
  }

  getResponseUrl() {
    const responseEntry = this.getResponseEntry();
    if(responseEntry) {
      return this.getSanitizedUrl(responseEntry.url);
    }
  }

  getSanitizedUrl(url:string){
    return memo(this.imageUrls, url, url => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  getResponseEntry(){
    if(this.questionState && this.element.inputEntryId && this.questionState[this.element.inputEntryId]) {
      return this.questionState[this.element.inputEntryId];
    }
  }

  getResponseText() {
    const responseEntry = this.getResponseEntry();
    if(responseEntry) {
      return responseEntry.str;
    }
  }
}
