<div class="sa-accounts-students-enroll">
    <div class="view-header">
        <div>
            <tra *ngIf="isFSA" slug="sa_sa_add_or_reenroll"></tra>
            <tra *ngIf="!isFSA" slug="sa_grad_enroll_page_header"></tra>
        </div>
        <a (click)="backToOptions()" *ngIf="isBackButtonVisisble">
            <tra *ngIf="!isFSA" slug="sa_grad_back_to_options"></tra>
            <tra *ngIf="isFSA" slug="sa_sa_back_to_options"></tra>
        </a>
    </div>

    <div *ngIf="!isFSA && isSubHeaderVisible">
        <tra-md slug="sa_grad_enroll_materials"></tra-md>
    </div>

    <!-- <div *ngIf="!isFSA" style="margin-top: 1em; margin-bottom: 2em;">
        <ul>
            <li><a><tra slug="sa_grad_view_num_guide_a"></tra></a></li>
            <li><a><tra slug="sa_grad_view_lit_guide_c"></tra></a></li>
        </ul>
    </div> -->
    <!-- <button hidden id="custom-control" (click)="customControl()"></button> -->

    <div *ngIf="isFSA && shouldShowAlertMessage" class="alert-message">
        <i class="fa fa-info-circle"></i>
        <tra-md [slug]="alertMessageSlug" [props]="alertMessageProps"></tra-md>
    </div>

    <!-- <div *ngIf="!isFSA && shouldShowMovingStudentMessage" class="alert-message move-student-message">
        <i class="fa fa-info-circle"></i>
        <tra-md slug="sa_grad_enroll_move_student" [props]="moveStudentMessageProps"></tra-md>
    </div> -->

    <div *ngIf="!isFSA && shouldShowInvalidPenMessage" class="alert-message invalid-pen-message">
        <i class="fa fa-info-circle"></i>
        <tra-md slug="sa_one_or_more_pen_invalid"></tra-md>
    </div>

    <div class="table-section" *ngIf="isFSA || assessments">
        <table [class.grad-enroll-table]="!isFSA"
            [class.with-confirmation]="isFSA && (mode == Mode.CONFIRMATION || hasFailedRows) && !isLoading">
            <tr>
                <th></th>
                <th>
                    <tra slug="sa_pen"></tra>
                </th>
                <th>
                    <tra slug="sa_llname"></tra>
                </th>
                <th>
                    <tra slug="sa_lfname"></tra>
                </th>
                <ng-container *ngIf="isFSA">
                    <th>
                        <tra slug="sa_grade"></tra>
                    </th>
                </ng-container>
                <ng-container *ngIf="!isFSA">
                    <th class="assessment-heading" [class.long-heading]="assessment.longName"
                        *ngFor="let assessment of assessments">
                        <div>
                            <div>{{assessment.name}}</div>
                            <!-- <button class="blue" (click)="selectAll(assessment)"> -->
                            <!-- </button> -->
                            <div class="row-direction">
                                <div class="select-all"><input type="checkbox" [(ngModel)]="assessment.selectAll"
                                    [disabled]="assessment.disabled" (change)="onSelectAllToggle(assessment)" /></div>
                                <tra *ngIf="!assessment.selectAll" slug="lbl_select_all"></tra>
                                <tra *ngIf="assessment.selectAll" slug="lbl_unselect_all"></tra>
                            </div>
                        </div>
                    </th>
                </ng-container>
                <th></th>
                <th *ngIf="(mode == Mode.CONFIRMATION || hasFailedRows) && !isLoading">
                    <tra slug="sa_enroll_confirmation"></tra>
                </th>
            </tr>
            <tr *ngFor="let row of rows, index as r">
                <td>{{r+1}}</td>
                <td>
                    <input 
                        pattern="[0-9]{9}" 
                        type="text" 
                        [(ngModel)]="row.pen"
                        (change)="onPenChange(row)"
                        class="input is-small" 
                        style="min-width: 9em;"
                    >
                    <div class="error-message" *ngIf="row.errors.pen">{{row.errors.pen}}</div>
                </td>
                <td>
                    <input class="input is-small" type="text" [(ngModel)]="row.last_name" (change)="onRowInput(row)">
                </td>
                <td>
                    <input class="input is-small" type="text" [(ngModel)]="row.first_name" (change)="onRowInput(row)">
                </td>
                <ng-container *ngIf="isFSA">
                    <td>
                        <mat-radio-group [(ngModel)]="row.grade" (change)="onRowInput(row)">
                            <mat-radio-button *ngFor="let grade of grades" [value]="grade" color="primary">
                                <tra-md [isCondensed]="true" [slug]="grade.caption"></tra-md>
                            </mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>
                <ng-container *ngIf="!isFSA">
                    <td *ngFor="let assessment of row.assessments">
                        <div class="assessment-checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="row.selection[assessment.code]"
                                    [disabled]="assessment.disabled"
                                    (change)="onAssessmentSelectionChange(row, assessment.code)"
                                >
                                <span> {{assessment.code}} </span>
                            </label>
                        </div>

                    </td>
                    <!-- <div *ngIf="row.assessments && row.selection" class="assessment-selection">
                        <div class="assessment-option" *ngFor="let assessment of row.assessments">
                            <input type="checkbox" [(ngModel)]="row.selection[assessment.code]"
                                [disabled]="assessment.disabled"
                                (change)="onAssessmentSelectionChange(row, assessment.code)">
                            <div>{{formatAssessment(assessment)}}</div>
                        </div>
                    </div> -->
                </ng-container>

                <td>
                    <button class="cancel-button clear-row" (click)="clearRow(row)">
                        <tra slug="sa_aa_clear_record"></tra>
                    </button>
                </td>

                <td class="confirmation-cell" *ngIf="(mode == Mode.CONFIRMATION || hasFailedRows) && !isLoading">
                    <ng-container *ngIf="row.successMessages.length > 0">
                        <div class="success-confirmation" *ngFor="let message of row.successMessages">
                            <i class="fa fa-check"></i>
                            <div>{{message}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="row.failureMessages.length > 0">
                        <div class="error-message" *ngFor="let message of row.failureMessages">
                            {{message}}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="row.cleared">
                        <tra slug="sa_enroll_row_cleared"></tra>
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>
    <div class="more-rows-section">
        <button class="more-rows-button" (click)="moreRowsClicked()" [disabled]="isLoading">
            <tra slug="sa_sa_create10rows"></tra>
        </button>
    </div>
    <div class="action-section">
        <ng-container *ngIf="mode == Mode.EDIT">
            <button class="save-button" (click)="saveClicked()" [disabled]="isLoading">
                <tra slug="sa_aa_submit"></tra>
            </button>
        </ng-container>
        <ng-container *ngIf="mode == Mode.CONFIRMATION">
            <ng-container *ngIf="hasFailedRows">
                <button class="fix-button" (click)="fixClicked()" [disabled]="isLoading">
                    <tra slug="sa_aa_fix"></tra>
                </button>
            </ng-container>
            <ng-container *ngIf="!hasFailedRows">
                <button class="ok-button" (click)="okClicked()" [disabled]="isLoading">
                    <tra slug="sa_aa_save"></tra>
                </button>
            </ng-container>
        </ng-container>

        <button 
            class="cancel-button" 
            *ngIf="isCancelButtonVisible"
            (click)="cancelClicked()" 
            [disabled]="isLoading"
        >
            <tra slug="sa_aa_cancel"></tra>
        </button>
    </div>
</div>

<div class="custom-modal" *ngIf="showProcessingPensModal">
    <div class="modal-contents" style="width: 500px;">
        <tra-md slug="sa_processed_pens" [props]="{numProcessed: numProcessed, totalToProcess: totalToProcess}"></tra-md>

        <progress class="progress" [value]="numProcessed" [max]="totalToProcess"></progress>
    </div>
</div>

<div class="custom-modal" *ngIf="ShowStudentsAddedModal">
    <div class="modal-contents content">
        <i class="far fa-check-circle icon"></i>
        <span class="content-block special-block">
            <tra *ngIf="isFSA" slug="sa_s_en_added_student_modal_p1"></tra>
            <tra *ngIf="!isFSA" slug="sa_s_grad_added_student_modal_p1"></tra>
        </span>
        <br>
        <span class="content-block special-block">
            <tra *ngIf="isFSA" slug="sa_s_en_added_student_modal_p2"></tra>
            <!-- <tra *ngIf="!isFSA" slug="sa_s_grad_added_student_modal_p2"></tra> -->

        </span>
        <br>
        <div class="content-block">
            <div class="btn add" (click)="addMoreStudentsFromAdded()" style="margin-right: 2em;">
                <tra slug="sa_s_en_addedm_add_students"></tra>
            </div>
            <div class="btn red" (click)="backToStudents()">
                <tra *ngIf="isFSA" slug="sa_s_en_addedm_return_to_students"></tra>
                <tra *ngIf="!isFSA" slug="sa_s_grad_addedm_return_to_students"></tra>
            </div>
        </div>
    </div>
</div>

<div class="custom-modal" *ngIf="ShowNoMoreRowsModal">
    <div class="modal-contents content">
        <i class="fas fa-exclamation-circle exclaim-icon"></i>
        <span class="content-block exclaim-block">
            <tra slug="sa_s_en_noRows_modal"></tra>
        </span>
        <br>
        <div class="content-block">
            <div class="warn-btn add" (click)="backToStudents()">
                <tra slug="sa_s_en_noRows_yes"></tra>
            </div>
            <div class="warn-btn no" (click)="addMoreStudentsFromNoRow()">
                <tra slug="sa_s_en_noRows_no"></tra>
            </div>
        </div>
    </div>
</div>

<div class="custom-modal conflict-modal" *ngIf="shouldOpenNMF_NME_ConflictModal">
    <div class="modal-contents" style="min-width: 600px; max-width: 800px;">
        <div class="modal-header">
            <i class="fa fa-info-circle"></i>
            <div>{{nmf_nme_conflict_component.name}} ({{nmf_nme_conflict_component.code}}) - <tra slug="lbl_select_all">
                </tra>
            </div>
        </div>

        <div class="conflict-desc">
            <tra-md slug="sa_enroll_grad_nme_nmf_conflict" [props]="nme_nmf_conflict_props"></tra-md>
        </div>

        <div class="actions">
            <button class="blue" (click)="confirmSelectAll(nmf_nme_conflict_component)">
                <tra slug="lbl_confirm"></tra>
            </button>
            <button class="cancel-button" (click)="closeNMF_NME_ConflictModal()">
                <tra slug="sa_aa_cancel"></tra>
            </button>
        </div>
    </div>
</div>