import { Injectable } from '@angular/core';
import { UserRoles } from '../api/models/roles';
import { WhitelabelService } from '../domain/whitelabel.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRolesService {

  constructor(private whitelabel: WhitelabelService) { }

  authRoleTypes = [ //Order matters for determining which overall authoring role types users have.
    {slug: UserRoles.TEST_ITEM_AUTHOR_SUPER, caption: 'Supervisor',},
    {slug: UserRoles.TEST_ITEM_AUTHOR_REV,           caption: 'Read-only',},
    {slug: UserRoles.TEST_ITEM_AUTHOR,       caption: 'Editor',}
  ]

  bcAuthRoleTypes = [{slug: UserRoles.BC_AUTH_COORD, caption: 'Development Coordinator/Director/Manager'},
    {slug: UserRoles.BC_AUTH_CHAIR,          caption: 'Development Chair/Developer'},
    {slug: UserRoles.BC_AUTH_MINISTRY_REV,   caption: 'Executive and Internal Ministry Reviewer'},
    {slug: UserRoles.BC_AUTH_PSYCHO,         caption: 'Psychometrician' },
    {slug: UserRoles.BC_AUTH_TRANS_COORD,    caption: 'Translation and Communications Coordinator'},
    {slug: UserRoles.BC_AUTH_SOC_SPEC,       caption: 'Social Considerations Specialist'},
    {slug: UserRoles.BC_AUTH_EXT_REV,        caption: 'External Reviewer'},
    {slug: UserRoles.BC_AUTH_TRIAL_WRITER,   caption: 'Trial Writer'},
    {slug: UserRoles.BC_AUTH_TRANS,          caption: 'Translator'},
    {slug: UserRoles.BC_AUTH_LING_REV,       caption: 'Linguistic Reviewer'},
    {slug: UserRoles.BC_AUTH_ACC_SPEC,       caption: 'Accessibility Specialist'},
    {slug: UserRoles.BC_AUTH_STANDARD_SPEC,  caption: 'Standard Setting Specialist'}];

  bcSuperRoles = [UserRoles.BC_AUTH_COORD];

  superRoles:UserRoles[] = [UserRoles.TEST_ITEM_AUTHOR_SUPER, UserRoles.BC_AUTH_COORD];
  readOnlyRoles:UserRoles[] = [UserRoles.TEST_ITEM_AUTHOR_REV, 
    UserRoles.BC_AUTH_BASE, 
    UserRoles.BC_AUTH_MINISTRY_REV, 
    UserRoles.BC_AUTH_PSYCHO, 
    UserRoles.BC_AUTH_SOC_SPEC, 
    UserRoles.BC_AUTH_EXT_REV, 
    UserRoles.BC_AUTH_TRIAL_WRITER, 
    UserRoles.BC_AUTH_ACC_SPEC, 
    UserRoles.BC_AUTH_STANDARD_SPEC];
    
  currentRoles = []

  determineRoleFromRoleTypes(role_types: UserRoles[]){
    for(const role of this.bcAuthRoleTypes.map(r => r.slug)) {
      if(role_types.includes(role)) {
        return role;
      }
    }

    for(const role of this.authRoleTypes.map(r => r.slug)) {
      if(role_types.includes(role)) {
        return role;
      }
    }

    return undefined;
  }

  getAuthRoles() {
      if(this.whitelabel.getSiteFlag('IS_BCED')){
        return this.authRoleTypes.concat(this.bcAuthRoleTypes);
      } else {
        return this.authRoleTypes;
      }
  }

  getCaptionMap() {
    const authRoles = this.getAuthRoles();
    const map = {};
    for(const role of authRoles) {
      map[role.slug] = role.caption;
    }
    return map;
  }

  hasRole(role:UserRoles) {
    let hasRole = false;
    console.log(role)
    if (!role) return false;
    this.currentRoles.forEach((aRole)=>{
      console.log(aRole["role_type"])
      if (role==aRole["role_type"]) {
        hasRole = true
      }
    })
    return hasRole
  }
}
