import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { BcAssessmentsService } from '../../bc-assessments/bc-assessments.service';
import { LangService } from '../../core/lang.service';
import { SECURE_LOGIN_SYS_FLAG } from '../../ui-whitelabel-bc-landing/view-secure-login/model';

interface IDocument {
  title: string,
  link: string,
  isDisabled?:boolean,
}

type IDocumentGroup = IDocument[];

@Component({
  selector: 'score-entry-documents',
  templateUrl: './score-entry-documents.component.html',
  styleUrls: ['./score-entry-documents.component.scss']
})
export class ScoreEntryDocumentsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() grade: number;
  @Input() large: boolean = false;

  documentGroups: IDocumentGroup[];

  private asmtAvailabilitySubcription: Subscription;

  constructor(
    private lang: LangService,
    private bcAssessments: BcAssessmentsService,
  ) { }

  ngOnInit(): void {
  }

  isEnabled(doc:IDocument){
    if (doc.isDisabled){ return false; }
    return this.bcAssessments.isFsaAdminAvailable();
  }

  ngOnDestroy() {
    if (this.asmtAvailabilitySubcription) this.asmtAvailabilitySubcription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grade) {
      this.documentGroups = this.getDocumentGroups(this.grade);
    }
  }

  private getDocumentGroups(grade: number): IDocumentGroup[] {
    if (grade == 4) {
      return [
        [{
          title: this.lang.tra('sa_se_scoring_guide'),
          link: this.lang.tra('sa_se_scoring_guide_links'),
        }, {
          title: this.lang.tra('sa_se_possible_solutions'),
          link: this.lang.tra('sa_se_possible_solutions_g4_links'),
        }],
        [{
          title: this.lang.tra('sa_se_them1_lit'),
          link: this.lang.tra('link_sa_se_g4_lt1'),
          isDisabled: false,
        }, {
          title: this.lang.tra('sa_se_them2_lit'),
          link: this.lang.tra('link_sa_se_g4_lt2'),
          isDisabled: false,
        }],
        [{
          title: `${this.lang.tra('sa_numeracy')} - ${this.lang.tra('sa_se_provincial_exemplars')}`,
          link: this.lang.tra('link_sa_se_g4_n'),
          isDisabled: false,
        }]
      ]
    }
    if (grade == 7) {
      return [
        [{
          title: this.lang.tra('sa_se_scoring_guide'),
          link: this.lang.tra('sa_se_scoring_guide_links'),
        }, {
          title: this.lang.tra('sa_se_possible_solutions'),
          link: this.lang.tra('sa_se_possible_solutions_g7_links'),
        }],
        [{
          title: this.lang.tra('sa_se_them1_lit'),
          link: this.lang.tra('link_sa_se_g7_lt1'),
          isDisabled: false,
        }, {
          title: this.lang.tra('sa_se_them2_lit'),
          link: this.lang.tra('link_sa_se_g7_lt2'),
          isDisabled: false,
        }],
        [{
          title: `${this.lang.tra('sa_numeracy')} - ${this.lang.tra('sa_se_provincial_exemplars')}`,
          link: this.lang.tra('link_sa_se_g7_n'),
          isDisabled: false,
        }]
      ]
    }
    return [];
  }

}
