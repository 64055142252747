import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatRowType, INewChat, INewMessage, MarkingChatService} from '../../marking/marking-chat.service';
import * as moment from 'moment-timezone';
import {FormControl, FormGroup} from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {AuthService} from '../../api/auth.service';
import { MarkingCallService } from 'src/app/marking/marking-call.service';
import { CallState } from 'src/app/marking/marking-call.service';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { getDisplayName } from './utils';
import { faThumbsDown, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { DefaultBrowserBehavior } from 'amazon-chime-sdk-js';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { LangService } from 'src/app/core/lang.service';

enum MarkingAccountTypes
{
  markingLeader = "mrkg-lead",
  markingCoordinator = "mrkg-coord",
  marker = "mrkg-mrkr",
}

@Component({
  selector: 'chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})

export class ChatBoxComponent implements OnInit, AfterViewInit, OnDestroy {
  SECTION_SOFT_MAX = 5;
  MAX_RECENT = 5;
  MY_UID = 1;
  MAX_CHARACTERS = 2500;

  @ViewChild('AddUsersToChat') addUsersSearch: ElementRef;

  ChatRowType = ChatRowType;

  CallState = CallState;

  deviceForm: any;

  JSON = JSON;
  Object = Object;

  outputDeviceValid: boolean = true;
  inputDeviceValid: boolean = true;
  getDisplayName = getDisplayName

  constructor(
      public chat: MarkingChatService,
      public auth: AuthService,
      public callService: MarkingCallService,
      public whitelabelService: WhitelabelService,
      public lang: LangService
  ) { 

    this.deviceForm = {
      audioOutputDevice: 'default',
      audioInputDevice: 'default',
     }

    callService.meetingDidEnd.subscribe(val => {
      // Close setting page if the call ends
      this.callService.viewChangeDevices = false;
      if(val) {
        this.scrollToBottom();
      }
    });
  }

  dragEnd($event: CdkDragEnd) {
    console.log($event.source.getFreeDragPosition());
  }
  @ViewChild('textInput') textArea: CdkTextareaAutosize;
  @ViewChild('chatHistory') chatDiv: ElementRef;
  
  public isLoaded = true; // false;
  public isOpen = false;
  public isDragging = false;
  public isUsingInput = false;
  public isEmojiPickerShowing = false;
 // public openChat: IChat;
  public openChat: INewChat;
  public markingAccountTypesSortingOrder = 
  {
    "leader": 1,
    "coordinator": 2,
    "marker": 3
  };
  public showMoreBroadcasts = false;
  public showMorePeople = false;
  public accountType = null;
  
  public showMoreRecent = false;

  searchField = new FormControl();
  searchResults: INewChat[];
  chatField = new FormControl();

  ngOnInit(): void 
  {
  //   this.deviceForm = new FormGroup({
  //     audioOutputDevices: new FormControl('default'),
  //     audioOutputDevicesOther: new FormControl(),
  //     audioInputDevices: new FormControl('default'),
  //     audioInputDevicesOther: new FormControl()
  //  });

    this.chatField.valueChanges.subscribe(x => {
        if(x.length > this.MAX_CHARACTERS) {
          this.chatField.setValue(x.substring(0, this.MAX_CHARACTERS));
        }
    })

    this.chat.gotNewChatData.subscribe(did => {
      if(!did) return;
      if(this.openChat && !(this.openChat.chatType == ChatRowType.GROUP && this.openChat.chat.length == 0)) {
        console.log("YO");
        //this.openChat = this.chat.recent.find(r => r.chatId == this.openChat.chatId) /*|| this.chat.groups.find(row => row.chatId == this.openChat.chatId)*/;
        let currentOpenChat = {...this.openChat};
        let newOpenChat = this.chat.recent.find(r => r.chatId == this.openChat.chatId);
        if(currentOpenChat.chat.length > newOpenChat.chat.length) {
          newOpenChat.chat = [...currentOpenChat.chat];
        }
        this.openChat = newOpenChat;
      }
    });

    this.auth.user().subscribe((user)=> 
    {
      if(!user || !user.uid) 
      {
        return;
      }

      this.accountType = user.accountType;
      console.log(user.accountType);
      if (this.accountType === MarkingAccountTypes.marker)
      {
        this.changeAccountTypesSortingOrderForMarker();
      }
    });
  }

  ngAfterViewInit() {
    this.chat.elem = this.chatDiv;
  }

  ngOnDestroy() {
    this.callService.meetingCreated.unsubscribe();
  }

//   ngOnChanges(simpleChanges:SimpleChanges) 
// {
//     console.log("simpleChanges");
//     if (simpleChanges && simpleChanges.accountType)
//     { 
//       console.log(simpleChanges.accountType);
//       // if marker
//       if (this.accountType != null && this.accountType === MarkingAccountTypes.marker)
//         {
//           this.changeAccountTypesSortingOrderForMarker();
//         }
//     }
// }

  getMessagePlaceholder(){
    return this.lang.tra('mrkg_cht_placeholder');
  }

  getChatPlaceholder(){
    return this.lang.tra('mrkg_cht_placeholder_chat');
  }

  getSearchNamePlaceholder(){
    return this.lang.tra('mrkg_typename')
  }

  public changeAccountTypesSortingOrderForMarker()
  {
    // sort users by role first, then by first name, then by last name
    // default order: leader, coordinator, markers
    // for markers only, bring markers to the beginning of the list:
    // (they will be displayed as "partners")

    this.markingAccountTypesSortingOrder = 
    {
      "marker": 1,
      "leader": 2,
      "coordinator": 3
    };
  }

  dragStart(e) {
    this.isDragging = true;
  }

  getRecent() 
  {
    // console.log(this.chat.recent);
    this.chat.recent.sort(this.sortByUnreadFirst);
    if(!this.chat.recent) return [];
    if (this.showMoreRecent) 
    {
      return this.chat.recent;
    } 
    
    else 
    {
      return this.chat.recent.slice(0, this.SECTION_SOFT_MAX);
    }
  }

  private sortByUnreadFirst(a, b): number
  {
    //a.unreadCount or b.unreadCount cannot be less than 0

    if (a.unreadCount > 0 && b.unreadCount === 0)
    {
      return -1;
    }

    else if (b.unreadCount > 0 && a.unreadCount === 0)
    {
      return 1;
    }

    else
    // cases in else:
    // 1) message A and message B being sorted both have 0 unread messages
    // 2) message A and message B both have more than 0 unread messages
    {
      return 0;
    }
  }

  getVisibleBroadcasts() {
    if(!this.chat.broadcasts) return [];
    if (this.showMoreBroadcasts) {
      //return this.chat.allChatData.groups.rows;
      return this.chat.broadcasts;
    } else {
      //return this.chat.allChatData.groups.rows.slice(0, this.SECTION_SOFT_MAX);
      return this.chat.broadcasts.slice(0, this.SECTION_SOFT_MAX);
    }

  }
  
  getVisiblePeople() 
  {
    if(!this.chat.contacts) return [];

    // console.log(this.chat.contacts);
    this.chat.contacts.sort(this.sortUsers(this.markingAccountTypesSortingOrder));

    // console.log(this.chat.contacts);
    if (this.showMorePeople) 
    {
      //return this.chat.allChatData.people.rows;
      return this.chat.contacts;
    } 
    
    else 
    {
      //return this.chat.allChatData.people.rows.slice(0, this.SECTION_SOFT_MAX);
      return this.chat.contacts.slice(0, this.SECTION_SOFT_MAX);
    }
  }

  private sortUsers(markingAccountTypesSortingOrder: any)
  {
    return (a,b) =>
    {
    // sort users by role first, then by first name, then by last name
    // default order: leader, coordinator, markers
    // for markers only, bring markers to the beginning of the list:
    // (they will be displayed as "partners")

      // console.log("consoloing", markingAccountTypesSortingOrder);
      if (markingAccountTypesSortingOrder[a.type] < markingAccountTypesSortingOrder[b.type])
      {
        return -1;
      }

      else if (markingAccountTypesSortingOrder[a.type] > markingAccountTypesSortingOrder[b.type])
      {
        return 1;
      }

      else 
      {
        if (a.firstName < b.firstName)
        {
          return -1;
        }
    
        else if (a.firstName > b.firstName)
        {
          return 1;
        }
        
        else 
        {
          if (a.lastName < b.lastName)
          {
            return -1;
          }
    
          else if (a.lastName > a.lastName)
          {
            return 1;
          }
    
          else 
          {
            return 0;
          }
        }
      }
    }
  } 
  
  getChatLabel(){
    return this.whitelabelService.getSiteText('marking_chat') || 'Chat';
  }

  innerWidth;
  innerHeight;
  toggleOpen() {
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
    if(!this.isOpen && this.draggedPosition){
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      if((this.draggedPosition.y - 420) < (-this.innerHeight)) { 
        this.draggedPosition = {x: this.draggedPosition.x, y: ((-this.innerHeight) + 420)}
      }
      if((this.draggedPosition.x + 520) > this.innerWidth) { 
        this.draggedPosition = {x: (this.innerWidth - 520), y: this.draggedPosition.y}
      }
    }
    this.isOpen = !this.isOpen;
  }

  refreshOpenChat() {
    const allRows = this.getRecent().concat(this.getVisiblePeople()).concat(this.getVisibleBroadcasts());
    for (const row of allRows) {
      if (row.chatId === this.openChat.chatId) {
        this.openChat = row;
        break;
      }
    }
  }

  getName(chat) {
    if(chat.name) {
      return chat.name;
    }
    else if(chat.chatType == 'GROUP') {
      let participants = this.getParticipantsForNames(chat);
      let name = '';
      for(let p = 0; p < participants.length; p++) {
        name += (participants[p].firstName + (p < participants.length - 1 ? ", " : ''));
      }
      return name;
    }
    else {
      return chat.firstName +" "+ chat.lastName;
    }
  }
  getSubtitle(chat) {
    if(chat.name) {
      return this.lang.tra('mrkg_cht_broadcast').toLowerCase().charAt(0).toUpperCase();
    }
    else if(chat.chatType == 'GROUP') {
      return this.lang.tra('mrkg_cht_group');
    }
    else {
      let sub = (chat.type.charAt(0).toUpperCase() + chat.type.slice(1)).trim();
      if( sub == 'Marker') {
        if (this.accountType === MarkingAccountTypes.marker) {
          return "Partner";
        }
        
        if(chat.isApplicant) return 'Applicant';
      }
      return (chat.type.charAt(0).toUpperCase() + chat.type.slice(1)).trim();
    }
  }
  getSubtitleDisplay(chat){
    if(chat.name) {
      return this.lang.tra('mrkg_cht_broadcast')[0].toUpperCase() + this.lang.tra('mrkg_cht_broadcast').substring(1).toLowerCase();
    }
    else if(chat.chatType == 'GROUP') {
      return this.lang.tra('mrkg_cht_group');
    }
    
    let sub = (chat.type.charAt(0).toUpperCase() + chat.type.slice(1)).trim();
    if( sub == 'Marker') {
      if( this.accountType === MarkingAccountTypes.marker) {
        return this.lang.tra("lbl_partner");
      }
      if(chat.isApplicant) return 'Applicant';
    }
    if(chat.type.includes('leader')) return this.lang.tra('mrkg_cht_leader')
    if(chat.type.includes('coordinator')) return this.lang.tra('mrkg_cht_coordinator')
    if(chat.type.includes('marker')) return this.lang.tra('mrkg_cht_marker')
    if(chat.type.includes('applicant')) return this.lang.tra('mrkg_cht_applicant')
    
    return (chat.type.charAt(0).toUpperCase() + chat.type.slice(1)).trim();
  }

  getTitle(chat) {
    if(chat.name) {
      return chat.name;
    }
    else if(chat.chatType == 'GROUP') {
      let participants = this.getParticipantsForNames(chat);
      let name = '';
      for(let p = 0; p < participants.length; p++) {
        name += (participants[p].firstName + (p < participants.length - 1 ? ", " : ''));
      }
      return name;
    }
    else {
      return chat.firstName +" "+ chat.lastName + " (" +  chat.type.charAt(0).toUpperCase() + chat.type.slice(1) + ")";
    }
    
  }

  getFirstName(chat, message) {
    if(chat.firstName) {
      return chat.firstName;
    }
    else if(chat.participants?.length > 2) {
      for(let p of chat.participants) {
        if(p.uid == message.senderUid) {
          return p.firstName;
        }
      }
    }
    else {
      return chat.name;
    }
  }

  newGroup() {

    this.openChat = {
      id: null,
      unreadCount: 0,
      activeCount: 0,
      theirUUID:0,
      chat: [],
      chatType: ChatRowType.GROUP,
      chatId: null,
      participants:[{uid:this.auth.getUid()}]
    }
    this.chat.openChatId = this.openChat.chatId;

    //wait for it to render
    setTimeout( () => this.addUsersSearch.nativeElement.focus());
  }

  selectRow(row: any) {
    console.log(row);
    this.searchField.setValue('');
    this.openChat = row;
    this.chat.openChatId = row.chatId;
    this.chat.clearUnread(row);
    this.scrollToBottom();
    
    // this.chat.subscribeToNameRefresh(() => this.refreshOpenChat())
  }
  closeRow() {
    this.addUsersSearchQuery = '';
    this.openChat = null;
    this.chat.openChatId = null;

    // this.chat.unsubscribeToNameRefresh();
  }

  searchRecent;
  searchGroups;
  searchContacts;
  searchBroadcasts;
  searchChange() {
    let query: string = this.searchField.value;
    
    if (!query || query.length == 0) {
      return;
    }
    
    let searchQuery = query.toLowerCase();
    console.log(searchQuery);
    let searchResults: INewChat[] = [];
    this.searchRecent = [];
    this.searchGroups = [];
    this.searchContacts = [];
    this.searchBroadcasts = [];

    this.chat.contacts.forEach((row: INewChat) => {
      if (this.getTitle(row).toLowerCase().includes(searchQuery)) {
        this.searchContacts.push(row);
        if(row.chat){
          this.searchRecent.push(row);
        }
      }
    });
    
    this.chat.recent.forEach(chat => {
      if (this.isGroupChat(chat)) {
        const groupChatName = this.getName(chat).toLowerCase();

        if(groupChatName.includes(searchQuery)) this.searchGroups.push(chat);
      }
    });

    this.chat.broadcasts.map(broadcast => {
      const broadcastName = broadcast.name.toLowerCase();
      if(broadcastName.includes(searchQuery)) this.searchBroadcasts.push(broadcast);
    })

    this.searchResults = searchResults;
  }

  isGroupChat(chat) {
    return !!chat.participants;
  }

  textAreaKeyUp(e) {
    const isShift = e.shiftKey;
    const key = e.key;
    const isEnter = key === 'Enter';

    if (!isShift && isEnter) {
      e.preventDefault();
      this.sendMessage();
    }
  }

  

  sendMeetingMessage(msg) {
    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }

    const isBroadcast = this.openChat.chatType === ChatRowType.BROADCAST;
    const message: INewMessage = {
      isBroadcast,
      message: msg,
      senderName: name,
      senderUid: uid,
      timestamp: new Date().toString(),
      isUnread: false,
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.insertMessage(message);
    if(this.openChat.participants && this.openChat.participants.length > 2) {
      let uids = this.openChat.participants.map(usr => usr.uid);
      this.chat.sendMessage(this.openChat, message, uids, this.openChat.id);
    }
    else {
      this.chat.sendMessage(this.openChat, message, this.openChat.uid);
    }
    this.chatField.setValue('');

    this.textArea.reset();
  }

  getgetUid() {
    return this.auth.getUid();
  }

  sendMessage() {
    let msg: string = this.chatField.value.trim();
    if (!msg || msg === '\n' || msg == '') {
      return;
    }

    // escape new line in msg
    msg = msg.split('\n').join('  \n');
    
    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }
    console.log(this.openChat);
    const isBroadcast = !!this.openChat.name && !!this.openChat.id;
    const message = {
      isBroadcast,
      message: msg,
      senderName: name,
      senderUid: uid,
      isUnread: false,
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.insertMessage(message);

    if(isBroadcast) {
      let uids = this.openChat.leaders.map(usr => usr.uid);
      uids = uids.concat(this.openChat.markers.map(usr => usr.uid));
      uids = uids.concat(this.openChat.coordinators.map(usr => usr.uid))
      this.chat.sendMessage(this.openChat, message, uids, this.openChat.id);
    }
    else if(this.openChat.participants && this.openChat.participants.length > 2) {
      let uids = this.openChat.participants.map(usr => usr.uid);
      this.chat.sendMessage(this.openChat, message, uids, this.openChat.id);
    }
    else {
      this.chat.sendMessage(this.openChat, message, this.openChat.uid);
    }
    
    this.chatField.setValue('');
    this.textArea.reset();
  }

  insertMessage(message) {
    if(!this.openChat.chat) {
      this.openChat.chat = [];
    }
    this.openChat.chat.push(message);
    this.chat.updateRecent(this.openChat);
    this.scrollToBottom();
  }

  toggleEmojis() {
    this.isEmojiPickerShowing = !this.isEmojiPickerShowing;
    if (!this.isEmojiPickerShowing) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    console.log("SCROLLING");
    let self = this;
    setTimeout(function() {
      if (self.chatDiv) {
        self.chat.elem = self.chatDiv;
        self.chatDiv.nativeElement.scrollTop = self.chatDiv.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined (component)');
      }
    }, 100);
  }

  emojiHandler(emoji) {
    const raw = emoji.native || '';
    const currentTextValue = this.chatField.value || '';

    this.chatField.setValue(currentTextValue + raw);
  }

  inputHandler() {
    const val: string = this.chatField.value || '';
    if (!val) {
      // this.textArea.reset();
    }
  }

  changeHandler() {
    this.textArea.reset(); // resizeToFitContent(true);
  }

  maxRows() {
    if (this.isEmojiPickerShowing) {
      return 1;
    } else {
      return 5;
    }
  }

  toggleShowMoreRecent() {
    this.showMoreRecent = !this.showMoreRecent;
  }
  toggleShowMorePeople() {
    this.showMorePeople = !this.showMorePeople;
  }
  toggleShowMoreBroadcasts() {
    this.showMoreBroadcasts = !this.showMoreBroadcasts;
  }

  createCall() {
    this.callService.meetingCreated.subscribe((value: boolean) => {
      if(value) {
        let msg = {meetingId: this.callService.activeMeetingID};
        this.sendMeetingMessage(JSON.stringify(msg));
      }
      
    });
    this.callService.createCall(this.openChat.chatId);
  }

  joinCall(meetingId) {
    this.callService.joinCall(this.openChat.chatId, meetingId);
  }

  toggleViewChangeDevices() {
    this.callService.viewChangeDevices = true;
  }

  submitDeviceForm(val) {
    console.log(val);


    let outputID = val.audioOutputDevice;

    let inputID = val.audioInputDevice;
    
    this.callService.changeDevices(outputID, inputID);
    this.callService.viewChangeDevices = false;
  }

  pickEmoji(emoji) {
    this.isEmojiPickerShowing = false;
    const raw = emoji || '';
    const currentTextValue = this.chatField.value || '';

    this.chatField.setValue(currentTextValue + raw);
  }

  changedInputDevice() {
    console.log(this.deviceForm);
  }

  changedOutputDevice() {
    console.log(this.deviceForm);
  }

  addUsersSearchQuery:string;
  addUsersSearchResults:any[];

  addUsersSearchQueryChanged() {
    console.log("TEST");
    this.addUsersSearchResults = [];

    let searchQuery = this.addUsersSearchQuery.trim().split(" ")

    for(let contact of this.chat.contacts) {
      let isMatch = true;
      for(let word of searchQuery) {
        if(!contact.firstName?.toLowerCase().includes(word.toLowerCase()) && !contact.lastName?.toLowerCase().includes(word.toLowerCase())) {
          isMatch = false;
        }
      }

      if(isMatch) {
        this.addUsersSearchResults.push(contact);
      }
    }

    console.log(this.addUsersSearchResults);
  }

  addUserToParticipants(user) {
    for(let participant of this.openChat.participants) {
      if(participant.uid == user.uid) {
        this.addUsersSearch.nativeElement.focus();
        this.addUsersSearchQuery = '';
        this.addUsersSearchResults = [];
        return;
      }
    }

    this.addUsersSearch.nativeElement.focus();
    this.addUsersSearchQuery = '';
    this.addUsersSearchResults = [];
    this.openChat.participants.push(user);
    console.log(this.openChat);
  }

  participantPhotos(chat) {
    return chat.participants.filter(p => p.uid != this.auth.getUid()).slice(0, 3).map(p => p.imgURL);
  }

  getParticipantsForNames(chat) {
    return chat.participants.filter(p => p.uid != this.auth.getUid());
  }
  
  async chatWithPair(uid) {
    console.log(uid);

    this.openChat = {
      id: null,
      unreadCount: 0,
      activeCount: 0,
      theirUUID:0,
      chat: [],
      chatType: ChatRowType.GROUP,
      chatId: null,
      participants:[{uid:this.auth.getUid()}].concat(await this.getPairFromUID(uid))
    }
    this.chat.openChatId = this.openChat.chatId;

    //wait for it to render
    setTimeout( () => this.addUsersSearch.nativeElement.focus());
  }

  async getPairFromUID(uid) {
    let res = await this.auth.apiFind('public/mrkg-lead/marking-pairs-users', {
      query: {
        uid
      }
    });
    let pair = [];
    for(let usr of res) {
      pair.push({
        firstName:usr.firstName,
        lastName:usr.lastName,
        uid:usr.uid
      })
    }
    return pair;
  }

  getMyUid() {
    return this.auth.getUid();
  }

  draggedPosition;
  settingDraggedPosition($event){
    this.draggedPosition = $event.source.getFreeDragPosition();
  }
}
