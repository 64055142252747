import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { IContentElementImage, QuestionState, ElementType, TextParagraphStyle } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { PrintAltTextService } from 'src/app/ui-item-maker/print-alt-text.service';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { defaultImageScaleFactor } from '../models/index'
import { TextToSpeechService } from '../text-to-speech.service';

@Component({
  selector: 'render-image',
  templateUrl: './render-image.component.html',
  styleUrls: ['./render-image.component.scss']
})
export class RenderImageComponent implements OnInit {

  @Input() element:IContentElementImage;
  @Input() scaleWidth:number = 1;
  @Input() scaleHeight:number = 1;
  @Input() parentElement?:IContentElementImage
  @Input() questionPubSub?: QuestionPubSub
  @Input() questionState?:QuestionState;
  @Input() draggable?: boolean = false;

  urlRaw:string;
  url:SafeResourceUrl;
  urlRawHighContrast:string;
  urlHighContrast:SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private printAltText: PrintAltTextService,
    private text2Speech: TextToSpeechService
  ) { }

    scaleFactor;
    baseElement
  ngOnInit() {
    this.updateUrl();
    if (this.parentElement) {
      this.baseElement = this.parentElement
    } else {
      this.baseElement = this.element
    }
    this.isInitDynamicImage()
  }

  dynamicUrl = undefined;
  initElementSub(){
    this.questionPubSub.allSub().subscribe(payload => {
      if (!this.questionState || payload.entryId !== this.baseElement.fromEntryId || !this.baseElement.isShowDynamicImage) return;
      const state = this.questionState[payload.entryId]
      switch (state.type) {
        case ElementType.MCQ:
          if (!state.selections.length) return ;
          console.log(this.dynamicUrl)
          const selection = state.selections[0]
          this.dynamicUrl = selection.content;
          if (selection.paragraphStyle == TextParagraphStyle.ADVANCED_INLINE && selection.advancedList) {
            selection.advancedList.forEach((el)=>{
              if (el.elementType==ElementType.IMAGE) {
                console.log(el)
                this.dynamicUrl = el.url
                if (!this.dynamicUrl || this.dynamicUrl=='') {
                  this.dynamicUrl = el.images["default"].image.url
                }
              }
            })
          }
          return;
        default:
          return;
      }
    });
  }

  isInitDynamicImage(){
    if(this.baseElement.isShowDynamicImage){
      this.element.url = ''
      this.initElementSub()
    }
  }

  ngDoCheck() {
    this.updateUrl()
  }

  getScale(){
    let scaleFactor = defaultImageScaleFactor; //this.element.scaleFactor || defaultImageScaleFactor
    return (scaleFactor/100)*(this.element.scale)
  }

  updateUrl(){
    if (this.urlRaw !== this.element.url){
      this.urlRaw = this.element.url;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRaw);
    }
    if (this.element.hiContrastImg && this.urlRawHighContrast !== this.element.hiContrastImg.url){
      this.urlRawHighContrast = this.element.hiContrastImg.url;
      this.urlHighContrast = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRawHighContrast);
    }
    if (this.urlRaw==null) {
      this.url = undefined
    }
    if (this.urlRawHighContrast==null) {
      this.urlHighContrast = undefined
    }
  }

  private checkURLNull(url:SafeResourceUrl) {
    if (url && url!="" && url !=null) {
      return true;
    }
    return false;
  }

  getImageUrl(){
    if (this.dynamicUrl) {
      return this.dynamicUrl
    }
    if (!this.text2Speech.isHiContrast || !this.baseElement.isHiContrastSensitive || !this.urlHighContrast ||this.urlHighContrast =='') {
      if (this.checkURLNull(this.url)) {
        return this.url
      }
    } else {
        if (this.checkURLNull(this.urlHighContrast)) {
          return this.urlHighContrast
        }
    }
    return undefined
  }

  showAltText() {
    return this.printAltText.getAltTextVisible();
  }

  getStyle() {
    const style = {}
    if (this.baseElement.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    return style;
  }
}
