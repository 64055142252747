import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IContentElementDnd, ElementTypeDefs, IElementTypeDef, ElementType, IContentElementDndSub, IContentElement, IContentElementDndDraggable, IContentElementGroup, GroupingArrangementType, GroupingTargetContentAlignmentType } from '../../ui-testrunner/models';
import { createDefaultElement, generateDefaultElementImage, generateDefaultElementText, generateDefaultElementCanvas } from '../item-set-editor/models';
import { CdkDragRelease, CdkDragStart, CdkDragEnd, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { bindFormControls } from '../services/data-bind';
import { FormControl } from '@angular/forms';
import { indexOf } from '../services/util';
import { AuthScopeSettingsService, AuthScopeSetting } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';

export const renderDndElementStyle = (element: IContentElementDndSub, hasContent: boolean= false, isCustomDim: boolean= false, defaultTargetStyle: IContentElementDndSub= null) => {
  const style: {[key: string]: string | number} = {
    'transform': `translate3d(${element.x}px, ${element.y}px, 0px)`,
  };
  if (!hasContent) { // using as a proxy for targets right now...
    const dims = ['width', 'height'];
    dims.forEach(dim => {
      let val;
      if (isCustomDim) {
        val = element[dim];
      }
      if (!val && defaultTargetStyle) {
        val = defaultTargetStyle[dim];
      }
      if (!val) {
        val = 30;
      }
      style[dim + '.px'] = val;
    });
  }
  return style;
};

export interface IElementPos {
  ref: IContentElementDndSub;
  originalX: number;
  originalY: number;
  isTarget?: boolean;
  isDraggable?: boolean;
  style: {
    [key: string]: string | number
  };
}

@Component({
  selector: 'element-config-grouping',
  templateUrl: './element-config-grouping.component.html',
  styleUrls: ['./element-config-grouping.component.scss']
})
export class ElementConfigGroupingComponent implements OnInit {
  @ViewChild('elPosContainer', { static: true }) elPosContainer: ElementRef;
  @Input() element: IContentElementGroup;

  isShowAdvancedOptions = new FormControl(false);
  targetType = new FormControl();
  width = new FormControl();
  height = new FormControl();
  scoreWeight = new FormControl();
  isInstructionsDisabled = new FormControl(false);
  isMatchingMode = new FormControl(false);
  isVerticalLayout = new FormControl(false);
  
  isEditingEntryId:boolean;
  startEditingEntryId(){
    if(confirm('Edit Entry ID? You should avoid doing this unless the entry is blank or duplicated due to an import.')){
      this.isEditingEntryId = true;
    }
  }

  subElementTypes: IElementTypeDef[] = [
    ElementTypeDefs.TEXT,
    ElementTypeDefs.MATH,
    ElementTypeDefs.IMAGE,
    ElementTypeDefs.TABLE,
  ];

  subTargetElementTypes: IElementTypeDef[] = [
    ElementTypeDefs.TEXT,
    ElementTypeDefs.MATH,
    ElementTypeDefs.IMAGE,
  ];



  delimiterType = new FormControl(ElementType.IMAGE);

  delimiterTypes = [
    {id: ElementType.IMAGE, caption: 'Image'},
    {id: "icon", caption: "Arrow"}
  ]

  separatorTypes = [
    {id: ElementType.IMAGE, caption: 'Image'},
    {id: ElementType.CANVAS, caption: "Canvas"}
  ]

  elementsToPosition: IElementPos[] = [];

  throttledUpdate = _.throttle(() => {
    this.updateDisplayEls();
  }, 500);

  lastTouchedElement: IContentElementDndSub;
  selectedPos: IElementPos;

  arrangementType: GroupingArrangementType;

  arrTypes = [
    { caption: "Normal", id: GroupingArrangementType.NORMAL},
    { caption: "Side By Side", id: GroupingArrangementType.SIDE_BY_SIDE},
    { caption: "Manual", id: GroupingArrangementType.MANUAL},
  ]

  alignmentType: GroupingTargetContentAlignmentType;

  targenContainerContentAlignement = [
    { caption: "Center", id: GroupingTargetContentAlignmentType.CENTER},
    { caption: "Left", id: GroupingTargetContentAlignmentType.LEFT},
    { caption: "Right", id: GroupingTargetContentAlignmentType.RIGHT},
  ]

  constructor(
    private authScopeSettings: AuthScopeSettingsService,
    private editingDisabled: EditingDisabledService
  ) { }

  ngOnInit() {
    if (!this.element.draggableCounter) { this.element.draggableCounter = 0; }
    if (!this.element.targetCounter) { this.element.targetCounter = 0; }
    if (!this.element.defaultTargetStyle) { this.element.defaultTargetStyle = {x: 0, y: 0, width: 30, height: 30}; }
    if (this.element.customTargetDim==undefined) { this.element.customTargetDim = false; }
    if (!this.element.targetWidth) this.element.targetWidth = 10;
    if (!this.element.targetHeight) this.element.targetHeight = 10;
    if (!this.element.correctOrders) this.element.correctOrders = [];
    if (!this.element.showCount) this.element.showCount = false;
    if (!this.element.showCountBackgroundColor) this.element.showCountBackgroundColor = 'white';
    if (!this.element.showCountText) this.element.showCountText = "Number of item: ";
    if (!this.element.showCountTextColor) this.element.showCountTextColor = 'black';
    if (!this.element.showCountTextMarginTop) this.element.showCountTextMarginTop = 0;
    const formControls = [
      {f: this.isShowAdvancedOptions, p: 'isShowAdvancedOptions' },
      {f: this.targetType, p: 'targetType' },
      {f: this.width, p: 'width' },
      {f: this.height, p: 'height' },
      {f: this.scoreWeight, p: 'scoreWeight' },
      {f: this.isInstructionsDisabled, p: 'isInstructionsDisabled' },
      {f: this.isMatchingMode, p: 'isMatchingMode' },
      {f: this.isVerticalLayout, p: 'isVerticalLayout' },
      {f: this.delimiterType, p:'delimiterType' },
    ];

    this.element.draggables.forEach((drag)=>{
      if (!drag.voiceover){
        drag.voiceover = {};
      }
      if (!drag.targetID2Amount) {
        drag.targetID2Amount = {}
      }
    })
    if (this.element.arrangementType == undefined){
      this.element.arrangementType = GroupingArrangementType.NORMAL;
    }
    if (this.element.alignmentType == undefined){
      this.element.alignmentType = GroupingTargetContentAlignmentType.CENTER;
    }
    this.element.targets.forEach((target)=>{
      if (!target.x) target.x=0;
      if (!target.y) target.y=0;
      if (!target.backgroundImg) {
        target.backgroundImg = generateDefaultElementImage(ElementType.IMAGE)
      }
    })

    if (!this.element.separatorImage) this.element.separatorImage = generateDefaultElementImage(ElementType.IMAGE);
    if (!this.element.separatorText) this.element.separatorText = generateDefaultElementText(ElementType.TEXT);
    if (!this.element.separatorCanvas) this.element.separatorCanvas = generateDefaultElementCanvas(ElementType.CANVAS);
    if (!this.element.separatorText) this.element.separatorText = generateDefaultElementText(ElementType.TEXT);
    if (!this.element.delimiterImg) this.element.delimiterImg = generateDefaultElementImage(ElementType.IMAGE);

    bindFormControls(this.element, formControls);
    this.updateDisplayEls();
  }

  isManual() {
    return this.element.arrangementType==GroupingArrangementType.MANUAL;
  }

  insertDraggableElement(elementType: ElementType) {
    this.element.draggableCounter = this.incrementCounter(this.element.draggableCounter, this.element.draggables);
    const newEl = {
      id: this.element.draggableCounter,
      element: createDefaultElement(elementType),
      voiceover: {},
      ... this.newDefaultDndElementConfig()
    };
    this.element.draggables.push(newEl);
    this.recordLastTouch(newEl);
    this.updateDisplayEls();
  }

  insertTargetElement(elementType: ElementType) {
    // console.log('insertTargetElement')
    this.element.targetCounter = this.incrementCounter(this.element.targetCounter, this.element.targets);
    const newEl = {
      id: this.element.targetCounter,
      element: createDefaultElement(elementType),
      backgroundImg: generateDefaultElementImage(ElementType.IMAGE),
      ... this.newDefaultDndElementConfig(),
    };
    this.element.targets.push(newEl);
    this.recordLastTouch(newEl);
    this.updateDisplayEls();
  }

  incrementCounter(counter: number, subs: IContentElementDndSub[]) {
    let isFirst = true;
    let isMatchFound = false;
    while (isFirst || isMatchFound) {
      counter ++;
      isFirst = false;
      isMatchFound = false;
      subs.forEach(sub => {
        if ( ('' + sub.id) === ('' + counter) ) {
          isMatchFound = true;
        }
      });
    }
    return counter;
  }


  newDefaultDndElementConfig (): IContentElementDndSub {
    return  {
      x: 0,
      y: 0,
    };
  }

  updateDisplayEls() {
    this.elementsToPosition = [];
    this.element.targets.forEach(element => this.addElementToList(element, this.elementsToPosition, true));
    this.element.draggables.forEach(element => this.addElementToList(element, this.elementsToPosition, false, true));
    this.updateChangeCounter();
  }

  addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false, isDraggable: boolean= false) {
    let hasElement = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      isDraggable,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
  }

  removeElement(arr: any[], t: any) {
    if (window.confirm('Remove this option?')) {
      let i = indexOf(arr, t);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    }
    this.updateDisplayEls();
  }

  drop(arr: any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  debug(str: string) {
    console.log('debug', str);
  }
  recordLastTouch(elementSub: IContentElementDndSub) {
    this.lastTouchedElement = elementSub;
  }

  updateChangeCounter() {
    if (!this.element._changeCounter) {
      this.element._changeCounter = 0;
    }
    this.element._changeCounter ++;
  }

  isSelectedPosRef(el:IContentElementDndSub){
    if (this.selectedPos){
      return (el === this.selectedPos.ref);
    }
  }

  selectPosElByRef(el:IContentElementDndSub){
    this.elementsToPosition.forEach(pos =>{
      if (pos.ref === el){
        this.selectedPos = pos;
      }
    })
  }

  isScoreWeightEnabled = () => this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_SCORE_WEIGHT);

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  setDelimiter($event) {
    this.element.delimiterIcon = "fas fa-arrow-"+$event;
  }

  insertCombinations() {
    if (!this.element.correctCombinations) {
      this.element.correctCombinations = []
    }
    this.element.correctCombinations.push({id2amount: {}})
  }

  deleteCombination(combo) {
    const index = this.element.correctCombinations.indexOf(combo)
    if (index!=-1) {
      this.element.correctCombinations.splice(index,1);
    }
  }

  turnOffOrdered() {
    this.element.isTargetsOrdered = false
  }

  turnOffSame() {
    this.element.isTargetsSame = false
  }

  insertOrder() {
    console.log(this.element.correctOrders)
    this.element.correctOrders.push({order: ''})
  }

  deleteOrder(order) {
    let i = indexOf(this.element.correctOrders, order);
      if (i !== -1){
        this.element.correctOrders.splice(i, 1)
      }
  }
}
