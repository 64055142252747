<div class="score-entry-students">

    <!-- <button id="custom-control" hidden (click)="customControl()">This should be hidden</button> -->
    <div class="section-header">

        <score-entry-aggregation-table [district]="district" [school]="school" [testWindow]="testWindow">
        </score-entry-aggregation-table>

        <div class="score-entry-selectors">
            <div class="grade-incomplete-container">
                <div class="grade-selector show-as-row">
                    <span class="viewing">
                        <tra slug="sa_se_grade"></tra>
                    </span>
                    <select class="selection-filter dropdown-select" [(ngModel)]="selectedGrade"
                        (change)="onSelectedGradeChange()" [disabled]="isLoading">
                        <option *ngFor="let grade of grades" [ngValue]="grade">
                            <tra slug="sa_se_FSA"></tra>-
                            <tra-md [slug]="getGradeDisplay(grade)"></tra-md>
                        </option>
                    </select>
                </div>
                <div class="grade-selector show-as-row">
                    <span class="viewing">
                        <tra slug="sa_sr_viewing"></tra>
                    </span>
                    <select class="selection-filter" [(ngModel)]="selectedRowType"
                        (change)="onIncompleteChanged($event)">
                        <option [ngValue]="ScoreEntryRowType.ALL" selected>
                            <tra slug="sa_se_show_completeNincomplete"></tra>
                        </option>
                        <option [ngValue]="ScoreEntryRowType.INCOMPLETE">
                            <tra slug="sa_se_show_incomplete_rows"></tra>
                        </option>
                        <option [ngValue]="ScoreEntryRowType.COMPLETE">
                            <tra slug="sa_se_show_complete_rows"></tra>
                        </option>
                    </select>
                </div>
            </div>
            <button class="button" [class]="currentLang == 'en' ? 'selected-language-toggle' : 'unselected'" 
            (click)="handleLanguageToggle('en')">English</button>
            <button class="button" [class]="currentLang == 'fr' ? 'selected-language-toggle' : 'unselected'" 
            (click)="handleLanguageToggle('fr')">French</button>

            <score-entry-documents *ngIf="!referesh" [grade]="selectedGrade"></score-entry-documents>

        </div>

        <div class="section-header space-between penInput-Import-container">
            <div class="pen-lookup">
                <div class="pen-search">
                    <input type="number" [(ngModel)]="penToLookup" (input)="searchPen()"
                        [placeholder]='placeholderPEN_search' min="100000000" max="999999999">
                </div>
                <button class="clear-search-button" [disabled]="isLoading" (click)="clearSearchPen()">
                    <tra slug="sa_se_clear_search"></tra>
                </button>
            </div>
            <div class="button-control">
                <button *ngIf="false" (click)="clear()" class="button clear-button" [disabled]="isLoading">
                    <tra slug="sa_se_clear"></tra>
                </button>
                <!-- <button class="button import-button" (click)="importSpreadsheetClicked()" [disabled]="disableImport">
                    <tra slug="sa_se_import_spreadsheets"></tra>
                </button> -->

                <button (click)="saveClicked()" class="button save-button" [disabled]="isLoading || isSaving">
                    <tra slug="sa_save_all"></tra>
                </button>
            </div>
        </div>

        <div class="score-entry-instructions">
            <tra-md slug="da_score_entry_instructions"></tra-md>
        </div>

        <bc-paginator [paginator]="pagination" [getCountDisplay]="getCountDisplay" [showCount]="paginatorShowCount"
            (refresh)="onPaginationChange()">
        </bc-paginator>
        <!-- <div class="accounts-pagination">
            <div>
                <tra slug="sa_page"></tra>
            </div>
            <button class="minimal" (click)="promptPageNumber()">{{ getCurrentPageToDisplay()
                }}</button>
            <div>
                <tra slug="sa_of"></tra> {{ getTotalPagesToDisplay() }} &nbsp;({{this.pagination.count}}
                <tra slug="sa_sa_students"></tra>)
            </div>
            <button class="minimal" (click)="goToPreviousPage()"
                [ngClass]="{'disabled': shouldDisablePreviousPage()}"
                [disabled]="shouldDisablePreviousPage()">
                <i class="fa fa-caret-left" aria-hidden="true"></i>
            </button>
            <button class="minimal" (click)="goToNextPage()"
                [ngClass]="{'disabled': shouldDisableNextPage()}" [disabled]="shouldDisableNextPage()">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
            </button>
        </div> -->

        <!-- <bc-score-entry-table [school]="school" [district]="district" [testWindow]="testWindow"
            [accountType]="accountType"></bc-score-entry-table> -->

        <table class="responses responseSchoolAdmin">
            <tr>
                <th rowspan="2" *ngFor="let headingToSortBy of headingToSortBys">
                    <div>
                        <div (click)="changeOrderBy(headingToSortBy.sortBy)">
                            <span>
                                <tra [slug]="headingToSortBy.heading"></tra>
                            </span>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'asc')" class="fa fa-caret-up"></i>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'desc')" class="fa fa-caret-down"></i>
                        </div>
                        <i class="fa fa-filter" aria-hidden="true" *ngIf="!headingToSortBy.filterDisabled"
                            (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                    </div>
                    <div *ngIf="isFilterVisible(headingToSortBy.sortBy)">
                        <input *ngIf="!headingToSortBy.isSelect" type="text"
                            (input)="updateFilter($event, headingToSortBy.sortBy)"
                            [value]="filterInitValue(headingToSortBy.sortBy)">
                        <select *ngIf="headingToSortBy.isSelect"
                            (change)="updateFilter($event, headingToSortBy.sortBy)">
                            <option *ngFor="let option of headingToSortBy.options"
                                [selected]="filterInitValue(headingToSortBy.sortBy) == option.label">
                                <tra [slug]="option.label"></tra>
                            </option>
                        </select>
                    </div>
                </th>
                <th colspan="4">
                    <tra slug="sa_literacy"></tra>
                </th>
                <th colspan="3">
                    <tra slug="sa_numeracy"></tra>
                </th>
                <th rowspan="2">
                    <tra slug="sa_se_status"></tra>
                </th>
                <th rowspan="2"></th>
            </tr>
            <tr>
                <th>
                    <tra slug="sa_se_theme"></tra>
                </th>
                <th>Q1</th>
                <th>Q2</th>
                <th>Q3</th>
                <th>Q1</th>
                <th>Q2</th>
                <th>Q3</th>
            </tr>
            <tr *ngFor="let row of scoreEntryRows, index as i">
                <td><a class="has-underline" (click)="openEditIndividual(row.uid)">{{row.pen}}</a>
                </td>
                <td>{{row.last_name}}</td>
                <td>{{row.first_name}}</td>
                <td>
                    <div class="select is-small">
                        <select [(ngModel)]="row.theme" (change)="onSelectedThemeChange(row)"
                            (input)="onSelectedThemeInput(row)"
                            [disabled]="isIrtReady || !areThemeAndScoreProfilesReady()">
                            <option [value]="noThemeValue">
                                <tra slug="sa_se_no_theme"></tra>
                            </option>
                            <ng-container *ngIf="themeProfile">
                                <option *ngFor="let option of themeProfile.options" [value]="option.slug">
                                    <tra slug="sa_se_theme"></tra> {{option.slug}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </td>
                <td *ngFor="let slug of fieldSlugs" style="border-right-width: 0px;">
                    <input [title]="getTitleForLit(row.theme)" pattern="[NR0-9]{1,2}" class="input is-small" type="text"
                        [(ngModel)]="row[slug]" [disabled]="isIrtReady || !areThemeAndScoreProfilesReady()"
                        (change)="onScoreChange(row, slug)" (input)="onScoreInput(row, slug)">
                </td>

                <td>
                    <tra [slug]="getStatus(row)"></tra>
                </td>
                <td>
                    <button class="button is-small" (click)="markAsNotYetScoredClicked(row)"
                        [disabled]="shouldDisableMarkAsNotYetScoredButton(row)">
                        <tra *ngIf="!row.is_marked_not_yet_scored" slug="sa_se_notYetScored"></tra>
                        <tra *ngIf="row.is_marked_not_yet_scored" slug="sa_se_mark_submitted"></tra>
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>



<div class="custom-modal" *ngIf="showImportSpreadSheetModal">
    <div class="modal-contents" style="width:700px">
        <div *ngIf="isUploadLoading" class="loader-container">
            <div class="bcg-loader">
                <div></div>
            </div>
        </div>

        <div class="modal-header">
            <tra slug="da_score_entry_upload_moda_title"></tra>
        </div>

        <div class="sample-excel">
            <a [href]="sampleExcelLink">
                <tra slug="da_score_entry_download_sample_spreadsheet"></tra>
            </a>
        </div>

        <bc-upload-widget *ngIf="!uploadResultsTable" [error]="importSpreadSheetError"
            (file)="onSpreadSheetChange($event)" [disabled]="isUploadLoading">
        </bc-upload-widget>

        <div class="import-results-section" *ngIf="uploadResultsTable">
            <div class="import-results-row" *ngFor="let row of uploadResultsTable" [style.background-color]="row.color">
                <div class="two-columns">
                    <div class="first-column">
                        <div class="field-row">
                            <div class="field-label">PEN</div>
                            <div class="field-value">{{row.pen}}</div>
                        </div>
                        <div class="field-row">
                            <div class="field-label">School Code</div>
                            <div class="field-value">{{row.school_code}}</div>
                        </div>
                        <div class="field-row">
                            <div class="field-label">First Name</div>
                            <div class="field-value">{{row.first_name}}</div>
                        </div>
                        <div class="field-row">
                            <div class="field-label">Last Name</div>
                            <div class="field-value">{{row.last_name}}</div>
                        </div>
                        <div class="field-row">
                            <div class="field-label">Grade</div>
                            <div class="field-value">{{row.grade}}</div>
                        </div>
                    </div>
                    <div class="second-column">
                        <ng-container *ngFor="let slug of fieldSlugs">
                            <div class="field-row" *ngIf="row[slug]">
                                <div class="field-label">{{getDisplayFromSlug(slug)}}</div>
                                <div class="field-value">{{row[slug]}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <hr [style.background-color]="row.hrColor">
                <div class="import-results">
                    {{row.message}}
                </div>
            </div>
        </div>

        <div class="actions">
            <button class="cancel-button" (click)="closeImportSpreadSheetModal()" [disabled]="isUploadLoading">
                <tra slug="sa_aa_cancel"></tra>
            </button>
            <button class="add-button" (click)="uploadSpreadSheet()" *ngIf="!uploadResultsTable"
                [disabled]="isUploadLoading || !validScoreEntrySpreadSheetRows || validScoreEntrySpreadSheetRows.length == 0">
                <tra slug="sa_aa_upload"></tra>
            </button>
            <button class="retry-button" (click)="uploadAnother()" *ngIf="uploadResultsTable">
                <tra slug="sa_aa_upload_another"></tra>
            </button>
        </div>
    </div>
</div>