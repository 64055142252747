import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { IContentElementImage, QuestionState, ElementType, IEntryStateVideo, IContentElementAudio, ToolTipPositions, DisplayMode } from "../models";
import { Subject } from "rxjs";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import { LangService } from "../../core/lang.service";
import { RenderAudioComponent } from "../render-audio/render-audio.component";
import { HttpClient } from '@angular/common/http';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { ActivatedRoute } from "@angular/router";
import { TextToSpeechService } from "../text-to-speech.service";
import { stat } from "fs";
import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { DataGuardService } from "../../core/data-guard.service";

@Component({
  selector: "element-render-audio",
  templateUrl: "./element-render-audio.component.html",
  styleUrls: ["./element-render-audio.component.scss"]
})
export class ElementRenderAudioComponent implements OnInit, OnDestroy {
  @Input() element: IContentElementAudio;
  @Input() isLocked: boolean;
  @Input() isShowSolution: boolean;
  @Input() questionState: QuestionState; // not used
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowTranscripts: boolean = false;
  @Input() allowPlaybackSpeed: boolean = false;
  @Input() showTimeLeft: boolean = true;

  @ViewChild(RenderAudioComponent) audioPlayer: RenderAudioComponent;

  constructor(private changeDetector: ChangeDetectorRef, private auth: AuthService,
    private routes: RoutesService, private route: ActivatedRoute, private dataGuard: DataGuardService,
    public lang: LangService, private http: HttpClient, private textToSpeech:TextToSpeechService) {}

  playBackRate = 1.0
  playBackRates = [
    1.0, 0.9, 0.75, 0.5, 0.25
  ]

  trigger: Subject<boolean> = new Subject();
  ToolTipPositions = ToolTipPositions;
  accommodation
  isAutoPlay;
  transcriptUrl

  ngOnInit() {
    if (this.element.assetId) {
      this.auth.apiGet(this.routes.TEST_AUTH_ASSET, this.element.assetId).then((res)=>{
        if (!res) {
          return
        }
        if (this.lang.getCurrentLanguage()=='en' && res["transcript_en"]) {
          this.transcriptUrl = res["transcript_en"]
        } else if (this.lang.getCurrentLanguage()=='fr' && res["transcript_fr"]) {
          this.transcriptUrl = res["transcript_fr"]
        }
      })
    } else {
      this.transcriptUrl = this.element.transcriptUrl
    }
    this.isPlaying = false;
    if (this.element.isAutoPlay) {
      this.initAudioCountdown();
    }
    this.accommodation = this.route.snapshot.queryParamMap.get('isAccomm');

  }

  ngAfterViewInit() {
    if (this.isQuestionState() && this.questionState[this.element.entryId]["transcriptAudioTime"]!=-1) {
      this.createTranscriptTimer(true, this.questionState[this.element.entryId]["transcriptAudioTime"])
      if (!this.transcriptOn) this.showTranscript()
    }
  }

  hasCheckedCanStart;
  isPubSubCalled: boolean = false
  canStartPlaying() {
    if (!this.hasCheckedCanStart && this.questionState && this.canPlay()) {
      const id = this.element.entryId;
      const el = this.getAudioElement();
      //console.log("checking for timestamp");
      if (this.questionState && this.questionState[id]) {
        const ts = this.questionState[id].timeStamp;
        //console.log("timestamp", ts);
        if (!el) return;
        if (ts && ts > 0 && ts < el["duration"]) {
          this.isAutoPlay = true;
          //console.log("setting current time", ts);
          el["currentTime"] = ts;
          //console.log("timestamp", el["currentTime"]);
          el.play();
          //console.log("timestamp", el["currentTime"]);
        }
      }
      this.hasCheckedCanStart = true;
    }

    //If the audio views are done reveal the MIC - This is intentionally being put here as this method is called upon audio meta data loaded
    if(!this.isEnabled() && this.element.numAudioPlays - this.getNumViews() <= 0 && !this.isPubSubCalled){ //!this.isEnabled() && this.element.numAudioPlays - this.getNumViews() <= 0 
      // #TODO: It should be based on identifying if all the elements are loaded rather than a timeout
      setTimeout(this.callPubs, 2000)
      this.isPubSubCalled  = true 
    }
  }

  bufferVal = 5;
  isPlaying = false;
  ngOnDestroy() {
    this.isCountdownInProgress = false;
    if (this.transcriptTimerInterval) {
      clearInterval(this.transcriptTimerInterval);
    }
    const id = this.element.entryId;
    if (id && this.questionState && this.questionState[id]) {
      const qState = this.questionState[id];
    }
    try {
      const el = this.getAudioElement();
      // console.log('el to destroy', el)
      el.pause();
    } catch (e) {}
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state: IEntryStateVideo = {
        isStarted: true,
        isFilled: false || this.element.isOptional,
        isResponded: false,
        numViews: 0,
        timeStamp: 0,
        transcriptAudioTime: -1,
        type: ElementType.AUDIO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      return this.questionState[this.element.entryId];
    }
    else{
      return {};
    }
  }

  private isSafari() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }

  isCountdownInProgress: boolean;
  countdownInterval: any;
  transcriptTimerInterval: any;
  countdownTimeRemaining: number;
  initAudioCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.isCountdownInProgress = true;
    this.countdownTimeRemaining = this.element.autoPlayDelay;
    this.countdownInterval = setInterval(() => {
      if (!this.isCountdownInProgress || this.isLocked) {
        return;
      }
      if (this.countdownTimeRemaining > 0) {
        this.countdownTimeRemaining--;
      }
      if (this.countdownTimeRemaining <= 0) {
        this.isCountdownInProgress = false;
        // play audio
        this.trigger.next(true);
      }
    }, 1000);
  }

  hasControls() {
    if (this.element.isHidden) {
      return false;
    }
    return true;
  }

  onPlay() {
    console.log("on play");
    this.addView();
    if (!this.transcriptOn) {
      this.showTranscript()
    }
  }

  play() {
    this.isCountdownInProgress = false;
    this.isPlaying = true;
    if(this.questionState && this.questionState[this.element.entryId]) this.questionState[this.element.entryId].isResponded = true;
    console.log("in play");
    if (!this.transcriptOn) {
      this.showTranscript()
    }
  }

  notPlaying() {
    this.isPlaying = false;
    this.isAutoPlay = false;
  }

  addView() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId];
      if (!this.isAutoPlay) {
        this.isAutoPlay = true;
        state.numViews++;
        state.timeStamp = 0;
        this.resetFlag();
      }
    }
  }

  getParsedFloatNumber(str: string, toFix: number){
    return str ? Number(Number.parseFloat(str).toFixed(toFix)) : undefined
  }

  isEnabled() {
    const state = this.ensureState();
    const el = this.getAudioElement();
    if (!el) {
      return false;
    }
    if (state) {
      if (this.element.isLimitAudioPlays &&  state.numViews >= this.element.numAudioPlays) {
        let timeStamp = this.getParsedFloatNumber(state["timeStamp"], 3)
        let duration = this.getParsedFloatNumber(el["duration"], 3)

        // Check if time is remaining for the view
        if ((timeStamp == 0 || (timeStamp!=undefined && timeStamp >= duration)) ){
          return false;
        }
      }
      if (state["transcriptAudioTime"]!=-1) {
        return false;
      }
      return true;
    }
    return false;
  }

  isQuestionState() {
    return this.questionState && this.questionState[this.element.entryId];
  }

  getTranscriptTimer() {
    if (this.isQuestionState() && this.getAudioElement()) {
      const state:IEntryStateVideo = this.questionState[this.element.entryId]
      if (state.transcriptAudioTime >= 0) {
        return Math.floor(((this.element.transcriptReadTime)*1000-state.transcriptAudioTime)/1000)
      }
    }
    return -1;
  }

  hasTranscriptBox() {
    return this.element.transcriptBoxID || this.element.transcriptBoxID==0
  }

  createTranscriptTimer(event, startTime:number = 0) {
    if (this.isQuestionState() && !this.transcriptTimerInterval && this.getAudioElement()) {
      const state:IEntryStateVideo = this.questionState[this.element.entryId];
      state.transcriptAudioTime = startTime;
      this.transcriptTimerInterval = setInterval(()=>{
        state.transcriptAudioTime+=100;
        if (this.hasTranscriptBox()) {
          this.questionPubSub.elementPub(this.element.transcriptBoxID,PubSubTypes.TIMER,this.getTranscriptTimer())
        }
        if (state.transcriptAudioTime>=(this.element.transcriptReadTime)*1000) {
          setTimeout(()=>{
            this.playEndHelper(event);
          }, 50);
          state.transcriptAudioTime = -1
          clearInterval(this.transcriptTimerInterval);
          this.transcriptTimerInterval = undefined;
          if (this.transcriptOn) this.showTranscript()
        }
      }, 100)
    }
  }

  playEnd(event) {
    if (!this.element.transcriptReadTime || !this.isQuestionState() || this.accommodation!=1) {
      this.playEndHelper(event);
      if (this.transcriptOn) this.showTranscript()
    } else {
      this.createTranscriptTimer(true)
    }
  }

  skipTimer() {
    if (this.isQuestionState()) {
      const state:IEntryStateVideo = this.questionState[this.element.entryId]
      state.transcriptAudioTime = (this.element.transcriptReadTime)*1000
    }
  }

  confirmEnd() {
    this.onAudioEnd();
    this.notPlaying();
  }

  playEndHelper(event) {
    this.confirmEnd();
    if (this.hasTranscriptBox()) {
      this.questionPubSub.elementPub(this.element.transcriptBoxID,PubSubTypes.INPUT,"")
    }
    if (this.element.loopAutomatically) {
      const state = this.questionState[this.element.entryId];
      if (!state) return;
      const element = this.getAudioElement();
      if (element && (!this.element.isLimitAudioPlays || state.numViews<this.element.numAudioPlays) && event) {
        this.trigger.next(true);
        this.play()
        this.onPlay()
      }
    }
  }

  onAudioEnd() {
    this.callPubs();
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId];
      state.isFilled = true
      // trigger force save
      this.dataGuard.triggerForceSave();
    }
  }

  callPubs = () => {
    if (this.element.onAudioEnd && (this.questionState[this.element.entryId].numViews >= this.element.numAudioPlays || !this.element.numAudioPlays)) {
      if(this.questionPubSub){
        this.element.onAudioEnd.forEach(pub => {
          this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
        });
        this.isPubSubCalled = true
      }
      
    }
  }

  adjustRate() {
    const el = this.getAudioElement()
    if (el) {
      el.playbackRate = this.playBackRate
    }
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  getNumViews() {
    if (this.questionState && this.questionState[this.element.entryId] && this.questionState[this.element.entryId].numViews) {
      return this.questionState[this.element.entryId].numViews;
    }
    return 0;
  }

  canPlay() {
    return this.isEnabled();
  }

  getAudioElement() {
    const id = this.element.entryId;
    let el = null;

    if (this.audioPlayer && this.audioPlayer.audioPlayer) {
      el = this.audioPlayer.audioPlayer.nativeElement;
    }

    return el;
  }

  getStartTime() {
    const state = this.ensureState();
    if (!state) {
      return 0;
    }
    return state.timeStamp;
  }

  leadingZero = (n: number) => {
    if (("" + n).length < 2) {
      return "0" + n;
    }
    return "" + n;
  };
  convertSecToMinSec = sec => {
    if (!sec) {
      return "00:00";
    }
    const min = Math.floor(sec / 60);
    return this.leadingZero(min) + ":" + this.leadingZero(Math.floor(sec - min * 60));
  };

  getSecRemainingStr() {
    const element = this.getAudioElement();
    if (!element) {
      return "";
    }

    if (!this.element.frenchTimer) {
      return this.convertSecToMinSec(element["currentTime"]) + " / " + this.convertSecToMinSec(element["duration"]);
    } else {
      return Math.ceil(element["currentTime"]) + "s / " + Math.ceil(element["duration"])+"s";
    }
  }

  getTranscriptTimeRemaining() {
    if (!this.isQuestionState()) return ''
    const state = this.questionState[this.element.entryId]
    if (state.transcriptAudioTime==-1){
      if (this.element.frenchTimer) return  `0s / ${Math.ceil(this.element.transcriptReadTime)}s` ;
      return '00:00 / '+this.convertSecToMinSec(this.element.transcriptReadTime)
    } 
    const el = this.getAudioElement();
    if (!el) return ''
    if (this.element.frenchTimer) return Math.ceil(state.transcriptAudioTime/1000) + "s / " + Math.ceil(this.element.transcriptReadTime)+"s";
    return this.convertSecToMinSec(state.transcriptAudioTime/1000) + " / " + this.convertSecToMinSec(this.element.transcriptReadTime)
  }

  timeSet = false;
  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getAudioElement();
      if (!element) return;
      //console.log("timestamp update", element["currentTime"])
      const state = this.questionState[this.element.entryId];
      //This was added as a special case for safari on ipad
      if (state.timeStamp && state.timeStamp > element["currentTime"] && !this.timeSet) {
        element["currentTime"] = state.timeStamp;
        this.timeSet = true;
      } else {
        state.timeStamp = element["currentTime"];
      }
    }
  }

  isCustomMode() {
    return !this.element.displayMode || this.element.displayMode === DisplayMode.CUSTOM
  }

  isNormalMode() {
    return this.element.displayMode === DisplayMode.NORMAL
  }

  transcriptOn = false;
  transcript = "";
  showTranscript() {
    if (this.transcriptUrl) {
      this.transcriptOn = !this.transcriptOn;
    } 
    if (!this.transcriptOn) {
      this.questionPubSub.elementPub(this.element.transcriptBoxID, PubSubTypes.INPUT, "")
    } else {
      this.http.get(this.transcriptUrl).subscribe(res => {
        if (!res["text"]) return
        this.transcript = res["text"]
        this.questionPubSub.elementPub(this.element.transcriptBoxID,PubSubTypes.INPUT,this.transcript);
      });
    }
  }
  
  getHCStyle(){
    if(this.textToSpeech.isHiContrast){
      return {
        'background-color' : '#000',
        'color' : '#fff',
        'border' : '1px solid white'
      }
    }
    return  {}
  }

  getRemainingPlayStyle(){
    let style = {}
    style['background-color'] = '#fff'
    style['padding'] = '0.5em'
    style['line-height'] = '1.1em'
    style = Object.assign(style, this.getHCStyle())
    return style
  }

  getRemainingSecStyle(){
    let style = {}
    style['background-color'] = '#f1f3f0'
    style['padding'] = '0.5em'
    style['font-size'] = '0.94em'
    style['white-space'] = 'nowrap'
    style = Object.assign(style, this.getHCStyle())
    return style
  }

  getTranscriptTimeRemainingStyle(){
    let style = {}
    style['background-color'] = '#f1f3f0'
    style['padding'] = '0.5em'
    style['font-size'] = '0.94em'
    style['white-space'] = 'nowrap'
    style = Object.assign(style, this.getHCStyle())
    return style
  }

  getNumPlaysRemainingSlug(){
    return this.element.numAudioPlays- this.getNumViews() > 1 ? 'lbl_num_plays_remaining' : 'lbl_num_play_remaining';
  }
}
