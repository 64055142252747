import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcAccountsService, DistrictDetail, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MaFinalResultsViews } from 'src/app/ui-ministryadmin/ma-final-results/views';
import { SaFinalResultsViews } from 'src/app/ui-schooladmin/sa-final-results/views';
import { BCFSASchoolAdminView } from 'src/app/ui-schooladmin/view-schooladmin-dashboard/data/views';

@Component({
  selector: 'fsa-final-results-table',
  templateUrl: './fsa-final-results-table.component.html',
  styleUrls: ['./fsa-final-results-table.component.scss']
})
export class FsaFinalResultsTableComponent implements OnInit {

  @Input() selectedView: SaFinalResultsViews;
  @Input() accountType: AccountType;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() isIrtReady: boolean;

  isLoading: boolean = false;

  isDownloadingAll: boolean = false;

  sldIncludeScaled: boolean = true;

  SaFinalResultsViews = SaFinalResultsViews;

  constructor(
    private auth: AuthService,
    private router: Router,
    private lang: LangService,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    private bcAccounts: BcAccountsService,
  ) { }

  ngOnInit(): void {
    
  }
  
  selectSchoolLevelDataReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectIndividualStudentResultsReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  getQueryParams() {
    const params = {
      ...this.route.snapshot.queryParams,
    };
    console.log(this.route.snapshot.queryParams);
    if (this.schoolDetail && this.schoolDetail.groupId > 0) {
      params.school = this.schoolDetail.groupId;
    };
    return params;
  }


  getViewRoute(viewSlug: string) {
    let route = this.getBaseRoute();
    if (viewSlug === 'dashboard') {
      return route + '/dashboard';
    }
    route = route + '/' + BCFSASchoolAdminView.BC_FSA_FINAL_REPORT;
    if (this.auth.isMinistryAdmin()) {
      route += '/' + MaFinalResultsViews.RESULTS_REPORT;
    }
    if (viewSlug === BCFSASchoolAdminView.BC_FSA_FINAL_REPORT) {
      return route;
    }
    return route + '/' + viewSlug;
  }

  getBaseRoute() {
    let account: string;
    if (this.auth.isDistrictAdmin(this.accountType)) {
      account = AccountType.DIST_ADMIN;
    } else if (this.auth.isSchoolAdmin(this.accountType)) {
      account = AccountType.SCHOOL_ADMIN;
    } else if (this.auth.isMinistryAdmin(this.accountType)) {
      account = AccountType.MINISTRY_ADMIN;
    } else {
      throw new Error("Unknown account type");
    }
    return `/${this.lang.c()}/${account}/bc-fsa`;
  }

  exportSchoolLevelDataReport() {
    if (!this.schoolDetail) {
      this.loginGuard.quickPopup("Please select a school to export the School Level Data Report.");
      return;
    };
    this.bcAccounts.exportSchoolLevelDataReport(this.schoolDetail.groupId, this.testWindow, this.isIrtReady);
  }

  exportIndividualStudentResultsReports() {
    let route: string;
    if (this.auth.isMinistryAdmin()) {
      route = `/final_results/results_report?window=${this.testWindow.id}&bulk=1`;
    } else {
      route = '/final_results?bulk=1';
    }
    if(this.schoolDetail) {
      route+='&school='+this.schoolDetail.groupId
    }
    window.open('#' + this.getBaseRoute() + route, '_blank');
    // this.isDownloadingAll = true;
    // this.auth.apiCreate('public/bcg-scan/individual-student-results-report', {
    //   districtGroupId:this.districtDetail.groupId,
    //   districtForeignId:this.districtDetail.foreignId,
    //   testWindowId:this.testWindow.id,
    //   isIrtReady:this.isIrtReady,
    // }).then(response => {
    //   // response.data -> response data base64 encoded
    //   // decoding the data via atob()
    //   const byteArray = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
    //   return new Blob([byteArray], {type: 'application/pdf'});
    // })
    // .then(blob => {
    //   saveAs(blob, 'Individual Student Results Report.pdf');
    //   this.isDownloadingAll = false;
    // });
  }

  isScoreEntryAdmin(): boolean {
    return this.auth.isScoreEntrySchoolAdmin() || this.auth.isScoreEntryDistrictAdmin();
  }

  isDistrictSelected() {
    return this.districtDetail?.groupId > 0;
  }

  isSchoolSelected() {
    return this.schoolDetail?.groupId > 0;
  }

  isDownloadAllDisabled() {
    return this.isLoading || !(this.isDistrictSelected() && this.isSchoolSelected());
  }

  isCurrentUserFsaMinistryAdmin() {
    return this.auth.isFsaMinistryAdmin();
  }

  public isDownloadAllAvailable() {
    return this.isSchoolSelected();
  }
}
