import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BcAccountsService, District, DistrictDetail, Filter, FilterCondition, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { SchoolsNotStartedReportRow } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { IColumnHeading } from '../bc-paginated-table/bc-paginated-table.component';

enum EComponentFilter {
  ALL,
  NOT_STARTED,
  IN_PROGRESS,
  SUBMITTED
}
interface IComponentFilterOption {
  id: EComponentFilter;
  caption: string;
  filters: Partial<Filter>[]
}
interface IComponentColumnHeading {
  heading: string;
  subheading: string;
  sortBy?: string;
  filterBy?: string;
  formControl: FormControl,
  sortDisabled?: boolean,
  filterDisabled?: boolean,
}
const COMPONENT_FILTER_OPTIONS: IComponentFilterOption[] = [
  { id: EComponentFilter.ALL, caption: 'All', filters: [] },
  {
    id: EComponentFilter.NOT_STARTED, caption: 'Not Started', filters: [{
      condition: FilterCondition.MATCH,
      value: 0,
    }]
  },
  {
    id: EComponentFilter.IN_PROGRESS, caption: 'In Progress', filters: [{
      condition: FilterCondition.DONT_MATCH,
      value: 0,
    }, {
      condition: FilterCondition.DONT_MATCH_FIELD,
      value: 'total',
    }]
  },
  {
    id: EComponentFilter.SUBMITTED, caption: 'Submitted', filters: [{
      condition: FilterCondition.MATCH_FIELD,
      value: 'total',
    }]
  }
];

interface IGradeOption {
  grade: string;
  caption: string;
}
const GRADE_OPTIONS: IGradeOption[] = [
  { grade: '4', caption: 'sa_sr_grade4' },
  { grade: '7', caption: 'sa_sr_grade7' },
  { grade: '', caption: 'sa_sr_grade4n7' },
]

@Component({
  selector: 'fsa-schools-not-started-report',
  templateUrl: './fsa-schools-not-started-report.component.html',
  styleUrls: ['./fsa-schools-not-started-report.component.scss']
})
export class FsaSchoolsNotStartedReportComponent implements OnInit, OnChanges {

  @Input() districtDetail: DistrictDetail;
  @Input() testWindow: TestWindow;
  @Input() schoolDetail: SchoolDetail;

  columnHeadings: IColumnHeading[] = [
    { heading: 'da_sr_sns_school', sortBy: 'school_name' },
    { heading: 'sa_sr_school_code', sortBy: 'school_foreign_id' },
    { heading: 'da_school_type', sortBy: 'school_type' },
    { heading: 'sa_sr_grades', sortBy: 'grade', sortDisabled: true, filterDisabled: true },
  ]

  gradeOptions: IGradeOption[] = GRADE_OPTIONS;
  selectedGradeOption: IGradeOption;

  pagination: Pagination;
  visibleFilters: Set<string> = new Set();
  filters: Map<string, Filter> = new Map();
  filterThrottle: NodeJS.Timeout | null;
  reportTable: SchoolsNotStartedReportRow[];

  componentFilterOptions: IComponentFilterOption[] = COMPONENT_FILTER_OPTIONS;
  componentFilterOptionsMap: Map<EComponentFilter, IComponentFilterOption> = new Map();
  componentsHeadingToSortBy: IComponentColumnHeading[];
  numeracyFilterFC = new FormControl();
  literacyFilterFC = new FormControl();
  scoreEntryFilterFC = new FormControl();

  isLoading = false;

  constructor(
    private bcAccounts: BcAccountsService,
    private bcReports: BcReportsService,
    private lang: LangService,
  ) { 
    this.pagination = this.bcAccounts.getInitialPagination();
  }

  ngOnInit(): void {
    this.componentsHeadingToSortBy = [
      { heading: 'sa_sr_literacy_lvl2', subheading: 'sa_sr_literacy_lvl2_sub', sortBy: 'sort submitted2', filterBy: 'submitted2', formControl: this.literacyFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: 'sa_sr_numeracy_lvl2', subheading: 'sa_sr_numeracy_lvl2_sub', sortBy: 'sort submitted1', filterBy: 'submitted1', formControl: this.numeracyFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: 'sa_cr_literacy_lvl2', subheading: 'sa_cr_literacy_lvl2_sub', sortBy: 'sort submitted3', filterBy: 'submitted3', formControl: this.scoreEntryFilterFC, sortDisabled: true, filterDisabled: true },
      { heading: 'sa_cr_numeracy_lvl2', subheading: 'sa_cr_numeracy_lvl2_sub', sortBy: 'sort submitted4', filterBy: 'submitted4', formControl: this.scoreEntryFilterFC, sortDisabled: true, filterDisabled: true },
    ]
    // initialize component filters
    this.numeracyFilterFC.setValue(EComponentFilter.ALL)
    this.literacyFilterFC.setValue(EComponentFilter.ALL)
    this.scoreEntryFilterFC.setValue(EComponentFilter.ALL)

    this.selectedGradeOption = GRADE_OPTIONS.filter(option => option.grade === '')[0];

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.districtDetail || changes.schoolDetail || changes.testWindow) {
      console.log(this.districtDetail)
      this.updateTable();
    }
  }

  onPaginationChange() {
    // this.pagination.skip = 0;
    this.updateTable();
  }

  async updateTable() {
    if (!this.districtDetail) return;
    this.isLoading = true;

    const grades = this.getGradeArr();

    console.log(this.pagination)

    const { count, data } = await this.bcReports.getSchoolsNotStartedReport({
      district_group_id: this.districtDetail.groupId,
      grades: grades,
      test_window_id: this.testWindow.id,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
    }, this.pagination);

    this.reportTable = data;
    this.markInfoRows(this.reportTable);
    this.pagination.count = count;

    this.isLoading = false;
  }

  private markInfoRows(data: SchoolsNotStartedReportRow[]) {
    let saved = null;
    let odd = false;
    data.forEach(entry => {
      const current = entry.school_group_id;
      if (current !== saved) {
        entry._isInfo = true;
        saved = current;
        odd = !odd;
      }
      entry._isOdd = odd;
    })
  }

  onSelectedGradeChange(e) {
    this.updateTable();
  }

  districtSelected(e) {
    this.updateTable();
  }

  getStatus(submitted: number, total: number): string {
    if (submitted == null || submitted == 0) return 'da_a_not_started';
    if (submitted < total) return 'da_a_in_progress';
    return 'da_a_completed';
  }

  export() {
    const grades = this.getGradeArr();

    this.bcReports.exportSchoolsNotStartedReport({
      test_window_id: this.testWindow.id,
      district_group_id: this.districtDetail.groupId,
      district_name: this.districtDetail.name,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      school_name: this.schoolDetail ? this.schoolDetail.name : undefined,
      grades: JSON.stringify(grades),
      pagination: this.pagination,
    });
  }

  getBackRoute(): string {
    return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/bc-fsa/session_reports`;
  }

  getDivisionPercentageDisplay(numerator: number, denominator: number): string {
    if (!denominator || denominator === 0) return '0';
    const quotient = (numerator || 0) * 100 / denominator;
    return quotient.toFixed(2) + '%';
  }

  getFilterValue(field: string): string {
    const filter = this.filters.get(field) as Filter;
    let value = '';
    if (filter) {
      if (Array.isArray(filter)) {
        value = filter[0].value;
      } else {
        value = String(filter.value);
      }
    }
    return value;
  }

  formatSchoolCode(schoolCode: number): string {
    return this.bcAccounts.formatSchoolCode(schoolCode);
  }

  getHeadingToSortBy() {
    return this.columnHeadings;
  }

  getComponentsHeadingToSortBy(): IComponentColumnHeading[] {
    return this.componentsHeadingToSortBy;
  }

  filterInitValue(field: string): string | number {
    const filter = this.filters.get(field);
    return filter ? filter.value : '';
  }

  changeOrderBy(by: string) {
    if (this.pagination.orderBy === by) {
      this.pagination.orderDirection = this.pagination.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.pagination.orderBy = by;
      this.pagination.orderDirection = 'asc';
    }
    this.pagination.skip = 0;

    this.updateTable();
  }

  isSortedBy(by: string, direction: 'asc' | 'desc'): boolean {
    return this.pagination.orderBy === by && this.pagination.orderDirection === direction;
  }

  toggleShowFilter(by: string) {
    if (this.visibleFilters.has(by)) {
      this.visibleFilters.delete(by);
    } else {
      this.visibleFilters.add(by);
    }
  }

  isFilterVisible(by: string): boolean {
    return this.visibleFilters.has(by);
  }

  updateFilter(event, by: string) {
    if (event.target.value === '') {
      this.filters.delete(by);
    } else {
      switch (by) {
        case 'school_type':
        case 'school_name':
        case 'school_foreign_id':
          this.filters.set(by, {
            field: by,
            condition: FilterCondition.LIKE,
            value: event.target.value,
          });
          break;
      }
    }

    if (this.filterThrottle !== null) {
      clearTimeout(this.filterThrottle);
    }
    this.filterThrottle = setTimeout(() => {
      this.pagination.filters = [...this.filters.values()];
      this.pagination.count = undefined;
      this.pagination.skip = 0;
      this.updateTable();
      this.filterThrottle = null;
    }, 500);
  }

  private getGradeArr() {
    if (!this.selectedGradeOption) return [4, 7] as (4 | 7)[];
    const grades: (4 | 7)[] = [];
    switch (this.selectedGradeOption.grade) {
      case '4':
        grades.push(4);
        break;
      case '7':
        grades.push(7);
        break;
      default:
        grades.push(4);
        grades.push(7);
    }
    return grades;
  }

}
