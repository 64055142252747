import { Injectable } from '@angular/core';
import { IContextData } from './contexts/common';
import {ENS} from './contexts/ens';
import {MPT} from './contexts/mpt';
import {VEA} from './contexts/vea';
import {EQAO} from './contexts/eqao';
import {BCED} from './contexts/bced';

const availableContexts:IContextData[] = [ // first entry is also the default
  BCED,
  VEA,
  EQAO,
  //MPT,
  // MEES,
  // ENS,
];

export const TEMPLATE_BRAND_NAME = '%BRAND_NAME%';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  context:string;
  contextData:IContextData;
  defaultWhitelabelContext:string;

  currentHomepageRoute:string;
  currentLoginRoute:string;
  currentBrandName:string;
  currentLogo:string;

  constructor() {
    const hostMap:{}[] = []
    const contextRef = this.registerContexts(availableContexts);
    console.log(contextRef)
    // const storedWhitelabel = localStorage.getItem('defaultWhitelabel'); // need to check if the data is defined before using
    this.context = contextRef.hostRef.get(window.location.host) || contextRef.defaultContextId;
    console.log('whitelabel context', this.context)
    this.contextData = contextRef.data.get(this.context);
    this.currentHomepageRoute = this.contextData.homepageRoute;
    this.currentLoginRoute = this.contextData.loginRoute;
    this.currentBrandName = this.contextData.brandName;
    this.currentLogo = this.contextData.logo;
    this.ensureDefaultContext();
  }

  registerContexts(contexts:IContextData[]){
    type ContextId = string;
    const hostRef:Map<ContextId, string> = new Map();
    const data:Map<ContextId, IContextData> = new Map();
    const defaultContextId = contexts[0].id;
    contexts.forEach(contextData => {
      if (data.has(contextData.id)){
        console.warn('Duplicate WL context definition.', contextData.id);
      }
      else{
        data.set(contextData.id, contextData);
        contextData.hosts.forEach(host => {
          if (hostRef.has(host)){
            console.warn('Duplicate host WL definition.', host, contextData.id, hostRef.get(host));
          }
          else{
            hostRef.set(host, contextData.id);
          }
        });
      }
    });
    return {data, hostRef, defaultContextId};
  }

  ensureDefaultContext(){
    const context = this.contextData;
    if (!context){
      console.error('using undefined context');
    }
    context.siteText = this.contextData.siteText || {};
    context.siteFlags = this.contextData.siteFlags || {};
    context.footer = this.contextData.footer || [];
    context.siteText = this.contextData.siteText || {};
    const siteText = this.contextData.siteText;
    if (!siteText['SITE_LINK']){
      siteText['SITE_LINK'] = window.location.host;
    }
  }

  onReady( callback ){
    callback(); // need to delay this if config is loaded over a file
  }

  updateTemplateContent(str:string){
    if (str){
      return str.split(TEMPLATE_BRAND_NAME).join(this.currentBrandName);
    }
    return str;
  }

  getApiAddress(){ return this.contextData.apiAddress(window.location.hostname); }
  getHomepageRoute(){ return this.currentHomepageRoute; }
  getLoginRoute(){ return this.currentLoginRoute || this.currentHomepageRoute; }
  getSiteFlag(slug:string){ return this.contextData.siteFlags[slug]; } 
  getSiteText(slug:string, defaultText:string=''){ return this.contextData.siteText[slug] || defaultText; }
  getFooter(){ return this.contextData.footer; }
  getDefaultCurriculumId(){ return ''; }
}
