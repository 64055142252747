import {Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {AuthService} from '../../api/auth.service';
import {IMessage} from '../../marking/marking-chat.service';
import { getDisplayName } from '../chat-box/utils';
import { Router } from '@angular/router';
import { LangService } from 'src/app/core/lang.service';

interface IMessageSection {
  isEmoji: boolean;
  message: string;
}

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  MY_UID = 1;

  @Input() name: string;
  @Input() message: any;

  getDisplayName = getDisplayName;

  // public messageArray: IMessageSection[] = [];

  // private colonMatcher = '\\:(.*?)\\:'; // \:(.*?)\: ===> This is the best regex thus far

  constructor(
      public auth: AuthService,
      private router: Router,
      public lang: LangService,
  ) { }

  ngOnInit(): void {
    this.MY_UID = this.auth.user().value.uid;
    // const arr = this.message.message.split(/:(.*?):/);

    /*
    let messageArray: IMessageSection[] = [];
    let wasLastEmoji = true;
    arr.forEach(s => {
      if (!!s && this.message.message.charAt(0) === ':') {
        wasLastEmoji = true;
        const newS = ':' + s + ':';
        messageArray.push({isEmoji: true, message: newS});
      } else if (!!s && !wasLastEmoji) {
        wasLastEmoji = true;
        const newS = ':' + s + ':';
        messageArray.push({isEmoji: true, message: newS});
      } else if (!!s) {
        wasLastEmoji = false;
        messageArray.push({isEmoji: false, message: s});
      }
    });
    this.messageArray = messageArray;
    */

  }

  formatMessageTime() {
    var elapsed = (new Date().getTime() - new Date(this.message.created_on).getTime());
    if(elapsed / (1000) < 30) {
      return this.lang.tra("mrkg_now");
    }

    var mins = Math.round(elapsed / (1000 * 60));
    if(mins < 50) {
      return this.lang.tra("mrkg_mins_ago", null, {min: mins});
    }

    var hrs = Math.round(elapsed / (1000 * 60 * 60));
    if(hrs < 20) {
      return hrs == 1 ?  this.lang.tra("mrkg_hour_ago", null, {hour: hrs}) : this.lang.tra("mrkg_hours_ago", null, {hour: hrs});
    }

    var days = Math.round(elapsed / (1000 * 60 * 60 * 24));
    return days == 1 ? this.lang.tra("mrkg_day_ago", null, {day: days}) : this.lang.tra("mrkg_days_ago", null, {day: days});
  }

  linkify(inputText, who) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    let styleTag;
    if(who == 'me') {
      styleTag='style="font-weight:normal; text-decoration:underline; color:white;"';
    }
    else {
      styleTag='style="font-weight:normal; text-decoration:underline; color:white;"';
    }

    if((inputText.includes('http') || inputText.includes('www')) && inputText.includes('exemplarSetID') && inputText.includes('exemplarID')) {
      let exemplarID = this.getParameterByName('exemplarID', inputText);

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a '+styleTag+' href="$1" target="_blank">000REPLACETOKEN000</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a '+styleTag+' href="http://$2" target="_blank">000REPLACETOKEN000</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a '+styleTag+' href="mailto:$1">$1</a>');

      replacedText = replacedText.replace('000REPLACETOKEN000', 'Exemplar #' + (parseInt(exemplarID)+1));

      if(!replacedText) {
        replacedText = inputText;
      }
    }
    else {
      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a '+styleTag+' href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a '+styleTag+' href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a '+styleTag+' href="mailto:$1">$1</a>');

      if(!replacedText) {
        replacedText = inputText;
      }
    }
  
    
    return replacedText;
  }

  getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  routeLink(link) {
    window.open(link, '_blank')
  }

}
