export interface MaterialLink {
    name: string,
    link: string,
    forceLang?: string,
    isDelayedAccess?: boolean,
    isDisabled?: boolean,
    isHidden?: boolean,
    isDynamicLang?: boolean
}
export interface MaterialList {
    title: string,
    subtitle?: string,
    expanded: boolean,
    rows: string[],
    columns: string[],
    columnAvailable: boolean[],
    data: MaterialLink[][][],
}

export const getGradMinistrySpecificSecureMaterialList = (): MaterialList => ({
    title: 'ma_am_ministrygradTitle',
    expanded: false,
    rows: ['ma_am_assessmentFormsRow', 'ma_am_assessmentFormsNumRow'],
    columns: ['', ''],
    columnAvailable: [false, false],
    data: [
        [
            [{
                name: 'ma_am_af_lit10fa_2021',
                link: '/#/en/test-auth/public-test-runner/706',
            }, {
                name: 'ma_am_af_lit10fb_2021',
                link: '/#/en/test-auth/public-test-runner/707',
            }, {
                name: 'ma_am_af_lit10fc_2021',
                link: '/#/en/test-auth/public-test-runner/688',
            }, {
                name: 'ma_am_af_lit12fa_2021',
                link: '/#/en/test-auth/public-test-runner/534',
            }, {
                name: 'ma_am_af_lit12fb_2021',
                link: '/#/en/test-auth/public-test-runner/708',
            }, {
                name: 'ma_am_af_lit12fc',
                link: '/#/en/test-auth/public-test-runner/709'
            }, {
                name: 'ma_am_af_ltp10_wc',
                link: '/#/fr/test-auth/public-test-runner/665'
            }, {
                name: 'ma_am_af_ltp10_oc',
                link: '/#/fr/test-auth/public-test-runner/666'
            }, {
                name: 'ma_am_af_ltp12_wc',
                link: '/#/fr/test-auth/public-test-runner/646'
            }, {
                name: 'ma_am_af_ltp12_oc',
                link: '/#/fr/test-auth/public-test-runner/643'
            }, {
                name: 'ma_am_af_ltf12_wc',
                link: '/#/fr/test-auth/public-test-runner/691'
            }, {
                name: 'ma_am_af_ltf12_oc',
                link: '/#/fr/test-auth/public-test-runner/692'
            }]
        ],
        [
            [{
                name: 'ma_am_af_num10fa_2021',
                link: '/#/en/test-auth/public-test-runner/701',
            }, {
                name: 'ma_am_af_num10fb_2021',
                link: '/#/en/test-auth/public-test-runner/702',
            }, {
                name: 'ma_am_af_num10fc_2021',
                link: '/#/en/test-auth/public-test-runner/703',
            }, {
                name: 'ma_am_af_num10fd_2021',
                link: '/#/en/test-auth/public-test-runner/704',
            }, {
                name: 'ma_am_af_nmf10fb_2021',
                link: '/#/fr/test-auth/public-test-runner/705',
            }]
        ],
    ],
})

export const getGeneralGradSecureMaterialList = (disabledList: string[] = [], hiddenList: string[] = []): MaterialList => {
    return {
        title: 'ma_am_generalgradTitle',
        expanded: true,
        rows: ['sa_grad_am_literacy_materials', 'sa_grad_am_numeracy_materials'],
        columns: ['', ''],
        columnAvailable: [false, false],
        data: [
            [
                [
                    {
                        name: 'grad_admin_guide_lit',
                        link: 'grad_admin_guide_lit_link',
                        isDynamicLang: true,
                        isDisabled: disabledList.includes('grad_admin_guide_lit'),
                        isHidden: hiddenList.includes('grad_admin_guide_lit'),
                    },
                    {
                        name: 'grad_session_password',
                        link: 'grad_session_password_link',
                        isDisabled: disabledList.includes('grad_session_password'),
                        isHidden: hiddenList.includes('grad_session_password'),
                    },
                    {
                        name: 'grad_session_password_ltf12',
                        link: 'grad_session_password_link_ltf12',
                        isDisabled: disabledList.includes('grad_session_password_ltf12'),
                        isHidden: hiddenList.includes('grad_session_password_ltf12'),
                    }
                ]
            ],
            [
                [
                    {
                        name: 'grad_admin_guide_num',
                        link: 'grad_admin_guide_num_link',
                        isDynamicLang: true,
                        isDisabled: disabledList.includes('grad_admin_guide_num'),
                        isHidden: hiddenList.includes('grad_admin_guide_num'),
                    }, 
                    {
                        name: 'grad_session_password',
                        link: 'grad_session_password_link',
                        isDisabled: disabledList.includes('grad_session_password'),
                        isHidden: hiddenList.includes('grad_session_password'),
                    }
                ]
            ],
        ],
    }
}

export const getGeneralFsaSecureMaterialList = (): MaterialList => ({
    title: 'sa_am_header_1',
    expanded: false,
    rows: ['sa_am_material_en', 'sa_am_material_fr'],
    columns: ['sa_am_general', 'sa_am_score', 'sa_am_secure_admin_info'],
    columnAvailable: [true, true, true],
    data: [
        [
            [{
                name: 'Administration Manual',
                link: 'sa_admin_guide_link',

            }, {
                name: 'Information for Parents Brochure',
                link: 'sa_information_for_parents_link',

            }, {
                name: 'Student Learning Assessment Order',
                link: 'sa_student_learning_assessment_order_link',

            }, {
                name: 'Foundation Skills Assessment (FSA) Samples',
                link: 'sa_fsa_sample_landing_link',
            }],

            [{
                name: 'Scoring Guide',
                link: 'sa_score_guide_link',

            }, {
                name: 'Score Entry, System Guide',
                link: 'sa_score_entry_link',

            }, {
                name: 'Information for Organizing Scoring',
                link: 'sa_information_for_org_scoring_link',

            },],

            [
                {
                    name: "Password Information Sheet",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "List of Enrolled Students",
                    link: "sa_enrolled_students_link"
                }
            ],
        ],

        [
            [{
                name: 'Guide d’organisation ',
                link: 'sa_admin_guide_link',

            }, {
                name: 'Informations pour les parents et les tuteurs',
                link: 'sa_information_for_parents_link',

            }, {
                name: 'Student Learning Assessment Order',
                link: 'sa_student_learning_assessment_order_link',

            }, {
                name: 'Évaluations types : Évaluation des habiletés de base (ÉHB)',
                link: 'sa_fsa_sample_landing_link',
            }],

            [{
                name: 'Guide de notation',
                link: 'sa_score_guide_link',

            }, {
                name: 'Entrée des résultats : Guide du système',
                // need FR
                link: 'sa_score_entry_link',

            }],

            [
                {
                    name: "Fiche d’information – mots de passe",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "Liste d'élèves inscrits",
                    link: "sa_enrolled_students_link"
                }
            ],
        ]
    ],
})

export const getG4SecureMaterialList = (): MaterialList => ({
    title: 'sa_am_header_2',
    subtitle: 'sa_am_header_2_sub',
    expanded: false,
    rows: ['sa_am_material_en', 'sa_am_material_fr'],
    columns: ['sa_am_secure_a_links', 'sa_am_scoring_links', 'sa_am_secure_admin_info', 'sa_am_item'],
    columnAvailable: [true, true, true, true],
    data: [
        [
            [
                {
                    name: 'Collaboration Activity',
                    link: 'sa_g4_collab_activity_link',
                    isDisabled: true,
                },

                {
                    name: 'Numeracy SR: Online Preview',
                    link: '/#/en/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_NME4-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
                {
                    name: 'Literacy SR: Online Preview',
                    link: '/#/en/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_LTE4-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,
                },

                {
                    name: 'FSA Secure Login for Students',
                    link: 'sa_student_login_secure_link',
                    isDelayedAccess: true,
                    isDisabled: true,
                },

            ],

            [{
                name: 'FSA specification',
                link: 'sa_g4_fsa_specification',
            },
            ],

            [
                {
                    name: "Password Information Sheet",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "List of Enrolled Students",
                    link: "sa_enrolled_students_link"
                }
            ],

            [{
                name: 'Item Descriptors ',
                link: 'sa_g4_item_desc_link',
                isDelayedAccess: true,
                isDisabled: true,
            }],
        ],

        [
            [
                {
                    name: 'Activité de collaboration',
                    link: 'sa_g4_collab_activity_link',
                    isDisabled: true,
                },
                {
                    name: 'Numératie SR: Online Preview',
                    link: '/#/fr/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_NMF4-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,

                }, {
                    name: 'Littératie SR: Online Preview',
                    link: '/#/fr/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_LTF4-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
                {
                    name: 'ÉHB - session sécurisée - accès élève',
                    link: 'sa_student_login_secure_link',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
            ],

            [{
                name: 'ÉHB – Spécifications conceptuelles',
                link: 'sa_g4_fsa_specification',
            }],

            [
                {
                    name: "Fiche d’information – mots de passe",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "Liste d'élèves inscrits",
                    link: "sa_enrolled_students_link"
                }
            ],

            [{
                name: "Description d’items",
                link: 'sa_g4_item_desc_link',
                isDelayedAccess: true,
                isDisabled: true,
            }],
        ]
    ]
});

export const getG7SecureMaterialList = (): MaterialList => ({
    title: 'sa_am_header_3',
    subtitle: 'sa_am_header_3_sub',
    expanded: false,
    rows: ['sa_am_material_en', 'sa_am_material_fr'],
    columns: ['sa_am_secure_a_links', 'sa_am_scoring_links', 'sa_am_secure_admin_info', 'sa_am_item'],
    columnAvailable: [true, true, true, true],
    data: [
        [
            [
                {
                    name: 'Collaboration Activity',
                    link: 'sa_g7_collab_activity_link',
                    isDisabled: true,
                },
                {
                    name: 'Numeracy SR: Online Preview',
                    link: '/#/en/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_NME7-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,

                }, {
                    name: 'Literacy SR: Online Preview',
                    link: '/#/en/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_LTE7-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
                {
                    name: 'FSA Secure Login for Students',
                    link: 'sa_student_login_secure_link',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
            ],

            [{
                name: 'FSA specification',
                link: 'sa_g7_fsa_specification_link',
            }
            ],

            [
                {
                    name: "Password Information Sheet",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "List of Enrolled Students",
                    link: "sa_enrolled_students_link"
                }
            ],

            [{
                name: 'Item Descriptors',
                link: 'sa_g7_item_desc_link',
                isDisabled: true,
            }],
        ],

        [
            [
                {
                    name: 'Activité de collaboration',
                    link: 'sa_g7_collab_activity_link',
                    isDisabled: true,
                },
                {
                    name: 'Numératie SR: Online Preview',
                    link: '/#/fr/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_NMF7-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,

                }, {
                    name: 'Littératie SR: Online Preview',
                    link: '/#/fr/test-auth/public-test-runner/0?bcedCopyrightNotice=1&isSchoolAdmin=1&tw_type_slug=FSA_LTF7-SR-ONLINE',
                    isDelayedAccess: true,
                    isDisabled: true,
                },
                {
                    name: 'ÉHB - session sécurisée - accès élève',
                    link: 'sa_student_login_secure_link',
                    isDelayedAccess: true,
                    isDisabled: true,
                },

            ],

            [{
                name: 'ÉHB – Spécifications conceptuelles',
                link: 'sa_g7_fsa_specification_link',
            }],

            [
                {
                    name: "Fiche d’information – mots de passe",
                    link: "sa_password_info_sheet_link",
                    isDisabled: true,
                },
                {
                    name: "Liste d'élèves inscrits",
                    link: "sa_enrolled_students_link"
                }
            ],

            [{
                name: "Description d’items",
                link: 'sa_g7_item_desc_link',
                isDisabled: true,
            }],
        ]
    ]
})

export const getG4SampleMaterialList = (): MaterialList => ({
    title: 'Grade 4 Materials',
    expanded: false,
    columns: ['Sample Assessment Links', 'sa_am_scoring_links', 'Policies & Guides'],
    rows: ['Materials in English', 'Materials in French'],
    columnAvailable: [true, true, true],
    data: [
        [
            [{
                name: 'Sample Assessment A Online',
                link: '',
            }, {
                name: 'Sample A- Print (PDF)',
                link: '',
            }, {
                name: 'Sample A- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Assessment Specifications (PDF)',
                link: '',
            }],

            [{
                name: 'Sample Scoringing Rubric (PDF)',
                link: '',
            }, {
                name: 'Sample Scoring Guide & Exemplars (PDF)',
                link: '',
            }, {
                name: 'Sample Comments from Markers',
                link: '',
            }],

            [{
                name: 'Administration Manual',
                link: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/230/authoring/2021-22 FSA Admin Manual Online/1629302065239/2021-22 FSA Admin Manual Online.pdf',
            }, {
                name: 'Calculator Policy',
                link: '',
            }, {
                name: 'Reference Pages',
                link: '',
            }, {
                name: 'Technical Readiness Guides',
                link: '',
            }],
        ],

        [
            [],
            [],
            [],
        ]
    ]
})

export const getG7SampleMaterialList = (): MaterialList => ({
    title: 'Grade 7 Materials',
    expanded: false,
    columns: ['Sample Assessment Links', 'sa_am_scoring_links', 'Policies & Guides'],
    rows: ['Materials in English', 'Materials in French'],
    columnAvailable: [true, true, true],
    data: [
        [
            [{
                name: 'Sample Assessment A Online',
                link: '',
            }, {
                name: 'Sample A- Print (PDF)',
                link: '',
            }, {
                name: 'Sample A- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Assessment Specifications (PDF)',
                link: '',
            }],

            [{
                name: 'Sample Scoringing Rubric (PDF)',
                link: '',
            }, {
                name: 'Sample Scoring Guide & Exemplars (PDF)',
                link: '',
            }, {
                name: 'Sample Comments from Markers',
                link: '',
            }],

            [{
                name: 'Administration Manual',
                link: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/230/authoring/2021-22 FSA Admin Manual Online/1629302065239/2021-22 FSA Admin Manual Online.pdf',
            }, {
                name: 'Calculator Policy',
                link: '',
            }, {
                name: 'Reference Pages',
                link: '',
            }, {
                name: 'Technical Readiness Guides',
                link: '',
            }],
        ],

        [
            [],
            [],
            [],
        ]
    ]
})

export const getAssessmentKeys = (): MaterialList => ({
    title: 'Grade XXX Materials',
    expanded: false,
    columns: ['Sample Assessment Links', 'sa_am_scoring_links', 'Policies & Guides'],
    rows: ['Materials in English', 'Materials in French'],
    columnAvailable: [true, true, true],
    data: [
        [
            [{
                name: 'Sample Assessment A XXXXXXXXXXXXOnline',
                link: '',
            }, {
                name: 'Sample A- Print XXXXXXXXXXXXXXXx(PDF)',
                link: '',
            }, {
                name: 'Sample A- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print (PDF)',
                link: '',
            }, {
                name: 'Sample B- Print Answer Key (PDF)',
                link: '',
            }, {
                name: 'Assessment Specifications (PDF)',
                link: '',
            }],

            [{
                name: 'Sample Scoringing Rubric (PDF)',
                link: '',
            }, {
                name: 'Sample Scoring Guide & Exemplars (PDF)',
                link: '',
            }, {
                name: 'Sample Comments from Markers',
                link: '',
            }],

            [{
                name: 'Administration Manual',
                link: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/230/authoring/2021-22 FSA Admin Manual Online/1629302065239/2021-22 FSA Admin Manual Online.pdf',
            }, {
                name: 'Calculator Policy',
                link: '',
            }, {
                name: 'Reference Pages',
                link: '',
            }, {
                name: 'Technical Readiness Guides',
                link: '',
            }],
        ],

        [
            [],
            [],
            [],
        ]
    ]
})