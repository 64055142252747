import { Injectable } from "@angular/core";
import moment, { Moment } from "moment";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";
import { BcAccountsService, FilterCondition, PaginatedRows, Pagination, StudentRow2 } from "../bc-accounts/bc-accounts.service";
import { BcAssessmentsService, IScoreProfile, ScoreEntryStudentRow, ScoreProfileType, TestWindow } from "../bc-assessments/bc-assessments.service";
import { LangService } from "../core/lang.service";
import { IScoreEntryChange, IScoreEntryData, IScoreEntrySchoolAggregation, IScoreEntrySchoolAggregationByGrade, IScoreEntryUploadData, ScoreEntryStatus } from "./types";

@Injectable({
    providedIn: 'root'
})
export class BcScoreEntryService {

    private lastPulled: Moment = null;

    private autoSaveInterval: number = 10000;

    private aliasValueForNoTheme: number = 0;

    private themeProfile: IScoreProfile;
    private scoreProfile: IScoreProfile;
    private _profilesSub: BehaviorSubject<{
        theme: IScoreProfile,
        score: IScoreProfile,
    }>;

    constructor(
        private auth: AuthService,
        private routes: RoutesService,
        private lang: LangService,
        private bcAccounts: BcAccountsService,
        private bcAssessments: BcAssessmentsService,
    ) {
        this._profilesSub = new BehaviorSubject({
            theme: this.themeProfile,
            score: this.scoreProfile,
        });

        this.findScoreAndThemeProfiles().then(() => {
            this._profilesSub.next({
                theme: this.themeProfile,
                score: this.scoreProfile,
            })
        })
    }

    getLastPulled(): Moment {
        return this.lastPulled;
    }

    resetLastPulled() {
        this.lastPulled = null;
    }

    updateLastPulled() {
        this.lastPulled = moment();
    }

    getAutoSaveInterval(): number {
        return this.autoSaveInterval;
    }

    private async findScoreAndThemeProfiles() {
        const [themeProfile, scoreProfile] = await Promise.all([
            this.bcAssessments.getScoreProfile(ScoreProfileType.THEME),
            this.bcAssessments.getScoreProfile(ScoreProfileType.SCORE),
        ]);
        this.themeProfile = themeProfile;
        this.scoreProfile = scoreProfile;
    }

    profilesSub = (): BehaviorSubject<{ theme: IScoreProfile, score: IScoreProfile }> => this._profilesSub;

    aliasForNoTheme = (): number => this.aliasValueForNoTheme;

    isNoTheme = (theme: any): boolean => theme == null || theme == this.aliasValueForNoTheme;


    async findStudentsForScoreEntry(pagination: Pagination, testWindow: TestWindow, districtGroupId: number, incompleteOnly: boolean = false, completeOnly: boolean = false, grade: -1 | 4 | 7, schoolGroupId?: number, penOnly: boolean = false): Promise<PaginatedRows<ScoreEntryStudentRow>> {
        let route: string;
        if (completeOnly) route = this.routes.SCHOOL_ADMIN_SCORE_ENTRY_COMPLETE;
        else if (incompleteOnly) route = this.routes.SCHOOL_ADMIN_SCORE_ENTRY_INCOMPLETE;
        else route = this.routes.SCHOOL_ADMIN_SCORE_ENTRY;

        return await this.auth.apiFind(route, {
            query: {
                district_group_id: districtGroupId,
                school_group_id: schoolGroupId,
                pagination,
                test_window_id: testWindow.id,
                grade: grade == -1 ? undefined : grade,
                pen_only: penOnly ? 1 : 0,
            },
        }).then((result: PaginatedRows<any>) => {

            this.updateLastPulled();

            result.data.map(d => {
                d.grade = +d.grade;
                d.is_marked_not_yet_scored = d.is_marked_not_yet_scored == 1 ? true : false;
            });
            return result;
        })
    }

    async createScore(table: IScoreEntryData[], test_window_id: number, school_group_id: number): Promise<IScoreEntryData[]> {
        const { table: resultTable } = await this.auth.apiCreate(this.routes.SCHOOL_ADMIN_SCORE_ENTRY, {
            table,
        }, {
            query: {
                test_window_id,
                school_group_id,
            }
        });
        return resultTable;
    }

    async findChangesSinceLastPulled(
        district_group_id: number,
        test_window_id: number,
        grade: 4 | 7,
        school_group_id?: number,
    ): Promise<IScoreEntryChange[]> {
        let result = await this.auth.apiFind(this.routes.SCHOOL_ADMIN_SCORE_ENTRY_CHANGES, {
            query: {
                district_group_id,
                test_window_id,
                grade,
                last_pulled: this.lastPulled.toISOString(),
                school_group_id,
            }
        });

        this.updateLastPulled();

        return result;
    }

    async uploadScoreEntry(test_window_id: number, table: IScoreEntryUploadData[]): Promise<IScoreEntryUploadData[]> {
        let resultsTable = await this.auth.apiCreate(this.routes.SCHOOL_ADMIN_SCORE_ENTRY_UPLOAD, {
            upload: table,
        }, {
            query: {
                test_window_id,
            },
        });
        return resultsTable.upload;
    }

    async generateAggregatedSchoolInfo(testWindow: TestWindow, district_group_id: number, school_group_id?: number): Promise<IScoreEntrySchoolAggregation> {
        let enrolledPagination = this.bcAccounts.getInitialPagination();
        enrolledPagination.size = -1;
        enrolledPagination.filters = [{
            field: 'unenrolled',
            value: 0,
            condition: FilterCondition.MATCH,
        }];
        const { count, data } = await this.bcAccounts.findStudents2(
            enrolledPagination,
            testWindow,
            district_group_id,
            school_group_id,
        );

        const g4 = this.generateAggregatedSchoolInfo__by_grade(data, 4);
        const g7 = this.generateAggregatedSchoolInfo__by_grade(data, 7);

        return {
            g4,
            g7,
        };
    }

    private generateAggregatedSchoolInfo__by_grade(students: StudentRow2[], grade: number): IScoreEntrySchoolAggregationByGrade {
        let gradeStudents = students.filter(s => +s.grade === grade);

        const isComponentSubmitted = (progress: number): boolean => progress == 2;

        let data: IScoreEntrySchoolAggregationByGrade = {
            enrollment: gradeStudents.length,
            cr_literacy: gradeStudents.filter(s => isComponentSubmitted(s.crLiteracy)).length,
            cr_numeracy: gradeStudents.filter(s => isComponentSubmitted(s.crNumeracy)).length,
            sr_literacy: gradeStudents.filter(s => isComponentSubmitted(s.srLiteracy)).length,
            sr_numeracy: gradeStudents.filter(s => isComponentSubmitted(s.srNumeracy)).length,
        };

        return data;
    }

    getEmptyAggregatedSchoolInfo(): IScoreEntrySchoolAggregation {
        return {
            g4: {
                enrollment: null,
                cr_literacy: null,
                cr_numeracy: null,
                sr_literacy: null,
                sr_numeracy: null,
            },
            g7: {
                enrollment: null,
                cr_literacy: null,
                cr_numeracy: null,
                sr_literacy: null,
                sr_numeracy: null,
            },
        };
    }

    areProfilesReady(): boolean {
        return this.scoreProfile != null && this.themeProfile != null;
    }

    isScoreEntryColumnFilled(column: string | number): boolean {
        if (typeof column == 'string') {
            return column !== "" && column != null;
        } else {
            return column >= 0 && column != null;
        }
    }

    isScoreEntryRowAllFilled(row: ScoreEntryStudentRow): boolean {
        if (!this.areProfilesReady()) return false;
        return this.isScoreEntryColumnFilled(row.LI_1) && this.validateScore(this.scoreProfile, row.LI_1)
            && this.isScoreEntryColumnFilled(row.LI_2) && this.validateScore(this.scoreProfile, row.LI_2)
            && this.isScoreEntryColumnFilled(row.LI_3) && this.validateScore(this.scoreProfile, row.LI_3)
            && this.isScoreEntryColumnFilled(row.NU_1) && this.validateScore(this.scoreProfile, row.NU_1)
            && this.isScoreEntryColumnFilled(row.NU_2) && this.validateScore(this.scoreProfile, row.NU_2)
            && this.isScoreEntryColumnFilled(row.NU_3) && this.validateScore(this.scoreProfile, row.NU_3)
    }

    isScoreEntryRowNoneFilled(row: ScoreEntryStudentRow): boolean {
        if (!this.areProfilesReady()) return true;
        return !this.isScoreEntryColumnFilled(row.theme)
            && (!this.isScoreEntryColumnFilled(row.LI_1) || !this.validateScore(this.scoreProfile, row.LI_1))
            && (!this.isScoreEntryColumnFilled(row.LI_2) || !this.validateScore(this.scoreProfile, row.LI_2))
            && (!this.isScoreEntryColumnFilled(row.LI_3) || !this.validateScore(this.scoreProfile, row.LI_3))
            && (!this.isScoreEntryColumnFilled(row.NU_1) || !this.validateScore(this.scoreProfile, row.NU_1))
            && (!this.isScoreEntryColumnFilled(row.NU_2) || !this.validateScore(this.scoreProfile, row.NU_2))
            && (!this.isScoreEntryColumnFilled(row.NU_3) || !this.validateScore(this.scoreProfile, row.NU_3))
    }

    isScoreEntryRowPartiallyFilled(row: ScoreEntryStudentRow): boolean {
        if (!this.areProfilesReady()) return false;
        return !this.isScoreEntryRowNoneFilled(row) && !this.isScoreEntryRowAllFilled(row);
    }

    validateScore(profile: IScoreProfile, score: any): boolean {
        if (!this.areProfilesReady()) return false;
        return profile.options.findIndex(option => option.slug == score) >= 0;
    }

    validateLiteracyCell(row: ScoreEntryStudentRow, value: string | number): boolean {
        if (!this.areProfilesReady()) return false;
        if (value == '') {
            return true;
        } else if (this.isNoTheme(row.theme)) {
            return value == 'NR';
        } else {
            return this.validateScore(this.scoreProfile, value);
        }
    }

    async markAsNotYetScored(row: ScoreEntryStudentRow, not_yet_scored: boolean, test_window_id: number, school_group_id: number) {
        const taqr_id = this.findOneTaqrId(row);
        await this.bcAssessments.markScoreEntryNotYetScored({
            uid: row.uid,
            test_window_id: test_window_id,
            school_group_id: school_group_id,
            taqr_id: taqr_id,
        }, not_yet_scored);
    }

    private findOneTaqrId(row: ScoreEntryStudentRow): number | null {
        return row.LI_1_taqr_id || row.LI_2_taqr_id || row.LI_3_taqr_id || row.NU_1_taqr_id || row.NU_2_taqr_id || row.NU_3_taqr_id || null;
    }

    getStatus(row: ScoreEntryStudentRow): ScoreEntryStatus {
        if (row.is_marked_not_yet_scored) {
            return ScoreEntryStatus.NOT_YET_SCORED;
        }

        // if row has been saved
        if (row._is_saved) {
            // if all cells are filled
            if (this.isScoreEntryRowAllFilled(row)) {
                return ScoreEntryStatus.SUBMITTED;
            }
            // if no cells are filled
            else if (this.isScoreEntryRowNoneFilled(row)) {
                return ScoreEntryStatus.NOT_YET_SCORED;
            }
            // if some cells are filled
            else {
                return ScoreEntryStatus.IN_PROGRESS
            }
        }
        // if row has unsaved changes
        else {
            return ScoreEntryStatus.IN_PROGRESS_NOT_SAVED;
        }
    }

    fillEmptyScoreWithNR(test_window_id: number, district_group_id?: number, school_group_id?: number) {
        this.auth.apiCreate(this.routes.SCHOOL_ADMIN_SCORE_ENTRY_FILL_NR, {}, {
            query: {
                test_window_id,
                district_group_id,
                school_group_id,
            },
        }).then(results => {
            console.log(results);
        })
    }

}